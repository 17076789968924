import {Component, Input, ViewChild} from "@angular/core";
import {ArticlesLexiClient, CaracteristiquesLexiClient, CaracteristiqueDto, VarianteArticleDto, ObjectType} from "@lexi-clients/lexi";
import { DxDataGridComponent, DxFormComponent } from "devextreme-angular";
import {lastValueFrom} from "rxjs";

@Component({
    selector: "app-variantes-article-list",
    templateUrl: "./variantes-article-list.component.html",
    styleUrls: ["./variantes-article-list.component.scss"],
})
export class VariantesArticleListComponent {
    @ViewChild("form") form: DxFormComponent;
    @ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;

    _articleId: number;
    @Input() set articleId(articleId: number) {
        if (articleId !== undefined) {
            this._articleId = articleId;
            this.setVariantes();
            this.setCaracteristiques();
        }
    }
    @Input() articleLibelleLong: string;

    caracteristiques: CaracteristiqueDto[] = [];
    variantes: VarianteArticleDto[] = [];

    constructor(
        private readonly articlesLexiClient: ArticlesLexiClient,
        private readonly caracteristiquesLexiClient: CaracteristiquesLexiClient
    ) { }

    async setCaracteristiques() {
        this.caracteristiques = await lastValueFrom(this.caracteristiquesLexiClient.getCaracteristiquesByObjectType(ObjectType.article));
    }

    async setVariantes() {
        this.variantes = await lastValueFrom(this.articlesLexiClient.getVariantes(this._articleId));
    }

    async onRowInserting(variante: VarianteArticleDto) {
        try {
            variante.articleParentId = this._articleId;
            await lastValueFrom(this.articlesLexiClient.creerVariante(this._articleId, variante));
        } finally {
            await this.setVariantes();
        }
    }

    async onRowUpdating(e: { oldData: VarianteArticleDto; newData: VarianteArticleDto }) {
        try {
            for (const field of Object.keys(e.newData)) {
                e.oldData[field] = e.newData[field];
            }
            await lastValueFrom(this.articlesLexiClient.modifierVariante(e.oldData));
        } finally {
            await this.setVariantes();
        }
    }

    async onRowRemoving(variante: VarianteArticleDto) {
        try {
            await lastValueFrom(this.articlesLexiClient.supprimer(variante.id));
        } finally {
            await this.setVariantes();
        }
    }

    /*
      e.data = les changements
      e.key = les données déjà présente
     */
      onSaving(e: {
        cancel: boolean;
        changes: Array<{ type: string; data: VarianteArticleDto; key: VarianteArticleDto }>;
    }) {
        if (this.form?.instance?.validate != null) {
            e.cancel = this.form.instance.validate().isValid;
        }
    }

    onInitNewRow(e: VarianteArticleDto) {
        e.libelleLong = this.articleLibelleLong;
    }
}
