<div style="padding: 0 10px;">

  <div *ngIf="canUseFilterBySociete" style="margin-bottom: 6px;">
    <dx-radio-group
      [items]="filtres"
      [(value)]="filterByCurrentPartenaire"
      valueExpr="id"
      displayExpr="intitule"
      layout="horizontal"
      (onValueChanged)="onFilterByCurrentSiteChanged()"
    ></dx-radio-group>
  </div>

  <div style="margin-bottom: 6px;">
    <dx-button text="Créer" (onClick)="treeList?.instance?.addRow()" style="margin-right: 12px;" [disabled]="!enableActions"></dx-button>
    <dx-button text="Rafraîchir" (onClick)="setLieuxStockage()" style="margin-right: 12px;"></dx-button>
    <dx-button text="Tout Réduire" (onClick)="onCollapse()" [(visible)]="!filterByCurrentPartenaire"></dx-button>
  </div>

  <dx-tree-list #treeList
    [dataSource]="dataSource"
    keyExpr="id"
    parentIdExpr="lieuParentId"
    [autoExpandAll]="false"
    [(expandedRowKeys)]="expandedRowKeys"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    [showColumnHeaders]="true"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    (onRowInserting)="onRowInserting($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onKeyDown)="onKeyDown($event)"
  >

  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setLieuxStockage()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true" [showNavigationButtons]="true"></dxo-pager>

  <!-- Editing -->
  <dxo-editing mode="popup" [allowAdding]="enableActions" [allowUpdating]="enableActions" [allowDeleting]="enableActions">
    <dxo-popup
      title="Lieu de stockage"
      [showTitle]="true"
      [showCloseButton]="true"
      [height]="525"
    ></dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item itemType="group" [colCount]="2">
        <dxi-item dataField="version" [label]="{ text: 'Version' }" editorType="dxDateBox"
          [editorOptions]="{displayFormat: 'dd/MM/yyyy HH:mm:ss', type: 'datetime'}"
        ></dxi-item>
        <dxi-item
          dataField="lieuStockageNatureId"
          [label]="{text: 'Nature lieu stockage'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: naturesLieuStockageDataSource,
            searchEnabled: true,
            displayExpr: 'libelle',
            valueExpr: 'id',
            searchExpr: ['libelle', 'codeBo'],
            showDataBeforeSearch: true,
          }"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [colCount]="2">
        <dxi-item
          dataField="siteId"
          [label]="{text: 'Site'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: siteDataSource,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true,
          }"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="partenaireId"
          [label]="{text: 'Partenaire'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: partenaireDataSource,
            showClearButton: true,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true,
          }"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="2">
        <dxi-item dataField="codeBo" [label]="{text: 'CodeBo'}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="intitule" [label]="{text: 'Intitulé'}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="6">
        <dxi-item dataField="isDeleted" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Archivé'}"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="6">
        <dxi-item dataField="valeurMaximum" [label]="{text: 'Valeur max.'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
        <dxi-item dataField="poidsMaximum" [label]="{text: 'Poids max. (T)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 6 } }"></dxi-item>
        <dxi-item dataField="volumeMaximum" [label]="{text: 'Volume max. (m3)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 6 } }"></dxi-item>
        <dxi-item dataField="fondMinimum" [label]="{text: 'Fond min.'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
        <dxi-item dataField="fondMaximum" [label]="{text: 'Fond max.'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
        <dxi-item dataField="path" [label]="{text: 'Path'}" [colSpan]="1"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="6">
        <dxi-item
          [colSpan]="1"
          dataField="lieuParentId"
          [label]="{text: 'Lieu parent'}"
          editorType="dxSelectBox"
          [editorOptions]="{
          dataSource: dataSource,
          displayExpr: 'intitule',
          valueExpr: 'id',
          showClearButton: true,
          }"
        ></dxi-item>
        <dxi-item dataField="adresseGeographique1" [label]="{text: 'Adresse Geographique 1'}" [colSpan]="1"></dxi-item>
        <dxi-item dataField="adresseGeographique2" [label]="{text: 'Adresse Geographique 2'}"></dxi-item>
        <dxi-item dataField="adresseGeographique3" [label]="{text: 'Adresse Geographique 3'}"></dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="path" caption="Chemin" [visible]="true" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="siteId" caption="Site" [width]="150" sortOrder="asc" [calculateSortValue]="sortByIntitule">
    <dxo-lookup [dataSource]="siteDataSource" displayExpr="intitule" valueExpr="id" ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="lieuStockageNatureId" caption="Nature lieu stockage" [allowGrouping]="false">
    <dxo-lookup [dataSource]="naturesLieuStockageDataSource" displayExpr="libelle" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="codeBo" caption="CodeBo" [allowGrouping]="false"></dxi-column>
  <dxi-column dataField="intitule" caption="Intitulé" [allowGrouping]="false" sortOrder="asc"></dxi-column>
  <dxi-column dataField="venteAutorisee" caption="Vente autorisée" [allowGrouping]="false" dataType="boolean" [visible]="false"></dxi-column>
  <dxi-column dataField="stockNegatifAutorise" caption="Stock négatif autorisé" [allowGrouping]="false" dataType="boolean" [visible]="false"></dxi-column>
  <dxi-column dataField="stockageMarchandise" caption="Stock marchandise" [allowGrouping]="false" dataType="boolean" [visible]="false"></dxi-column>
  <dxi-column dataField="valeurMaximum" caption="Valeur max." [allowGrouping]="false" dataType="number" format="#.###" [visible]="false"></dxi-column>
  <dxi-column dataField="poidsMaximum" caption="Poids max. (T)" [allowGrouping]="false" dataType="number" format="#.######" [visible]="false"></dxi-column>
  <dxi-column dataField="volumeMaximum" caption="Volume max. (m3)" [allowGrouping]="false" dataType="number" format="#.######" [visible]="false"></dxi-column>
  <dxi-column dataField="stockageFonds" caption="Stock fonds" [allowGrouping]="false" dataType="boolean" [visible]="false"></dxi-column>
  <dxi-column dataField="lieuParentId" caption="lieuParentId" [visible]="false"></dxi-column>
  <dxi-column dataField="adresseGeographique1" caption="Adresse Geographique 1" [visible]="false"></dxi-column>
  <dxi-column dataField="adresseGeographique2" caption="Adresse Geographique 2" [visible]="false"></dxi-column>
  <dxi-column dataField="adresseGeographique3" caption="Adresse Geographique 3" [visible]="false"></dxi-column>
  <dxi-column dataField="fondMinimum" caption="Fond min." [allowGrouping]="false" dataType="number" format="#.###" [visible]="false"></dxi-column>
  <dxi-column dataField="fondMaximum" caption="Fond max." [allowGrouping]="false" dataType="number" format="#.###" [visible]="false"></dxi-column>
  <dxi-column dataField="suiviNumeroSerie" dataType="boolean" caption="Sérialisable"></dxi-column>
  <dxi-column dataField="version" caption="Version" [allowGrouping]="false" [allowEditing]="false" dataType="date" format="dd/MM/yyyy HH:mm"></dxi-column>
  <dxi-column dataField="isDeleted" caption="Archivé" [allowGrouping]="false" [allowEditing]="false" dataType="boolean" [filterValue]="false"></dxi-column>
  <dxi-column [visible]="false" [showInColumnChooser]="false" dataField="partenaireId"></dxi-column>
</dx-tree-list>
</div>