import { Component, Input } from "@angular/core";
import { ArticlesLexiClient, TarifDto, TypeTaxe } from "@lexi-clients/lexi";
import { lastValueFrom } from "rxjs";
import { formatNumber } from "devextreme/localization";

@Component({
  selector: "app-tarif-article-simplifie",
  templateUrl: "./tarif-article-simplifie.component.html",
  styleUrls: ["./tarif-article-simplifie.component.scss"],
})
export class TarifArticleSimplifieComponent {
  tarifs: TarifDto[];
  isModeAvance: boolean = false;
  _articleId: number;

  @Input() set articleId(articleId: number) {
    if (articleId !== undefined) {
      this._articleId = articleId;
      this.setTarifs();
    }
  }

  constructor(private readonly articlesLexiClient: ArticlesLexiClient) {}

  async setTarifs() {
    this.tarifs = await lastValueFrom(
      this.articlesLexiClient.getTarifsByArticleId(this._articleId),
    );
  }

  showModeAvance() {
    this.isModeAvance = true;
  }
  hideModeAvance() {
    this.isModeAvance = false;
  }

  protected readonly TypeTaxe = TypeTaxe;
  protected readonly formatNumber = formatNumber;
}
