<div class="form-container">

  <dx-box direction="row" width="100%" align="center" crossAlign="center">
    <dxi-item class="back-button" [ratio]="1">
      <a routerLink="/administration/sites">
        <dx-button icon="back"></dx-button>
      </a>
    </dxi-item>

    <dxi-item [ratio]="4">
      <h2 id="title">
        <span *ngIf="!isCreation">Détails {{ 'du_site' | internationalisation: 'du site' }} {{ !siteReadOnly ?  site?.intitule : '' }}</span>
        <span *ngIf="isCreation">Création {{ 'du_site' | internationalisation: 'du site' }}</span>
      </h2>
    </dxi-item>

    <dxi-item [ratio]="1"></dxi-item>
  </dx-box>

  <dx-box style="margin-bottom: 12px;" direction="row" width="100%">
    <dxi-item [ratio]="1">
      <div>
        <dx-button [visible]="!isCreation && isModificationEnCours" class="action-button" type="success" text="Enregistrer" (onClick)="onUpdateSite()" style="margin-right: 10px;"></dx-button>
        <dx-button [visible]="!isCreation && isModificationEnCours" class="action-button" type="danger" text="Annuler" (onClick)="onCancelUpdate()"></dx-button>
        <dx-button [visible]="isCreation" class="action-button" type="success" text="Créer" (onClick)="onCreateNewSite()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item [ratio]="1" *ngIf="!isCreation">
      <div class="menu-button">
        <dx-drop-down-button
          [items]="actions"
          keyExpr="id"
          displayExpr="intitule"
          (onItemClick)="onActionClick($event)"
          [disabled]="isModificationEnCours"
          [dropDownOptions]="{ width: 120 }"
          text="Action"
          icon="smalliconslayout"
        ></dx-drop-down-button>
      </div>
    </dxi-item>
  </dx-box>

  <dx-form #form [formData]="site" [readOnly]="!isCreation && !isModificationEnCours">
    <dxi-item itemType="tabbed">
      <dxo-tab-panel-options [showNavButtons]="true"></dxo-tab-panel-options>
      <!-- Général -->
      <dxi-tab title="Général" [colCount]="5">
        <!-- Actif -->
        <dxi-item itemType="group" [colSpan]="5" [visible]="!site?.actif && !isCreation">
          <span class="dx-icon-warning" style="font-size: 20px; color: rgb(204, 187, 38);"></span>
          {{ 'Site' | internationalisation: 'Site' }} désactivé
        </dxi-item>

        <!-- Groupe 1 -->
        <dxi-item itemType="group">
          <dxi-item dataField="codeBo">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="intitule" [label]="{text: 'Intitulé'}">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            [label]="{text: 'Partenaire associé'}"
            dataField="partenaireId"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: partenaireDataSource,
              valueExpr: 'id',
              displayExpr: partenaireDisplayExpr,
              searchEnabled: true,
              searchExpr: ['intitule', 'codeBo']
            }"
          >
            <dxi-validation-rule *ngIf="!isCreation" type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            dataField="lieuStockageDefautId"
            [label]="{text: 'Lieu de stockage par défaut'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: lieuStockageDataSource,
              searchEnabled: true,
              displayExpr: lieuStockageDisplayExpr,
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
        </dxi-item>

        <!-- Groupe 2 -->
        <dxi-item itemType="group">
          <dxi-item dataField="telephone1" [label]="{text: 'Téléphone 1'}"></dxi-item>
          <dxi-item dataField="telephone2" [label]="{text: 'Téléphone 2'}"></dxi-item>
          <dxi-item dataField="email"></dxi-item>
          <dxi-item dataField="fax"></dxi-item>
        </dxi-item>

        <!-- Groupe 3 -->
        <dxi-item itemType="group">
          <dxi-item dataField="noRidet" [label]="{text: 'Numéro Ridet'}"></dxi-item>
          <dxi-item dataField="noRC" [label]="{text: 'Numéro RC'}"></dxi-item>
          <dxi-item dataField="adresse1"></dxi-item>
          <dxi-item dataField="adresse2"></dxi-item>
        </dxi-item>

        <!-- Groupe 4 -->
        <dxi-item itemType="group">
          <dxi-item dataField="etablissement" [label]="{text: 'Établissement'}"></dxi-item>
          <dxi-item dataField="capital"></dxi-item>
          <dxi-item dataField="noCompte"
            [label]="{text: 'Numéro de compte'}"
            [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
          >
            <dxi-validation-rule message="{{ messageLongueurCompteInvalide }}"
              type="stringLength" ignoreEmptyValue="true"
              [min]="longueurCompte" [max]="longueurCompte"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="lieuPaiementCheque" [label]="{text: 'Lieu paiement chèque'}"></dxi-item>
        </dxi-item>

        <!-- Groupe 5 -->
        <dxi-item itemType="group">
          <dxi-item dataField="siteWeb"></dxi-item>
          <dxi-item dataField="informationsGenerales" editorType="dxTextArea"
            [editorOptions]="{height: 173}"
            [label]="{text: 'Informations générales'}"
          ></dxi-item>
        </dxi-item>

        <!-- Options générales -->
        <dxi-item cssClass="mb30" itemType="group" caption="Options" labelLocation="top">
          <!-- Groupe -->
          <dxi-item editorType="dxCheckBox" dataField="isStock" [label]="{visible: false}" [editorOptions]="{text: 'Est un lieu de stockage'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="isPartenaire" [label]="{visible: false}" [editorOptions]="{text: 'Est partenaire'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="synchronized" [label]="{visible: false}" [editorOptions]="{text: 'Synchronisé avec un backoffice'}"></dxi-item>

          <dxi-item
            dataField="entrepotTypeId"
            [label]="{text: 'Type de l\'entrepôt'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: entrepotTypeDataSource,
              searchEnabled: true,
              displayExpr: 'libelle',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelle', 'code'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
        </dxi-item>
      </dxi-tab>

      <!-- TPV -->
      <dxi-tab title="TPV" [colCount]="4">
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2">
            <app-tpv-settings-form [colCount]="2" [tpvSettings]="site?.tpvSettings" [formIsReadOnly]="!isCreation && !isModificationEnCours"
              [siteId]="site?.id" [isLieuxStockageForSite]="true"
            ></app-tpv-settings-form>
        </dxi-item>

        <dxi-item itemType="group" [colSpan]="2" [colCount]="2">
          <!-- Groupe -->
          <dxi-item
            dataField="devisePrincipaleId"
            [label]="{text: 'Devise principale'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: deviseDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeISO'],
                showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item
            dataField="deviseSecondaireId"
            [label]="{text: 'Devise secondaire'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: deviseDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeISO'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item
            dataField="prixParDefautId"
            [label]="{text: 'Prix par défaut'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: prixDataSource,
              searchEnabled: true,
              displayExpr: 'libelleLong',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelleLong', 'codeBo', 'libelleCourt'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <!-- Groupe -->
          <dxi-item dataField="dureeValiditeBonAchat" [label]="{text: 'Durée de validité d\'un bon d\'achat (en jours)'}" editorType="dxNumberBox" [editorOptions]="{format: {type: 'fixedPoint', precision: 0}}">
          </dxi-item>
          <!-- Groupe -->
          <dxi-item editorType="dxCheckBox" dataField="verificationStock" [label]="{visible: false}" [editorOptions]="{text: 'Vérification du stock'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="isPointDeVente" [label]="{visible: false}" [editorOptions]="{text: 'Est un point de vente'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="avertissementStockInsuffisant" [label]="{visible: false}" [editorOptions]="{text: 'Avertissement stock insuffisant'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="avertissementLotBloqueOuSansLot" [label]="{visible: false}" [editorOptions]="{text: 'Avertissement lot bloqué ou sans lot'}"></dxi-item>
          <dxi-item editorType="dxCheckBox" dataField="prixVenteForce" [label]="{visible: false}" [editorOptions]="{text: 'Prix de vente forcé'}"></dxi-item>

        </dxi-item>
        <!-- Groupe -->
      </dxi-tab>

      <!-- Comptabilité -->
      <dxi-tab title="Comptabilité" [colCount]="4">
        <dxi-item dataField="compteAuxiliaire"></dxi-item>
        <dxi-item dataField="compteBanque"
          [label]="{text: 'Compte Banque'}"
          [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
        >
          <dxi-validation-rule
            type="stringLength"
            [min]="longueurCompte"
            [max]="longueurCompte"
            ignoreEmptyValue="true"
            message="{{ messageLongueurCompteInvalide }}">
          </dxi-validation-rule>
        </dxi-item>
      </dxi-tab>

      <!-- Douane -->
      <dxi-tab title="Douane" [colCount]="4">
        <dxi-item editorType="dxCheckBox" dataField="isSousDouane" [label]="{visible: false}" [editorOptions]="{text: 'Est un entrepôt sous douane'}"></dxi-item>
        <dxi-item dataField="codeDouane"></dxi-item>
        <dxi-item
          dataField="bureauxDouaneId"
          [label]="{text: 'Bureau de douane'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: bureauxDouaneDataSource,
            searchEnabled: true,
            displayExpr: 'libelle',
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['libelle', 'code'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>
      </dxi-tab>

      <dxi-tab title="Devises" colCount="4">
        <app-gestion-devises *ngIf="siteId" [siteId]="siteId" [siteReadOnly]="!isModificationEnCours"></app-gestion-devises>
      </dxi-tab>

      <dxi-tab title="Nature des pièces" colCount="4">
        <app-magasin-piece-nature-datagrid *ngIf="siteId" [siteId]="siteId" [siteReadOnly]="!isModificationEnCours"></app-magasin-piece-nature-datagrid>
      </dxi-tab>

      <dxi-tab title="Modes de règlement" colCount="4">
        <app-gestion-mode-reglement-magasin *ngIf="siteId" [siteId]="siteId" [siteReadOnly]="!isModificationEnCours"></app-gestion-mode-reglement-magasin>
      </dxi-tab>

      <dxi-tab title="Fret Local" colCount="4">
        <dxi-item *ngIf="currentTypeUtilisateurRuunui == TypeUtilisateurRuunui.armateur"
          itemType="group" caption="Intégration automatique dans REVATUA" [colSpan]="2"
        >
          <div *dxTemplate>
            <app-voyage-fret-local-settings-form
              [colCount]="3"
              [colSpan]="1"
              [voyageFretLocalSettings]="site?.fretLocalSettings"
              [formIsReadOnly]="!isModificationEnCours"
              [motifRefusDemande]="motifRefusDemande"
            ></app-voyage-fret-local-settings-form>
          </div>
        </dxi-item>
      </dxi-tab>

      <!-- Tab Logistique -->
      <dxi-tab title="Logistique">
        <dxi-item itemType="group">
          <div *dxTemplate>
            <app-gestion-logistique [colCount]="4" [colSpan]="1" [logistiqueSettings]="site?.logistiqueSettings" [formIsReadOnly]="!isModificationEnCours" [isFromSociete]="isFromSociete"></app-gestion-logistique>
          </div>
        </dxi-item>
      </dxi-tab>

      <!-- Lieux de stockage -->
      <dxi-tab title="Lieux de stockage">
        <app-lieu-stockage-tree-list [partenaireId]="site?.partenaireId" [enableActions]="isModificationEnCours && canGererLieuxStockages" [currentSiteId]="siteId"></app-lieu-stockage-tree-list>
      </dxi-tab>
    </dxi-item>
  </dx-form>
</div>

<dx-load-panel
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
