<div>
  <div class="tarifsHeader">
    <h4 style="font-weight: 500; margin: 0">Tarifs</h4>
    <dx-button icon="edit" (onClick)="showModeAvance()"></dx-button>
  </div>

  <dx-list [dataSource]="tarifs">
    <div *dxTemplate="let item of 'item'">
        <div style="display: flex; flex-direction: column;">
          <h5 style="font-weight: 500; margin: 0">{{ item.prixIntitule }}</h5>
          <div style="display: flex; gap: 0.5rem">
            <p style="font-weight: 500">{{ formatNumber(item.valeur, {type :"currency" , precision: 0 , currency:"XPF"})}} {{item.prixTypeTaxe?.toUpperCase()}}</p>
            <p class="textSecondary" *ngIf="item.prixTypeTaxe === TypeTaxe.ht">{{ formatNumber(item.prixTtc, {type :"currency" , precision: 0 , currency:"XPF"}) }} TTC</p>
            <p class="textSecondary" *ngIf="item.prixTypeTaxe === TypeTaxe.ttc">{{ formatNumber(item.prixHt, {type :"currency" , precision: 0 , currency:"XPF"}) }} HT</p>
          </div>

        </div>
    </div>
  </dx-list>


  <dx-popup
    [fullScreen]="false"
    [showCloseButton]="true"
    [(visible)]="isModeAvance"
    (onHiding)="hideModeAvance()"
    title="Tarifs"
  >
    <app-tarif-article-list [tarifsDto]="tarifs"></app-tarif-article-list>
  </dx-popup>
</div>
