import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginResponse } from 'angular-auth-oidc-client';
import { Crisp } from "crisp-sdk-web";
import Popup, { Properties } from 'devextreme/ui/popup';
import themes from 'devextreme/ui/themes';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './settings/auth.service';
import { InternationalisationService } from './services/internationalisation.service';
import { AppInfoService } from './shared/services';
import { AppInitService } from './app-init.service';
import { EnvironmentService } from './environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  /**
   * Représente les timeouts lancés lorsqu'une requête au domaine d'authentification est terminée.
   * Ils serviront à faire un clearTimeout au cas où une autre requête parte
   * avant que le timeout ait eu le temps de s'exécuter.
   **/
  logoURL: string;
  racineLogoURL: string = "./assets/logo/";
  timeoutIds: Array<NodeJS.Timeout> = [];
  isLoading: boolean = true;

  constructor(
    public readonly authService: AuthService,
    public readonly appInfo: AppInfoService,
    readonly appInit: AppInitService,
    private readonly internationalisationService: InternationalisationService,
    private envService: EnvironmentService
  ) {
    Popup.defaultOptions<Properties>({
      options: {
        showCloseButton: true,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit(): Promise<any> {
    this.isLoading = true;

    //Initialisation de l'URL du logo LEXI
    const env = this.envService.environment;
    if(env.personnalisationLogo){
      this.logoURL=this.racineLogoURL+this.getDayNumber()+".png";
    }
    else{
      this.logoURL="./assets/images/logo_anime.gif";
    }

    // Initialisation des services
    this.authService.init();
    this.internationalisationService.init();

    // Initialisation de Crisp
    if(this.appInit.isCrispEnabled) {
      Crisp.configure(this.appInit.crispKey);
      Crisp.chat.hide();
    }

    // Set du thème de l'utilisateur
    const selectedTheme = window.localStorage.getItem('dx-theme') || 'material.orange.light.compact';
    
    themes.current(selectedTheme);

    // On vérifie si l'utilisteur est authentifié
    this.subscriptions.add(
      // Wait the uaa checkAuth response before auth redirect
      this.checkAuthResponse.subscribe(
        response => {
          if (response.isAuthenticated === false) {
            this.authService.login();
          }
        }
      )
    );

    this.subscriptions.add(
      this.authService.getAuthRequetes$().subscribe(nombreDeRequetesEnCours => {
        if (nombreDeRequetesEnCours == null || nombreDeRequetesEnCours > 0) {
          // On supprime les timeouts si il en existe pour ne pas changer la valeur
          // de isLoading à false après l'avoir passé à true
          this.timeoutIds.forEach(x => clearTimeout(x));
          this.timeoutIds = [];
          this.isLoading = true;
        }
        else {
          // Dans le cas où la requête est terminée, on laisse un peu de temps à la vue
          // pour s'actualiser au cas où on est authorisé
          const timeoutId = setTimeout(() => this.isLoading = false, 2000);
          this.timeoutIds.push(timeoutId);
        }
      })
    );
  }

  get isAuthenticated(): boolean {
    return this.authService.authenticatedUser && !this.authService.userChangedLoading ? this.authService.authenticatedUser.isAuthenticated() : false;
  }

  get isAuthorized(): boolean {
    return this.authService.authenticatedUser && !this.authService.userChangedLoading ? this.authService.authenticatedUser.isAuthorized() : false;
  }

  get checkAuthResponse(): Observable<LoginResponse> {
    return this.authService.checkAuthResponse$;
  }

  getDayNumber(): number {
    const date = new Date();
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  }

  getDefaultLogo(){
    this.logoURL="./assets/images/logo_anime.gif";
  }

  refreshPage() {
    window.location.reload();
  }

}
