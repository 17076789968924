/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AttributValeurType {
    string = 'string',
    integer = 'integer',
    dateTime = 'dateTime',
    date = 'date',
    decimal = 'decimal',
    boolean = 'boolean',
    list = 'list',
    multilineText = 'multilineText',
    dateTimeOffset = 'dateTimeOffset',
    listCustom = 'listCustom'
};

