<dx-data-grid
  [dataSource]="paquets"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onInitNewRow)="onInitNewRow($event)"
  (onKeyDown)="onKeyDown($event)"
  width="600"
  (onExporting)="onExporting($event, exportFileName)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setPaquets()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="cell" [allowAdding]="allowEditing" [allowUpdating]="allowEditing" [allowDeleting]="allowEditing"></dxo-editing>
  <dxo-export [enabled]="true"></dxo-export>

  <!-- Colonnes -->
  <dxi-column dataField="numero" caption="N°" width="50">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="numeroSuivi" caption="N° de suivi" width="150">
    <dxi-validation-rule type="stringLength" [max]="13" message="Le numéro de suivi doit faire 13 caractères."></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="poids" caption="Poids (kg)" dataType="number" format="#0.###" width="100"></dxi-column>
  <dxi-column dataField="volume" caption="Volume (m3)" dataType="number" format="#0.###" width="100"></dxi-column>
  <dxi-column dataField="pointe" dataType="boolean" caption="Pointé" width="60" [allowEditing]="allowEditing && canPointerColis"></dxi-column>
</dx-data-grid>