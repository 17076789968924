<div style="margin-bottom: 6px;">
  <dx-button text="Rafraîchir" (onClick)="setConnaissements()"></dx-button>
</div>

<dx-data-grid
  id="gridConnaissements"
  [dataSource]="connaissements"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onExporting)="onExporting($event, 'connaissements')"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setConnaissements()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>

  <!-- Colonnes -->
  <dxi-column dataField="id" caption="" [width]="40" cellTemplate="popupTemplate">
    <div *dxTemplate="let data of 'popupTemplate'">
      <span style="cursor: pointer;" class="dx-icon-find" (click)="openDetailPopup(data)"></span>
    </div>
  </dxi-column>

  <dxi-column dataField="codeConnaissement" caption="Numéro"></dxi-column>
  <dxi-column dataField="dateDepart" caption="Départ" dataType="date"></dxi-column>
  <dxi-column dataField="archipelIntitule" caption="Archipel" [visible]="false"></dxi-column>
  <dxi-column dataField="transporteurIntitule" caption="Transporteur"></dxi-column>
  <dxi-column dataField="ileArriveeIntitule" caption="Ile arrivée"></dxi-column>
  <dxi-column dataField="destinataireIntitule" caption="Destinataire" [visible]="false"></dxi-column>
  <dxi-column dataField="expediteurIntitule" caption="Expéditeur" [visible]="false"></dxi-column>
  <dxi-column dataField="nombreColis" caption="Nb Colis"></dxi-column>

  <dxi-column dataField="statutPlateforme" caption="Etat">
    <dxo-lookup [dataSource]="etatRevatua" valueExpr="id" displayExpr="libelle"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="numeroVoyage" caption="Voyage"></dxi-column>
  <dxi-column dataField="numeroRevatua" caption="Numéro Revatua"></dxi-column>
  <dxi-column dataField="ileDepartIntitule" caption="Ile départ"></dxi-column>

  <dxi-column caption="PDF" type="buttons" [width]="70" cellTemplate="buttonTemplate">
    <div *dxTemplate="let cell of 'buttonTemplate'">
      <dx-button
        hint="Télécharger PDF"
        icon="exportpdf"
        (onClick)="onConnaissementDownloadPdf(cell)"
      ></dx-button>
    </div>
  </dxi-column>
</dx-data-grid>

<!-- Popup ConnaissementDetail -->
<dx-popup
  title="{{titrePopUp}}"
  [(visible)]="popupVisible"
  hideOnOutsideClick="true"
  [showCloseButton]="true"
>
  <app-connaissement-info [selectedConnaissementDetail]="selectedConnaissementDetail"></app-connaissement-info>
</dx-popup>