import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { GetNoteResult, ListSortDirection, NoteDto, NotesLexiClient, ObjectType } from '@lexi-clients/lexi';
import { LexiUser } from '@lexi/oidc-uaa';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { Subscription, filter, lastValueFrom } from 'rxjs';
import { NotificationType } from '../references/references';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnDestroy {
  @ViewChild("addNoteForm") addNoteForm: DxFormComponent;
  @ViewChild("editNoteForm") editNoteForm: DxFormComponent;
  private subscriptions = new Subscription();

  private _objectType: ObjectType;
  get objectType(): ObjectType { return this._objectType; }
  @Input() set objectType(value: ObjectType) {
      this._objectType = value;
      this.loadDataSource();
  }

  private _objectId: string;
  get objectId(): string { return this._objectId; }
  @Input() set objectId(value: string) {
      this._objectId = value;
      this.loadDataSource();
  }

  dataSource: GetNoteResult[];
  currentUserId: number;
  cardClass: string = "dx-card custom-card";
  containerClass: string = "notes-container";
  newNoteDto: NoteDto;
  noteDtoToUpdate: NoteDto;
  noteASupprimer: NoteDto;
  showAddNotePopup = false;
  showEditNotePopup = false;
  showDeleteNotePopup = false;

  constructor(
    private readonly notesLexiClient: NotesLexiClient,
    private readonly authService: AuthService,
  ) {
    this.subscriptions.add(
      this.authService.currentUser$
        .pipe(filter((user: LexiUser) => user != null))
        .subscribe((user: LexiUser) => (this.currentUserId = user.id))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadDataSource() {
    if (this.objectType == null || this.objectId == null) return;
    this.dataSource = await lastValueFrom(this.notesLexiClient.getNotes(this.objectType, this.objectId, ListSortDirection.ascending));
  }

  onOpenAddNotePopup() {
    this.newNoteDto = { objectType: this.objectType, objectId: this.objectId.toString(), content: null };
    this.showAddNotePopup = true;
  }

  onOpenEditNotePopup(selectedNote: NoteDto) {
    this.noteDtoToUpdate = {};
    for(const field of Object.keys(selectedNote)) {
      this.noteDtoToUpdate[field] = selectedNote[field];
    }
    this.showEditNotePopup = true;
  }

  onOpenDeleteNotePopup(note: NoteDto) {
    this.noteASupprimer = note;
    this.showDeleteNotePopup = true;
  }

  async onCreateNote() {
    const isValid = this.addNoteForm.instance.validate().isValid;
    if (!isValid) return;

    await lastValueFrom(this.notesLexiClient.addNote(this.newNoteDto));
    notify({closeOnClick: true, message: "Nouvelle note créée avec succès."}, NotificationType.Success);
    await this.loadDataSource();

    this.showAddNotePopup = false;
    this.newNoteDto = {  };
  }

  async onUpdateNote() {
    const isValid = this.editNoteForm.instance.validate().isValid;
    if (!isValid) return;

    await lastValueFrom(this.notesLexiClient.updateNote(this.noteDtoToUpdate));
    notify({closeOnClick: true, message: "Note modifiée avec succès."}, NotificationType.Success);
    await this.loadDataSource();

    this.showEditNotePopup = false;
    this.noteDtoToUpdate = null;
  }

  async onDeleteNote() {
    await lastValueFrom(this.notesLexiClient.deleteNote(this.noteASupprimer.id));
    notify({closeOnClick: true, message: "Note supprimée avec succès."}, NotificationType.Success);
    await this.loadDataSource();
    this.noteASupprimer = null;
    this.showDeleteNotePopup = false;
  }
}
