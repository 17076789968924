import { Component, OnInit } from '@angular/core';
import { ObjectType, RoleUtilisationDto } from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import { NotificationType } from 'lexi-angular/src/app/modules/shared/references/references';
import { HeaderBaseComponent } from './header-base.component';
import { Partenaire } from 'lexi-angular/src/app/domain/partenaire';
import { Subscription, filter, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-header-partenaire',
  templateUrl: 'header-partenaire.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderPartenaireComponent extends HeaderBaseComponent implements OnInit {
  private subscriptions = new Subscription();
  contactsEnAttentes : RoleUtilisationDto[] = [];
  showPopupContactsEnAttentes = false;
  partenaires: Partenaire[];
  selectedPartenaireId: number;
  userMenuItems = [
    {
      text: 'Déconnecter',
      icon: 'runner',
      onClick: () => this.authService.logout()
    }
  ];

  get anyPartenaires(): boolean {
    return this.partenaires?.length > 1 ? true : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.selectedPartenaireId = this.authService.currentPartenaireId;

    this.subscriptions.add(
      this.authService.currentSocieteId$.pipe(
        filter((societeId: number) => societeId != null)
      ).subscribe(async (societeId: number) => {
        await this.setCurrentClient();
        await this.setPartenaires();
        await this.setContactsEnAttentes();
      })
    );
  }

  async setPartenaires() {
    if (this.authService.securityUser?.id) {
      const p = await lastValueFrom(this.securityHttpClient.getPartenairesPourUnUtilisateur(this.authService.securityUser.id));
      if (p && p.length > 0) {
        this.partenaires = (p.map(dto => new Partenaire(dto)));
      }
    }
  }

  async setCurrentClient() {
    if (this.authService.currentPartenaireId) {
      this.currentClient = await lastValueFrom(this.partenairesLexiClient.getById(this.authService.currentPartenaireId));
      this.authService.setCurClientObs(this.currentClient);
    }
  }

  changerPartenaire(selectedItem) {
    this.authService.setPartenaireId(selectedItem.value);
  }

  togglePopupContactsEnAttentes(){
    this.showPopupContactsEnAttentes = !this.showPopupContactsEnAttentes;
  }

  async setContactsEnAttentes(){
    this.contactsEnAttentes = await lastValueFrom(this.userRelationService.getEnAttentesPourUtilisateur(ObjectType.partenaire, this.user.id));
  }

  async onValidate(e: { data: RoleUtilisationDto }){
    await lastValueFrom(this.userRelationService.valider(e.data));
    this.afterActionContactEnAttente(e.data, `Vous êtes maintenant associé au partenaire ${e.data.partenaireIntitule}.`);
    await this.setPartenaires();
  }

  async onDelete(e: { data: RoleUtilisationDto }){
    await lastValueFrom(this.userRelationService.supprimer(
      e.data.objectType, e.data.objectId, e.data.utilisateurId, e.data.roleId
    ));
    this.afterActionContactEnAttente(e.data, `Vous avez refusé la demande d'association au partenaire ${e.data.partenaireIntitule}.`);
  }

  private async afterActionContactEnAttente(r: RoleUtilisationDto, message: string) {
    this.contactsEnAttentes.splice(this.contactsEnAttentes.indexOf(r), 1);
    if (this.contactsEnAttentes.length == 0) {
      this.showPopupContactsEnAttentes = false;
    }
    notify(message, NotificationType.Success);
  }
}