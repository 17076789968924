<div [style]="'height: '+height">
  <dx-data-grid #dataGrid
    [dataSource]="dataSource"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [filterSyncEnabled]="true"
    [height]="'100%'"
  >

    <!-- Toolbar -->
    <dxo-toolbar>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearDatagridFilters()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Rafraîchir" (onClick)="refreshDataGrid()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>

    <!-- Colonnes -->
    <dxi-column
      dataField="id"
      caption="Code"
      cellTemplate="idBonCellTemplate"
      alignment="left"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSearch]="false"
    >
      <div *dxTemplate="let data of 'idBonCellTemplate'">
        <a routerLink="/bon/{{data.value}}">
          {{data.value}} - {{data.data.codeBo}}
        </a>
      </div>
    </dxi-column>
    <dxi-column dataField="intitule" caption="Intitule" [visible]="true" width="160"></dxi-column>
    <dxi-column caption="Marchandises" cellTemplate="marchandisesTemplate" alignment="right" width="120">
      <div *dxTemplate="let cell of 'marchandisesTemplate'">
        {{cell.data.nombreMarchandisesMouvementees}} / {{cell.data.nombreMarchandises}}
      </div>
    </dxi-column>
    <dxi-column dataField="mouvementTypeIntitule" caption="Type" [width]="170"></dxi-column>

    <dxi-column caption="Source" cellTemplate="sourceTemplate">
      <div *dxTemplate="let cell of 'sourceTemplate'">
        {{currentPartenaireId == cell.data.partenaireSourceId ? cell.data.sourceStockageIntitule : cell.data.partenaireSourceIntitule}}
      </div>
    </dxi-column>
    <dxi-column dataField="PartenaireSourceId" caption="Partenaire source id" [visible]="false"></dxi-column>

    <dxi-column caption="Destination" cellTemplate="destinationTemplate">
      <div *dxTemplate="let cell of 'destinationTemplate'">
        {{currentPartenaireId == cell.data.partenaireDestinationId ? cell.data.destinationStockageIntitule : cell.data.partenaireDestinationIntitule}}
      </div>
    </dxi-column>
    <dxi-column dataField="partenaireDestinationId" caption="Partenaire destination id" [visible]="false"></dxi-column>
  </dx-data-grid>
</div>