<ng-template header-host></ng-template>

<dx-drawer class="layout-body" position="before" [closeOnOutsideClick]="shaderEnabled" [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled" [maxSize]="250"
  [(opened)]="menuOpened">

  <app-side-navigation-menu [compactMode]="!menuOpened" [selectedItem]="selectedRoute" class="dx-swatch-additional"
    *dxTemplate="let dataMenu of 'panel'" (selectedItemChanged)="navigationChanged($event)"
    (openMenu)="navigationClick()">
  </app-side-navigation-menu>

  <dx-scroll-view>
    <router-outlet></router-outlet>
  </dx-scroll-view>
</dx-drawer>