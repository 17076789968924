import {Component, Input, ViewChild} from '@angular/core';
import {
  ClassificationsLexiClient,
  ClassificationTypeDto,
  ClassificationValueDto,
  ClassificationValuesLexiClient,
  CreateClassificationDto,
  GetClassificationDto,
  ObjectType
} from '@lexi-clients/lexi';
import {DxDataGridComponent, DxSelectBoxComponent, DxTreeListComponent} from 'devextreme-angular';
import {ClassificationTypeService} from '../../services/classification-type.service';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import {NotificationType} from '../../modules/shared/references/references';
import {lastValueFrom} from 'rxjs';
import {DxDataSourceService} from '../../shared/services/dx-data-source.service';
import {AuthService} from '../../settings/auth.service';
import dxTreeList, {RowExpandedEvent} from 'devextreme/ui/tree_list';

@Component({
  selector: 'app-classification-datagrid',
  templateUrl: './classification-datagrid.component.html',
  styleUrls: ['./classification-datagrid.component.scss'],
})
export class ClassificationDatagridComponent {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid; }
  @ViewChild('dataGrid') set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value && this.heightToDeduce != null) {
      value.instance.element().style.height = `calc(100vh - ${this.heightToDeduce}px)`;
    }
  }

  private _heightToDeduce: number;
  get heightToDeduce() { return this._heightToDeduce; }
  @Input() set heightToDeduce(value: number) {
    this._heightToDeduce = value;
    if (value && this.dataGrid != null) {
      this.dataGrid.instance.element().style.height = `calc(100vh - ${value}px)`;
    }
  }
  @ViewChild('treeList') treeList: DxTreeListComponent;
  @ViewChild('selectBoxClassificationValue') selectBoxClassificationValueRef: DxSelectBoxComponent;
  @ViewChild('selectBoxClassificationTypePopup') selectBoxClassificationTypePopup: DxSelectBoxComponent;
  @Input() objectType: ObjectType = ObjectType.autre;

  private _itemId: number;
  get itemId(): number {
    return this._itemId;
  }
  @Input() set itemId(value: number) {
    if (value != null && value != undefined) {
      this._itemId = value;
      this.onInit();
    }
  }

  @Input() set classificationsDto(classificationsDto: GetClassificationDto[]) {
    this.classifications = classificationsDto;
  }

  private classificationValues: ClassificationValueDto[] = [];
  classificationValueDataSourceForSelectBox: ClassificationValueDto[] = [];
  classificationValueDataSourceForTreeList: ClassificationValueDto[] = [];
  classifications: GetClassificationDto[] = [];
  classificationTypeDataSource: DataSource;

  selectedClassificationValueId: number;
  classificationTypeIdForPopup: number;
  classificationTypeIdForSelectBox: number;
  classificationsToAddDisplayText: string = "Sélectionnez les classifications à ajouter";
  selectedClassificationValues: ClassificationValueDto[];

  constructor(
    private readonly classificationsLexiClient: ClassificationsLexiClient,
    private readonly classificationTypeListService: ClassificationTypeService,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly authService: AuthService,
  ) { }

  //#region Méthodes principales
  async onInit() {
    if (this.classifications.length === 0) {
      await this.setClassifications();
    }
    this.classificationValues = await lastValueFrom(this.classificationValuesLexiClient.getAll());
    const params = new Map().set("objectType", this.objectType);
    this.classificationTypeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypeListService, params);

    // Par défaut, on prend la classification comptable de la société
    if (this.authService.currentSociete?.classificationComptableTypeId != null) {
      const typeId = this.authService.currentSociete?.classificationComptableTypeId;
      this.classificationTypeIdForSelectBox = this.classificationTypeIdForPopup = typeId;
      this.classificationValueDataSourceForSelectBox = this.classificationValueDataSourceForTreeList = this.classificationValues?.filter(x => x.classificationTypeId == typeId);
    }
  }

  async setClassifications() {
    this.classifications = await lastValueFrom(this.classificationsLexiClient.getByItemId(this.objectType, this.itemId));
  }

  async createNewClassifications(classificationValues: ClassificationValueDto[]) {
    try {
      const newClassifications: CreateClassificationDto[] = classificationValues.map(x => {
        return {
          itemId: this.itemId,
          classificationValueId: x.id,
          type: this.objectType
        };
      });

      if (newClassifications.length > 1) {
        notify({closeOnClick: true, message: "Création multiple impossible : une seule classification par type de classification permise." }, NotificationType.Success);
        return;
      }

      await lastValueFrom(this.classificationsLexiClient.create(newClassifications));
      const message = newClassifications.length > 1
        ? "Associations aux classifications faites avec succès."
        : "Association à la classification faite avec succès.";
      notify({closeOnClick: true, message }, NotificationType.Success);
    }
    finally {
      await this.setClassifications();
    }
  }
  //#endregion

  //#region Gestion des évènements
  onClassificationTypeChanged = (e: { selectedItem?: ClassificationTypeDto }) => {
    this.filterClassificationValuesForTreeList();
  }

  onClassificationValueSelectionChanged(e: { selectedRowKeys: number[], selectedRowsData: ClassificationValueDto[] }) {
    this.treeList.instance.updateDimensions();
    this.selectedClassificationValues = e?.selectedRowsData ?? [];
    const intitules = this.selectedClassificationValues.map(x => x.intitule);
    this.classificationsToAddDisplayText = intitules?.length > 0
      ? `Classification sélectionnée : ${intitules.join(", ")}`
      : "Sélectionnez une classification à ajouter";
  }

  async onRowInserting() {
    await this.createNewClassifications(this.selectedClassificationValues);
  }

  async onRowRemoving(e: GetClassificationDto) {
    try {
      await lastValueFrom(this.classificationsLexiClient._delete(this.objectType, this.itemId, e.classificationValueId));
      notify({closeOnClick: true, message: "Association à la classification supprimée avec succès."}, NotificationType.Success);
    }
    finally {
      await this.setClassifications();
    }
  }

  onSaving(e: { cancel: boolean, changes: Array<{key: any, type: 'remove' | 'insert'}> }) {
    if (e.changes?.some(x => x.type == "insert") && (this.selectedClassificationValues == null || this.selectedClassificationValues.length == 0)) {
      e.cancel = true;
      notify({closeOnClick: true, message: "Une classification doit être sélectionnée."}, NotificationType.Warning);
    }
  }

  resetSelectedValues() {
    this.selectedClassificationValueId = null;
    this.selectedClassificationValues = null;
    this.treeList?.instance?.clearSelection();
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }

  onSelectBoxClassificationValuePressEnter(e) {
    if (e.key === 'Enter') {
      this.onAddClassificationValueWithSelectBox();
    }
  }

  async onAddClassificationValueWithSelectBox() {
    if (this.selectBoxClassificationValueRef == null) {
      notify({closeOnClick: true, message: "Impossible d'ajouter la classification. Essayez de recharger la page."}, NotificationType.Error);
    }

    const cv: ClassificationValueDto = this.selectBoxClassificationValueRef.value;
    if (!cv) notify({closeOnClick: true, message: "Veuillez sélectionner une classification."}, NotificationType.Warning);

    await this.createNewClassifications([cv]);
    this.selectBoxClassificationValueRef.value = null;
  }
  //#endregion

  //#region Méthodes helper
  filterClassificationValuesForTreeList = () => {
    this.classificationValueDataSourceForTreeList = this.classificationValues?.filter(x => x.classificationTypeId == this.classificationTypeIdForPopup);
  }

  filterClassificationValuesForSelectBox = () => {
    this.classificationValueDataSourceForSelectBox = this.classificationValues?.filter(x => x.classificationTypeId == this.classificationTypeIdForSelectBox);
  }

  classificationTypeDisplayExpr(type: ClassificationTypeDto) {
    return type && type.codeBo + " - " + type.intitule;
  }

  classificationValueDisplayExpr(value: ClassificationValueDto) {
    return value && value.codeBo + " - " + value.intitule;
  }

  /**
   * @returns la fonctione de comparaison des ClassificationValues
   * de sorte à ce que les premiers éléments soient les ClassificationValues sans ParentId
   */
   compareClassificationValues(a: ClassificationValueDto, b: ClassificationValueDto) {
    if (a.parentId != null && b.parentId == null) {
      return 1;
    }

    if (a.parentId == null && b.parentId != null) {
      return -1;
    }

    return 0;
  }

  async expandRecursively(dxTreeList: dxTreeList<any, any>, rowKey: number, rowIndex: number) {
    try {
      await dxTreeList.expandRow(rowKey);
      const children = dxTreeList.getVisibleRows()?.find(x => x.rowIndex == rowIndex)?.node?.children;
      if (children) {
        children.forEach(async (element) => {
          const index = dxTreeList.getRowIndexByKey(element.key);
          await this.expandRecursively(dxTreeList, element.key, index);
        });
      }
    }
    catch { }
  }

  async onRowExpanded(e: RowExpandedEvent) {
    const index = e.component.getRowIndexByKey(e.key);
    await this.expandRecursively(e.component, e.key, index);
  }
  //#endregion

}
