import { Component, OnInit, ViewChild } from '@angular/core';
import { ArticleCsvImportResultDto, RechercheDxArticleDto, ArticlesLexiClient, ClassificationTypeDto, ClassificationValueDto, ObjectType, Permissions, ClassificationsLexiClient, CreateClassificationDto, ClassificationValuesLexiClient } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../../settings/auth.service';
import { ArticleListDatagridComponent } from '../../modules/shared/article-list-datagrid/article-list-datagrid.component';
import { DxDataSourceService } from '../../shared/services/dx-data-source.service';
import { ClassificationTypeService } from '../../services/classification-type.service';
import DataSource from 'devextreme/data/data_source';
import { DxTreeListComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { NotificationType } from '../../modules/shared/references/references';
import notify from 'devextreme/ui/notify';
import dxTreeList, { SelectionChangedEvent } from 'devextreme/ui/tree_list';
import { DownloadService } from '../../services/download.service';

const showClassificationsStorageKey = "showClassificationsArticleList";
const isSelectionRecursiveArticleList = "isSelectionRecursiveArticleList";
@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit {
  private _articleListDataGridRef: ArticleListDatagridComponent;
  get articleListDataGridRef() { return this._articleListDataGridRef; }
  @ViewChild(ArticleListDatagridComponent) set articleListDataGridRef(value: ArticleListDatagridComponent) {
    this._articleListDataGridRef = value;
    this.setClassificationValues();
  }
  private _treeList: DxTreeListComponent;
  get treeList() { return this._treeList; }
  @ViewChild('treeList') set treeList(value: DxTreeListComponent) {
    this._treeList = value;
    if (value) {
      value.instance.updateDimensions();
      value.instance.element().style.height = "calc(100vh - 190px)";
    }
  }
  showImportCsvPopup = false;
  fichierCsv: File[] = [];
  importCsvResultMsg: string;
  importCsvResultMsg2: string;
  importHasFailed = false;
  showLoader = false;
  initDataGrid = false;
  isModificationAutorisee: boolean = false;
  classificationTypeDataSource: DataSource;
  classificationValues: ClassificationValueDto[];
  selectedClassificationValues: ClassificationValueDto[];
  showClassifications = false;
  classificationValueIds: number[];
  isSelectionRecursive = true;
  recuperationTemplateEncours = false;

  constructor(
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly authService: AuthService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly classificationTypeListService: ClassificationTypeService,
    private readonly classificationsLexiClient: ClassificationsLexiClient,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly downloadService: DownloadService
  ) { }

  async ngOnInit() {
    this.isModificationAutorisee = this.authService.securityUserisGrantedWith(Permissions.canModifierFicheArticle);
    this.initDataGrid = true;
    const params = new Map().set("objectType", ObjectType.article);
    this.classificationTypeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypeListService, params);
    await this.setClassificationValues();
    if (localStorage.getItem(showClassificationsStorageKey)) {
      this.showClassifications = true;
    }

    if (localStorage.getItem(isSelectionRecursiveArticleList)) {
      this.isSelectionRecursive = true;
    }
  }

  setDataSource() {
    this.articleListDataGridRef?.setArticleDataSource();
  }

  async importDataFromCsv() {
    this.resetImportResultMsg();
    this.showLoader = true;
    try {
      const response: ArticleCsvImportResultDto = await lastValueFrom(this.articlesLexiClient.importArticlesFromCsv(true, this.fichierCsv[0]));

      // Erreur
      if (response.hasFailed) {
        this.importHasFailed = true;
        if (response.errorMessage != null) {
          this.importCsvResultMsg = response.errorMessage;
        }

        else {
          if (response.hasWrongHeadersCount) {
            this.importCsvResultMsg = "Le nombre de colonnes est incorrecte, il y a plus de colonnes que celles attendues.";
          }

          else {
            this.importCsvResultMsg = `Les colonnes suivantes sont invalides: ${response.invalidHeaders.join(', ')}.`;
          }

          if (response.expectedHeaders != null) {
            this.importCsvResultMsg2 = `Colonnes attendues: (${response.expectedHeaders.join(', ')})`;
          }
        }
      }

      else {
        // Importation incomplète
         if (response.articlesOnError.length > 0) {
          let errorMessage = "";
          response.articlesOnError.forEach(x => errorMessage += `Code Article : ${x.codeBo}; Erreur : ${x.messageErreur}\n`);
          this.importCsvResultMsg = `Certains articles n'ont pas pu être importés. Le détail a été copié dans le presse papier`;
          navigator.clipboard.writeText(errorMessage).then().catch(e => console.error(e));
          this.setDataSource();
        }

        // Succès
        else {
          this.importHasFailed = false;
          this.setDataSource();
          this.importCsvResultMsg = `${response.nbArticlesCreated} article(s) ont été créé(s),  ${response.nbArticlesIgnored} article(s) ont été ignorés. \nL'importation s'est terminée.`;
        }

        // Articles désarchivés
        this.importCsvResultMsg2 = response.codesArticlesDesarchives.length > 0
          ?
`Certains articles existaient déjà en base de données à l'état supprimé/archivé. Ils ont été désarchivés sans modification supplémentaire.

  Codes des articles désarchivés :

[ ${response.codesArticlesDesarchives.join(", ")} ]`
          : "";
      }

      this.fichierCsv = [];
    }
    finally {
      this.showLoader = false;
    }
  }

  cancelImportCsv() {
    this.showImportCsvPopup = false;
    this.resetImportResultMsg();
    this.fichierCsv = [];
  }

  resetImportResultMsg() {
    this.importCsvResultMsg = null;
    this.importCsvResultMsg2 = null;
  }

  classificationTypeDisplayExpr(type: ClassificationTypeDto) {
    return type && type.codeBo + " - " + type.intitule;
  }

  async expandRecursively(dxTreeList: dxTreeList<any, any>, rowKey: number, rowIndex: number) {
    try {
      await dxTreeList.expandRow(rowKey);
      const children = dxTreeList.getVisibleRows()?.find(x => x.rowIndex == rowIndex)?.node?.children;
      if (children) {
        children.forEach(async (element) => {
          const index = dxTreeList.getRowIndexByKey(element.key);
          await this.expandRecursively(dxTreeList, element.key, index);
        });
      }
    }
    catch { }
  }

  async onClassificationValueSelectionChanged(e: SelectionChangedEvent) {
    // On ne filtre pas si la désélection vient du fait d'avoir mis le filtre 'Sans classification uniquement'
    if (this.articleListDataGridRef?.sansClassificationValue && e.currentSelectedRowKeys?.length < 1) return;
    const rows = e.component.getVisibleRows();
    for (const element of rows.filter(x => x.isSelected && !x.isExpanded)) {
      await this.expandRecursively(e.component, element.key, element.rowIndex);
    }

    await e.component.refresh();
    this.classificationValueIds = e.component.getSelectedRowKeys('all');
    setTimeout(() => {
      this.articleListDataGridRef?.setArticleDataSource(false);
    }, 100);
  }

  onToggleClassificationDisplay() {
    this.treeList?.instance?.clearSelection();
    this.articleListDataGridRef?.dataGrid?.instance.clearSelection();
    this.classificationValueIds = null;
    this.showClassifications = !this.showClassifications;
    if (this.showClassifications) {
      localStorage.setItem(showClassificationsStorageKey, "true");
    }
    else {
      setTimeout(() => {
        this.articleListDataGridRef?.setArticleDataSource();
      }, 100);

      localStorage.removeItem(showClassificationsStorageKey);
    }
  }

  async onToggleIsSelectionRecursive() {
    if (this.isSelectionRecursive) {
      localStorage.setItem(isSelectionRecursiveArticleList, "true");
    }
    else {
      localStorage.removeItem(isSelectionRecursiveArticleList);
    }

    await this.treeList?.instance.refresh();
    this.classificationValueIds = this.treeList?.instance.getSelectedRowKeys('all');
    setTimeout(() => {
      this.articleListDataGridRef?.setArticleDataSource(false);
    }, 100);
  }

  onAddArticleIntoClassification = async (e: { itemData: RechercheDxArticleDto, component: any, toIndex: number }) => {
    if (this.articleListDataGridRef?.dataGrid?.instance == null) return;
    const rows = e.component.getVisibleRows();
    const classification: ClassificationValueDto = rows?.find(x => x.rowIndex == e.toIndex)?.data;
    let articles: RechercheDxArticleDto[] = this.articleListDataGridRef.dataGrid.instance.getSelectedRowsData();
    if ((articles == null || articles.length == 0) && e.itemData != null) {
      articles = [e.itemData];
    }
    const s = articles?.length > 1 ? "s" : "";
    if (articles?.length > 0 && classification != null) {
      const confirmed = await confirm(`Associer ${articles.length} article${s} à la classification '${classification.intitule}'`, `Confirmation`);
      if (confirmed) {
        const dtos: CreateClassificationDto[] = articles.map(x => { return {
          itemId: x.id,
          classificationValueId: classification.id,
          type: ObjectType.article,
        }; });
        await lastValueFrom(this.classificationsLexiClient.create(dtos));
        notify({closeOnClick: true, message: `Association${s} faite${s} avec succès.` }, NotificationType.Success);
        this.articleListDataGridRef.dataGrid.instance.clearSelection();
        setTimeout(() => {
          this.articleListDataGridRef?.setArticleDataSource(this.articleListDataGridRef?.sansClassificationValue);
        }, 100);
      }
    }
  }

  async onRemoveClassificationArticle() {
    if (this.articleListDataGridRef?.dataGrid?.instance == null) {
      notify({closeOnClick: true, message: `Impossible de récupérer les articles sélectionnés. Essayez de rafraîchir la page.` }, NotificationType.Warning);
      return;
    }

    const articles: RechercheDxArticleDto[] = this.articleListDataGridRef.dataGrid.instance.getSelectedRowsData();
    if (articles?.length < 1) {
      notify({closeOnClick: true, message: `Veuillez sélectionner au moins un article.` }, NotificationType.Warning);
      return;
    }

    const s = articles?.length > 1 ? "s" : "";
    const classifications: ClassificationValueDto[] = this.treeList?.instance?.getSelectedRowsData();
    if (articles?.length > 0 && classifications?.length > 0) {
      const classificationsIntitule = classifications.map(x => x.intitule);
      const m = classifications.length > 1 ? `aux classifications ${classificationsIntitule.join(', ')}` : `à la classifications ${classificationsIntitule[0]}`;
      const confirmed = await confirm(`Supprimer l'association de ${articles.length} article${s} ${m}`, `Confirmation`);
      if (confirmed) {
        await lastValueFrom(this.classificationsLexiClient.deleteMultiple(ObjectType.article, classifications.map(x => x.id), articles.map(x => x.id)));
        notify({closeOnClick: true, message: `Association${s} supprimée${s} avec succès.` }, NotificationType.Success);
        this.articleListDataGridRef.dataGrid.instance.clearSelection();
        setTimeout(() => {
          this.articleListDataGridRef?.setArticleDataSource();
        }, 100);
      }
    }
  }

  clearTreeListFilters() {
    this.treeList?.instance?.clearFilter();
    this.treeList?.instance?.clearSelection();
    this.treeList?.instance?.clearSorting();
    this.classificationValueIds = null;
  }

  async setClassificationValues() {
    this.classificationValues = this.articleListDataGridRef?.selectedClassificationTypeId == null ? []
      : await lastValueFrom(this.classificationValuesLexiClient.getByClassificationTypeId(this.articleListDataGridRef.selectedClassificationTypeId));
  }

  async downloadTemplate() {
    try {
      this.recuperationTemplateEncours = true;
      const response = await lastValueFrom(this.articlesLexiClient.getTemplateImportCsv('response'));
      this.downloadService.directDownloadFile(response, 'csv');
    }
    finally {
      this.recuperationTemplateEncours = false;
    }
  }
}
