import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LexiUser } from '@lexi/oidc-uaa';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxMenuModule } from 'devextreme-angular';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode: string;

  @Input()
  user: LexiUser;

  constructor(protected readonly authService: AuthService) {}
}

@NgModule({
  imports: [
    DxContextMenuModule,
    CommonModule,
    DxMenuModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
