import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  PaquetDto,
  PaquetLexiClient
} from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-bon-detail-gestion-des-colis',
  templateUrl: './bon-detail-gestion-des-colis.component.html',
  styleUrls: ['./bon-detail-gestion-des-colis.component.scss'],
})
export class BonDetailGestionDesColisComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  private _bonId: number;
  get bonId(): number { return this._bonId; }
  @Input() set bonId(value: number) {
    if (value) {
      this._bonId = value;
      this.setPaquets();
    }
  }

  @Input() allowEditing: boolean = false;
  @Input() canPointerColis: boolean = false;
  @Input() exportFileName: string = "liste-des-colis";

  @Output() colisUpdated = new EventEmitter<PaquetDto[]>();

  paquets: PaquetDto[] = [];

  constructor(private readonly paquetLexiClient: PaquetLexiClient,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  async setPaquets() {
    if (this.bonId != null) {
      this.paquets = await lastValueFrom(this.paquetLexiClient.getByBonId(this.bonId));
      this.colisUpdated.emit([...this.paquets]);
    }
  }

  async onRowInserting(paquet: PaquetDto) {
    try {
      paquet.bonId = this.bonId;
      paquet.numeroSuivi = paquet.numeroSuivi !== null && paquet.numeroSuivi !== undefined ? paquet.numeroSuivi : '';
      await lastValueFrom(this.paquetLexiClient.creer(paquet));
    }
    finally {
      await this.setPaquets();
    }
  }

  async onRowUpdating(e: any) {
    try {
      for(const field of Object.keys(e.newData)) {
        e.oldData[field] = e.newData[field];
      }
      await lastValueFrom(this.paquetLexiClient.update(e.oldData));
    }
    finally {
      await this.setPaquets();
    }
  }

  async onRowRemoving(paquet: PaquetDto) {
    try {
      await lastValueFrom(this.paquetLexiClient._delete(paquet.id, paquet.bonId));
    }
    finally {
      await this.setPaquets();
    }
  }

  onInitNewRow(e: { data: PaquetDto, component: any }) {
    e.data.numero = this.getNextNumero();
    setTimeout(() => {
      const newRowIndex = e.component.getVisibleRows().find(x => x.isEditing == true && x.isNewRow).rowIndex;
      e.component.editCell(newRowIndex, 'numeroSuivi');
    }, 10);
  }

  private getNextNumero() {
    if (this.paquets == null || this.paquets.length == 0) return 1;
    const maxNumero = Math.max(...this.paquets.map(x => x.numero));
    return maxNumero + 1;
  }
  
  async onKeyDown(e) {
    if (this.allowEditing && e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
  
  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
