import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../settings/auth.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { TypeUtilisateur, UserDto, UtilisateursLexiClient } from '@lexi-clients/lexi';

@Component({
  selector: 'app-voir-en-tant-que',
  templateUrl: './voir-en-tant-que.component.html',
  styleUrls: ['./voir-en-tant-que.component.scss'],
})
export class VoirEnTantQueComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  currentAsUserId: number;
  utilisateurs: UserDto[];

  constructor(
    private readonly authService: AuthService,
    private readonly utilisateursLexiClient: UtilisateursLexiClient,
  ) {
    this.subscriptions.add(
      this.authService.currentAsUserId$.subscribe((asUserId: number) => {
        this.currentAsUserId = asUserId;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    const collaborateurs = await lastValueFrom(this.utilisateursLexiClient.getUtilisateurs(TypeUtilisateur.collaborateur, false, true));
    this.utilisateurs = collaborateurs?.filter(x => x.id != this.authService.authenticatedUser.id && !x.isRoot && x.externalId != null);
  }

  changerAsUserId() {
    this.authService.setAsUserId(this.currentAsUserId, true);
  }

}
