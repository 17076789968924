import { Component, Input, ViewChild } from '@angular/core';
import { ObjectType, ClassificationTypeDto, ClassificationTypesLexiClient, ClassificationValueDto, ClassificationValuesLexiClient, LotStockageMarchandiseBySiteDto, LotStockageMarchandiseDto, ArticlesLexiClient } from '@lexi-clients/lexi';
import CustomStore from 'devextreme/data/custom_store';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { LotStockageMarchandiseBySiteListService } from 'lexi-angular/src/app/services/lot-stockage-marchandise-by-site.service';
import { lastValueFrom } from 'rxjs';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-lot-stockage-magasin-datagrid',
  templateUrl: './lot-stockage-magasin-datagrid.component.html',
  styleUrls: ['./lot-stockage-magasin-datagrid.component.scss'],
})
export class LotStockageMagasinDatagridComponent {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  private _textBox: DxTextBoxComponent;
  get textBoxPattern() { return this._textBox }
  @ViewChild('textBoxPattern') set textBoxPattern(value: DxTextBoxComponent) {
    this._textBox = value;
    this._textBox?.instance?.focus();
  }
 
  private _currentSiteId: number;
  get currentSiteId(): number { return this._currentSiteId; }
  @Input() set currentSiteId(value: number) {
    if (value != null && value != undefined) {
      this._currentSiteId = value;
      this.loadPageData();
    }
  }

  private _articleId: number;
  get articleId(): number { return this._articleId; }
  @Input() set articleId(value: number) {
    if (value != null && value != undefined) {
      this._articleId = value;
      this.dataGridStorageKey = "grille_lot_stockage_by_site_fiche_article";
      this.loadPageData();
    }
  }

  @Input() dataGridMarginTop: number;
  @Input() canAfficherPMP: boolean = false;

  private _prixId: number;
  get prixId(): number {
    return this._prixId;
  }
  @Input() set prixId(value: number) {
    this._prixId = value;
    if (value != null) {
      this.setLotStockageStore();
    }
  }

  lotStockageStore: CustomStore;
  dataGridStorageKey = "grille_lot_stockage_by_site_globale";
  showLegende = false;
  filtreSurQuantiteDataSource = [
    { id: true, intitule: "Quantité < Quantité Min." },
    { id: false, intitule: "Quantité > Quantité Max." },
  ];

  /** Liste complète des ClassificationValues qui sert de DataSource aux lookup des colonnes */
  classificationValuesNiveauUn: ClassificationValueDto[];
  classificationValuesNiveauDeux: ClassificationValueDto[];

  classificationTypes: ClassificationTypeDto[];
  selectedClassificationTypeId: number;
  selectedFiltreSurQuantite: boolean | null;
  showPopupStockSite: boolean = false;
  stockParSite: LotStockageMarchandiseDto[] = [];
  selectedArticle: LotStockageMarchandiseBySiteDto;
  pattern: string;

  readonly selectedClassificationTypeIdStorageKey = "LotStockageMagasinClassificationTypeId";

  readonly selectedFiltreSurQuantiteStorageKey = "LotStockageMagasinFiltreSurQuantite";

  constructor(
    private readonly lotStockageMarchandiseBySiteListService: LotStockageMarchandiseBySiteListService,
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly classificationTypesLexiClient: ClassificationTypesLexiClient,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  async loadPageData() {
    await this.setClassifications();

    // LocalStorage
    const storedClassificationType = this.getFromLocalStorage(this.selectedClassificationTypeIdStorageKey, true);
    if (storedClassificationType) this.selectedClassificationTypeId = storedClassificationType;
    const storedFiltreSurQuantite = this.getFromLocalStorage(this.selectedFiltreSurQuantiteStorageKey);
    if (storedFiltreSurQuantite != null) this.selectedFiltreSurQuantite = storedFiltreSurQuantite;
    this.setLotStockageStore();
  }

  setLotStockageStore() {
    const additionalParams = new Map().set("siteId", this.currentSiteId);
    if (this.articleId != null) additionalParams.set("articleId", this.articleId);
    if (this.selectedClassificationTypeId != null) additionalParams.set("classificationTypeId", this.selectedClassificationTypeId);
    if (this.selectedFiltreSurQuantite != null) additionalParams.set("qteSousQteMinOnly", this.selectedFiltreSurQuantite);
    if (this.pattern != null) additionalParams.set("pattern", this.pattern);
    if (this.prixId != null) additionalParams.set("prixId", this.prixId);

    this.lotStockageStore = new CustomStoreService(this.lotStockageMarchandiseBySiteListService).getCustomStore(additionalParams);
  }

  private async setClassifications() {
    this.classificationTypes = await lastValueFrom(this.classificationTypesLexiClient.getByObjectType(ObjectType.article));
    this.classificationValuesNiveauUn = await lastValueFrom(this.classificationValuesLexiClient.getAll(1));
    this.classificationValuesNiveauDeux = await lastValueFrom(this.classificationValuesLexiClient.getAll(2));
  }

  //#region Gestion des évènements
  onRowPrepared(e: { rowType: 'data' | 'header', key: LotStockageMarchandiseBySiteDto, rowElement: HTMLElement, cells: Array<{ cellElement: HTMLElement, key: LotStockageMarchandiseBySiteDto, column: { caption: string }, value: any }> }) {
    if (e.rowType == "data") {
      const quantiteCell = e.cells.find(x => x.column.caption == 'Quantité');
      if (e.key.quantiteMinimale != null && e.key.totalQuantite < e.key.quantiteMinimale) {
        quantiteCell.cellElement.classList.add('qte-sous-qte-min');
      }
      else if (e.key.quantiteMaximale != null && e.key.totalQuantite > e.key.quantiteMaximale) {
        quantiteCell.cellElement.classList.add('qte-superieur-a-qte-max');
      }
    }
  }

  async onClassificationTypeChanged() {
    // LocalStorage
    this.selectedClassificationTypeId != null
      ? localStorage.setItem(this.selectedClassificationTypeIdStorageKey, JSON.stringify(this.selectedClassificationTypeId))
      : localStorage.removeItem(this.selectedClassificationTypeIdStorageKey);
  }

  onFiltreSurQuantiteChanged() {
    // LocalStorage
    this.selectedFiltreSurQuantite != null
    ? localStorage.setItem(this.selectedFiltreSurQuantiteStorageKey, JSON.stringify(this.selectedFiltreSurQuantite))
    : localStorage.removeItem(this.selectedFiltreSurQuantiteStorageKey);
  }
  
  clearDatagridFilters() {
    this.dataGrid?.instance.clearFilter();
  }
  
  //#endregion

  //#region Méthodes Helpers
  getLinkToArticleDetail(e: LotStockageMarchandiseBySiteDto) {
    return `/article/${e.articleId}`;
  }

  classificationTypeDisplayExpr(type: ClassificationTypeDto) {
    return type && type.codeBo + " - " + type.intitule;
  }

  classificationValueDisplayExpr(data: ClassificationValueDto) {
    return data && data.codeBo + " - " + data.intitule;
  }

  private getFromLocalStorage(key: string, mustBeNumber?: boolean): any {
    const storedString = localStorage.getItem(key);

    // Si doit être un nombre, si ça ne l'est pas on retire du storage
    if (mustBeNumber && isNaN(Number(storedString))) {
      localStorage.removeItem(key);
      return null;
    }
    return storedString ? JSON.parse(storedString) : null;
  }

  calculateQuantitePrixVente(rowData) {
    return rowData.totalQuantite * rowData.prixVente;
  }

  getDifferenceQuantiteMinQuantite(rowData) {
    return rowData.quantiteMinimale - rowData.totalQuantite;
  }
  //#endregion

  //#region Stock par site
  showStockParSite = async (article: LotStockageMarchandiseBySiteDto) => {
    this.selectedArticle = article;
    this.stockParSite = null;
    this.showPopupStockSite = true;
    this.stockParSite = await lastValueFrom(this.articlesLexiClient.getStockArticleSocieteCourante(article?.articleId));
  }
  //#endregion

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }  
}
