/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum RaccourciType {
    article = 'article',
    client = 'client',
    commentaire = 'commentaire',
    processFacturation = 'processFacturation',
    groupe = 'groupe',
    piece = 'piece',
    typeSuiviFond = 'typeSuiviFond',
    mouvementStock = 'mouvementStock',
    listeAffranchissement = 'listeAffranchissement',
    operationsDeCaisse = 'operationsDeCaisse'
};

