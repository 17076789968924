<div class="form-container" *ngIf="partenaire">
  <dx-box direction="row" width="100%" align="center" crossAlign="center">
    <dxi-item class="back-button" [ratio]="1">
      <a routerLink="/{{partenaireCategorieIntitule}}s">
        <dx-button icon="back"></dx-button>
      </a>
    </dxi-item>

    <dxi-item [ratio]="4">
      <h2 id="title">
        <span *ngIf="!isCreation">{{partenaire?.codeBo}} - {{partenaire?.intitule}}</span>
        <span *ngIf="isCreation">Création d'un nouveau {{ this.partenaireCategorieIntitule }}</span>
      </h2>
      <div *ngIf="(!isCreation && (partenaire?.enCours != null || encoursClient != null)) && partenaire?.type != partenaireType.interne" style="font-size: 18px;">
        Encours : {{partenaire?.enCours ?? encoursClient | number}} F
      </div>
    </dxi-item>

    <dxi-item [ratio]="1"></dxi-item>
  </dx-box>


  <dx-box style="margin-bottom: 12px;" direction="row" width="100%">
    <dxi-item [ratio]="1">
      <div>
        <dx-button *ngIf="!isCreation && isModificationEnCours" class="action-button" type="success" text="Enregistrer" (onClick)="onUpdatePartenaire()" style="margin-right: 10px;"></dx-button>
        <dx-button *ngIf="!isCreation && isModificationEnCours" class="action-button" type="danger" text="Annuler" (onClick)="onCancelUpdate()"></dx-button>
        <dx-button *ngIf="isCreation" class="action-button" type="success" text="Créer" (onClick)="onCreateNewPartenaire()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item [ratio]="1" *ngIf="!isCreation">
      <div class="menu-button">
        <dx-drop-down-button
          [items]="actions"
          keyExpr="id"
          displayExpr="intitule"
          (onItemClick)="onActionClick($event)"
          [disabled]="isModificationEnCours"
          [dropDownOptions]="{ width: 120 }"
          text="Action"
          icon="smalliconslayout"
        ></dx-drop-down-button>
      </div>
    </dxi-item>
  </dx-box>

  <dx-form #form [formData]="partenaire" [readOnly]="!isCreation && !isModificationEnCours" labelLocation="top" [showColonAfterLabel]="false">
    <!-- TabPanel -->
    <dxi-item itemType="tabbed">
      <dxo-tab-panel-options [showNavButtons]="true" [deferRendering]="false"></dxo-tab-panel-options>

      <!-- Général -->
      <dxi-tab title="Général" [colCount]="4">
        <!-- Actif -->
        <dxi-item itemType="group" [colSpan]="4" [visible]="partenaire != null && !partenaire.actif">
          <span class="dx-icon-warning" style="font-size: 20px; color: rgb(204, 187, 38);"></span>
            Client désactivé
        </dxi-item>

        <!-- Administratif -->
        <dxi-item itemType="group" caption="Administratif" [colSpan]="2" colCount="2">
          <dxi-item dataField="codeBo" [label]="{ text: 'Code BackOffice'}" [editorOptions]="{readOnly: !isCreation }"  [editorOptions]="{ maxLength: 36 }"></dxi-item>
          <dxi-item dataField="intitule" [label]="{ text: 'Intitule' }" [editorOptions]="{ maxLength: 120 }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="raisonSociale" [label]="{ text: 'Raison Sociale' }" [editorOptions]="{ maxLength: 120 }"></dxi-item>
          <dxi-item dataField="identifiantEntreprise" [label]="{ text: 'N° Tahiti' }" [editorOptions]="{ maxLength: 20 }"></dxi-item>
          <dxi-item dataField="identifiantCommercial" [label]="{ text: 'N° RC' }" [editorOptions]="{ maxLength: 20 }"></dxi-item>
          <dxi-item dataField="numeroTvaIntracommunautaire" [label]="{ text: 'N° TVA intracommunautaire' }" [editorOptions]="{ maxLength: 20 }"></dxi-item>
          <dxi-item dataField="profilPartenaireId"
            [label]="{text: 'Profil'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: profilPartenaires,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: false,
              value: defaultProfilPartenaireId
            }"
          >
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            dataField="formeJuridiqueId"
            [label]="{ text: 'Forme juridique' }"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: formeJuridiques,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
            }"
          ></dxi-item>
          <dxi-item dataField="nom" [label]="{ text: 'Nom' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
          <dxi-item dataField="prenom" [label]="{ text: 'Prénom' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
          <dxi-item dataField="representantLegal" [label]="{ text: 'Représentant légal' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
          <dxi-item dataField="enseigne" [label]="{ text: 'Enseigne' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
          <dxi-item dataField="dateNaissance" [label]="{ text: 'Date de naissance'}" editorType="dxDateBox" [editorOptions]="{displayFormat: 'dd/MM/yyyy', width: '170px', type: 'date'}"></dxi-item>
          <dxi-item
            dataField="civilite"
            [label]="{text: 'Civilité'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: civiliteType,
              searchEnabled: true,
              displayExpr: 'libelle',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelle', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item dataField="adhesionOffresCommerciales" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Adhésion aux offres commerciales'}"></dxi-item>
        </dxi-item>
        

        <!-- Informations financières -->
        <dxi-item itemType="group" caption="Informations financières" [colSpan]="2" colCount="2" *ngIf="partenaire?.type != partenaireType.interne">
          <dxi-item dataField="{{isEncoursClientSaisissable ? 'enCours': ''}}" [label]="{text: 'Encours'}" editorType="dxNumberBox"
            [editorOptions]="{
              format: { type: 'fixedPoint', precision: 0 },
              readOnly: isEncoursClientSaisissable ? null: true,
              value: encoursClient
            }"
          ></dxi-item>
          <dxi-item dataField="plafondEncours" [label]="{text: 'Plafond encours'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
          <!-- <dxi-item dataField="jourPaiement" [label]="{text: 'Jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
          <!-- <dxi-item dataField="nombreJourPaiement" [label]="{text: 'Nombre jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
          <!-- <dxi-item dataField="debutPeriodePaiement" [label]="{text: 'Début période paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
          <!-- <dxi-item dataField="habitudePaiement" [label]="{ text: 'Habitude de paiement' }">
            <dxi-validation-rule type="stringLength" [max]="150"></dxi-validation-rule>
          </dxi-item> -->
          <dxi-item
            dataField="partenaireFactureId"
            [label]="{text: 'Partenaire facturé'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: partenaireDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item
            dataField="partenaireParentId"
            [label]="{text: 'Partenaire parent'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: partenaireDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <dxi-item dataField="controlEncours" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Contrôle encours'}"></dxi-item>
          <dxi-item dataField="encoursNegatifAutorise" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Encours négatif autorisé'}"></dxi-item>
          <dxi-item dataField="soumisTva" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Soumis à la TVA'}"></dxi-item>
          <dxi-item dataField="facturationHT" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Facturé en HT'}"></dxi-item>
        </dxi-item>

        <!-- Partie Client -->
        <dxi-item itemType="group" [caption]="partenaire?.type != partenaireType.interne ? 'Client' : 'Compta'" [colSpan]="1">
          <dxi-item dataField="estClient" [label]="{visible: false}" editorType="dxCheckBox" [editorOptions]="{text: 'Client'}" *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
          <dxi-item itemType="group" [disabled]="partenaire?.type == partenaireType.externe && !partenaire?.estClient">
            <dxi-item dataField="codeBoClient" [label]="{ text: 'Code BackOffice client'}" *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
            <dxi-item dataField="compteGeneralClient" [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }" caption="Compte collectif client">
              <dxi-validation-rule
                type="stringLength"
                [min]="longueurCompte"
                [max]="longueurCompte"
                ignoreEmptyValue="true"
                message="{{ messageLongueurCompteInvalide }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="compteAuxiliaireClient" [label]="{ text: 'Compte auxiliaire client' }" [editorOptions]="{ maxLength: 20 }">
              <dxi-validation-rule type="stringLength" [max]="20" message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
            </dxi-item>
          </dxi-item>
        </dxi-item>

        <!-- Partie Fournisseur -->
        <dxi-item itemType="group" caption="Fournisseur" [colSpan]="1" *ngIf="partenaire?.type != partenaireType.interne">
          <dxi-item dataField="estFournisseur" [label]="{text: ' '}" editorType="dxCheckBox" [editorOptions]="{text: 'Fournisseur'}"></dxi-item>
          <dxi-item itemType="group" [disabled]="!partenaire?.estFournisseur">
            <dxi-item dataField="codeBoFournisseur" [label]="{ text: 'Code BackOffice fournisseur' }" [editorOptions]="{ maxLength: 36 }"></dxi-item>

            <dxi-item dataField="compteGeneralFournisseur" [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }" caption="Compte collectif fournisseur">
              <dxi-validation-rule
                type="stringLength"
                [min]="longueurCompte"
                [max]="longueurCompte"
                ignoreEmptyValue="true"
                message="{{ messageLongueurCompteInvalide }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="compteAuxiliaireFournisseur" [label]="{ text: 'Compte auxiliaire fournisseur' }" [editorOptions]="{ maxLength: 20 }">
              <dxi-validation-rule type="stringLength" [max]="20" message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
            </dxi-item>
          </dxi-item>
        </dxi-item>

        <!-- Partie Logistique -->
        <dxi-item itemType="group" caption="Logistique" [colSpan]="1">
          <dxi-item
            dataField="lieuParDefautId"
            [label]="{text: 'Lieu de stockage par défaut'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: lieuStockageDataSource,
              searchEnabled: true,
              displayExpr: lieuStockageDisplayExpr,
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true,
              disabled: isClientExterne()
            }"
          ></dxi-item>

        </dxi-item>
        <!-- Partie Informations bancaires -->
        <dxi-item itemType="group" caption="Informations bancaires" [colSpan]="1">
          <dxi-item
            dataField="informationsBancaire.numeroCompte"
            [label]="{text: 'Numéro de compte'}"
            [editorOptions]="{ maxLength: 120 }"
          ></dxi-item>
          <dxi-item
            dataField="informationsBancaire.codeGuichet"
            [label]="{text: 'Code guichet'}"
            [editorOptions]="{ maxLength: 120 }"
          ></dxi-item>
          <dxi-item
            dataField="informationsBancaire.cleRib"
            [label]="{text: 'Clé RIB'}"
            [editorOptions]="{ maxLength: 120 }"
          ></dxi-item>
        </dxi-item>
      </dxi-tab>

      <!-- Adresses -->
      <dxi-tab title="Adresses" colCount="4">
        <!-- Géographique -->
        <dxi-item itemType="group" caption="Géographique" [colSpan]="1">
          <dxi-item dataField="adresseGeographique1" [label]="{ text: 'Adresse géographique' }" [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="adresseGeographique2" [label]="{ text: 'Adresse géographique 2' }" [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="adresseGeographique3" [label]="{ text: 'Adresse géographique 3' }" [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            dataField="paysId"
            [label]="{text: 'Pays'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: paysDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeDouane', 'codeNumerique'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item
            dataField="ileId"
            [label]="{text: 'Île'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: ileDataSource,
              searchEnabled: true,
              displayExpr: 'libelle',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelle'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item
            dataField="secteurGeographiqueId"
            [label]="{text: 'Secteur géographique'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: secteurGeographiqueDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item dataField="commune" [label]="{ text: 'Commune' }" [editorOptions]="{ maxLength: 120 }">
            <dxi-validation-rule type="stringLength" [max]="120"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="informationsLivraison" editorType="dxTextArea"
            [editorOptions]="{height: 125, maxLength: 255}"
            [label]="{text: 'Informations livraison'}"
          ></dxi-item>
        </dxi-item>

        <!-- Postale -->
        <dxi-item itemType="group" caption="Postale" [colSpan]="1">
          <dxi-item dataField="adressePostale1" [label]="{ text: 'Adresse postale' }"  [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="adressePostale2" [label]="{ text: 'Adresse postale 2' }" [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="adressePostale3" [label]="{ text: 'Adresse postale 3' }" [editorOptions]="{ maxLength: 80 }">
            <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="codePostal" [label]="{ text: 'Code postal' }" [editorOptions]="{ maxLength: 12 }">
            <dxi-validation-rule type="stringLength" [max]="12"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <!-- Téléphonique -->
        <dxi-item itemType="group" caption="Téléphonique" [colSpan]="1">
          <dxi-item dataField="telephoneFixe" [label]="{ text: 'Téléphone fixe' }" [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30 }">
            <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="phonePattern" [message]="phoneBrokePatternMessage"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="telecopie" [label]="{ text: 'Fax' }" [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
            <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="phonePattern" [message]="phoneBrokePatternMessage"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="telephonePortable" [label]="{ text: 'Téléphone portable' }" [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
            <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="phonePattern" [message]="phoneBrokePatternMessage"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="telephoneAutre" [label]="{ text: 'Téléphone autre' }" [editorOptions]="{valueChangeEvent: 'keyup', maxLength: 30}">
            <dxi-validation-rule type="stringLength" [max]="30"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="phonePattern" [message]="phoneBrokePatternMessage"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <!-- Électronique -->
        <dxi-item itemType="group" caption="Électronique" [colSpan]="1">
          <dxi-item dataField="email" [label]="{ text: 'Courriel' }" [editorOptions]="{ maxLength: 150 }">
            <dxi-validation-rule type="stringLength" [max]="150"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="siteWeb" [label]="{ text: 'Site internet' }"></dxi-item>
        </dxi-item>
      </dxi-tab>

      <!-- Fret local -->
      <dxi-tab title="Fret local">
        <dxi-item itemType="group" [colSpan]="2" colCount="2">
          <dxi-item dataField="estRevendeur" [label]="{ text: 'Est revendeur' }" editorType="dxSwitch"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" colCount="3">
          <dxi-item
            dataField="transporteurPrefereId"
            [label]="{text: 'Transporteur préféré'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: transporteurDataSource,
              searchEnabled: true,
              displayExpr: 'designation',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['designation', 'codeBo', 'codeInternational'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <dxi-item
            dataField="lieuDebarquementPrivilegieId"
            [label]="{text: 'Lieu de débarquement préféré'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: lieuDebarquementDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
        </dxi-item>

        <dxi-item itemType="group">
          <app-partenaire-fret-local-settings-form
            [colCount]="3"
            [colSpan]="1"
            [fretLocalSettings]="partenaire?.fretLocalSettings"
            [formIsReadOnly]="!isModificationEnCours"
            [motifRefusDemande]="motifRefusDemande"
            [typeUtilisateur]="typeUtilisateurRuunui"
          ></app-partenaire-fret-local-settings-form>
        </dxi-item>
      </dxi-tab>

      <!-- Notes -->
      <dxi-tab title="Notes" colCount="4">
        <dxi-item dataField="notes" [label]="{text: 'Notes'}" editorType="dxTextArea" [editorOptions]="{height: 80}"></dxi-item>
      </dxi-tab>

      <!-- Classifications -->
      <dxi-tab title="Classifications" [disabled]="isCreation" *ngIf="partenaire?.type != partenaireType.interne">
        <app-classification-datagrid *ngIf="!isCreation" [objectType]="objectType.partenaire" [itemId]="partenaireId" [heightToDeduce]="heightToDeduce"></app-classification-datagrid>
      </dxi-tab>

      <!-- Contacts -->
      <dxi-tab title="Contacts" [disabled]="isCreation" *ngIf="partenaire?.type != partenaireType.interne">
        <app-contact-list *ngIf="!isCreation" [clientId]="partenaireId" [heightToDeduce]="heightToDeduce"></app-contact-list>
      </dxi-tab>

      <!-- Factures -->
      <dxi-tab title="Factures" [disabled]="isCreation" *ngIf="partenaire?.type != partenaireType.interne">
        <app-facture-list *ngIf="!isCreation" [clientId]="partenaireId"></app-facture-list>
      </dxi-tab>

      <!-- Connaissements -->
      <dxi-tab title="Connaissements" [disabled]="isCreation" *ngIf="partenaire?.type != partenaireType.interne">
        <app-connaissement-list *ngIf="!isCreation" [clientId]="partenaireId"></app-connaissement-list>
      </dxi-tab>

      <!-- Prix -->
      <dxi-tab title="Prix" [disabled]="isCreation">
        <app-client-prix-list *ngIf="!isCreation" [clientId]="partenaireId"></app-client-prix-list>
      </dxi-tab>

      <!-- Documents -->
      <dxi-tab title="Documents" [disabled]="isCreation">
        <app-document-list [objectId]="partenaireId" [objectType]="objectType.partenaire"></app-document-list>
      </dxi-tab>

      <!-- Lieux de stockage -->
      <dxi-tab title="Lieux de stockage" [disabled]="isCreation">
        <app-lieu-stockage-tree-list
          [partenaireId]="partenaireId"
          [enableActions]="canGererLieuxStockages && isModificationEnCours"
          [currentSiteId]="currentSiteId"
          [partenaireCodeBo]="partenaire?.codeBo"
          [heightToDeduce]="heightToDeduce"
          (onUpdateLieuStockage)="setLieuStockageDataSource()"
        ></app-lieu-stockage-tree-list>
      </dxi-tab>

      <!-- Caractéristique -->
      <dxi-tab title="Caractéristique" [disabled]="isCreation">
        <app-gestion-caracteristiques *ngIf="!isCreation" [objectId]="partenaireId" [objectType]="objectType.partenaire" [heightToDeduce]="heightToDeduce"></app-gestion-caracteristiques>
      </dxi-tab>
    </dxi-item>
  </dx-form>
</div>