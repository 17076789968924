import { Permissions, SocieteAvecPermissionsPortailClientDto, TypeUtilisateurRuunui } from '@lexi-clients/lexi';

export interface Navigation {
    icon: string;
    text: string;
    path: string | null;
    permissionsRequises: Permissions[];
    visible: boolean;
    expanded?: boolean;
    items: Navigation[];
}

const donneesBaseNavigation = (enableTransporteurs: boolean): Navigation => {
    const donneesBase: Navigation =  {
        text: 'Données de base',
        path: '',
        icon: 'folder',
        permissionsRequises: [],
        visible: true,
        items: [
            { text: 'Clients', path: '/clients', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
            { text: 'Articles', path: '/articles', icon: 'product', permissionsRequises: [], visible: true, items: [] } as Navigation,
            { text: 'Fournisseurs', path: '/fournisseurs', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
        ]
    };

    if (enableTransporteurs) {
        donneesBase.items.push(
            { text: 'Transporteurs', path: '/transporteurs', icon: 'icon ion-md-boat', permissionsRequises: [Permissions.canGererTransporteurs], visible: true, items: [] } as Navigation
        );
    }

    return donneesBase;
}

export interface NavigationTop {
    id: number;
    title: string;
    navigation: Navigation[];
    visible: boolean;
}

export enum NavigationCollaborateurMenuId {
    vente = 0,
    logistique = 2,
    sav = 3,
    douane = 4,
    fretLocal = 5,
    pointDeVente = 6,
    kpi = 7,
    administration = 8,
    comptabilite = 9
}

export class Parametrage {
    currentUserType: TypeUtilisateurRuunui;
    currentUserCanGererTransporteurs: boolean;
    /** true si la société en cours gère du stock */
    currentSocieteManageStock: boolean;
}

export const navigationCollaborateur = (parametrage: Parametrage): Array<NavigationTop> => {
    const enableTransporteurs: boolean = parametrage.currentUserCanGererTransporteurs && parametrage.currentUserType == TypeUtilisateurRuunui.chargeur;

    return [
        {
            id: NavigationCollaborateurMenuId.vente,
            title: 'Vente',
            visible: false,
            navigation: [
                { text: 'Site', path: '/site-en-cours', icon: 'home', permissionsRequises: [Permissions.canManageSite], visible: true, items: [] } as Navigation,
                { text: 'Clients', path: '/clients', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
                { text: 'Articles', path: '/articles', icon: 'product', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Pièces', path: null, icon: 'file', visible: true, permissionsRequises: [], items: [
                    { text: 'Pièces', path: '/vente/pieces', icon: 'file', visible: true, permissionsRequises: [], items: [] } as Navigation,
                    { text: 'Règlements', path: '/vente/reglements', icon: 'file', visible: true, permissionsRequises: [], items: [] } as Navigation,
                ]} as Navigation,
                { text: 'Stock', path: null, icon: 'mediumiconslayout', visible: parametrage.currentSocieteManageStock, permissionsRequises: [], items: [
                    { text: 'État', path: '/visualisation-stock', icon: 'mediumiconslayout', visible: parametrage.currentSocieteManageStock, permissionsRequises: [Permissions.accesVueConsultationStock], items: [] } as Navigation,
                    { text: 'Mouvements', path: '/visualisation-des-mouvements', icon: 'icon ion-md-swap', visible: parametrage.currentSocieteManageStock, permissionsRequises: [Permissions.accesVueConsultationStock], items: [] } as Navigation,
                    { text: 'Bons', path: '/visualisation-des-bons', icon: 'textdocument', visible: parametrage.currentSocieteManageStock, permissionsRequises: [], items: [] } as Navigation,
                ] } as Navigation,
                { text: 'Éditions', path: '/vente/editions', icon: 'paste', visible: true, permissionsRequises: [Permissions.accesEditionsTpv], items: [] } as Navigation,
                { text: 'Statistiques', path: '/vente/statistiques-site', icon: 'paste', visible: true, permissionsRequises: [Permissions.canAfficherStatistiqueTpv], items: [] } as Navigation,
                { text: 'Caisse', path: null, icon: 'icon ion-md-desktop', visible: true, permissionsRequises: [], items: [
                    { text: 'Contrôle', path: '/vente/controle-de-periode', icon: 'clock', visible: true, permissionsRequises: [Permissions.canCloturerCaisse], items: [] } as Navigation,
                    { text: 'Gestion des caisses', path: '/vente/caisses', icon: 'product', visible: true, permissionsRequises: [Permissions.canManageTerminaux], items: [] } as Navigation,
                    { text: 'Raccourcis', path: '/vente/raccourcis', icon: 'movetofolder', visible: true, permissionsRequises: [Permissions.canGererRaccourcis], items: [] } as Navigation,
                ] } as Navigation,
                { text: 'Gestion', path: null, icon: 'folder', visible: true, permissionsRequises: [], items: [
                    { text: 'Opérations commerciales', path: '/vente/operations-commerciales', icon: 'chart', visible: true, permissionsRequises: [Permissions.administrationOperationsCommerciales], items: [] } as Navigation,
                    { text: 'Bons d\'achat', path: '/vente/bons-achat', icon: 'gift', permissionsRequises: [Permissions.administrationBonsAchat], visible: true, items: [] } as Navigation,
                ]} as Navigation,
                { text: 'Synchronisation Bo', path: '/vente/synchronisation-back-office', icon: 'download', visible: true, permissionsRequises: [Permissions.canSynchroniserBackoffice], items: [] } as Navigation
            ]
        },
        {
            id: NavigationCollaborateurMenuId.logistique,
            title: 'Logistique',
            visible: false,
            navigation: navigationLogistique(parametrage, enableTransporteurs)
        },
        {
            id: NavigationCollaborateurMenuId.sav,
            title: 'Sav',
            visible: false,
            navigation: [
                // { text: 'Commun', path: '', icon: 'folder', permissionsRequises: [], visible: true, items: [
                //     { text: 'Clients', path: '/sav/clients', icon: 'folder', permissionsRequises: [], visible: true, items: [] } as Navigation,
                //     { text: 'Produits', path: '/sav/articles', icon: 'folder', permissionsRequises: [], visible: true, items: [] } as Navigation,
                // ] } as Navigation,
                { text: 'Demandes', path: '/sav/demandes', icon: 'help', permissionsRequises: [], visible: true, items: [] } as Navigation,
                { text: 'Activités', path: '/sav/activites', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
                { text: 'Dossiers', path: '/sav/dossiers', icon: 'activefolder', permissionsRequises: [], visible: true, items: [] } as Navigation,
                // { text: 'Interventions', path: '/sav/interventions', icon: 'user', visible: true, permissionsRequises: [], items: [] } as Navigation,
                // { text: 'Parc', path: '/sav/parcs', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
                // { text: 'Contrats', path: '/sav/contrats', icon: 'user', visible: true, permissionsRequises: [], items: [] } as Navigation,
                // { text: 'Paramètres', path: '', icon: 'folder', visible: true, permissionsRequises: [], items: [
                //     { text: 'Type de contrats', path: '/sav/contrat-types', visible: true, icon: 'user', permissionsRequises: [], items: [] } as Navigation,
                //     { text: 'Type d\'évènements', path: '/sav/evenement-types', visible: true, icon: 'user', permissionsRequises: [], items: [] } as Navigation,
                //     { text: 'Flux', path: '/sav/flux', icon: 'user', visible: true, permissionsRequises: [], items: [] } as Navigation,
                //     { text: 'Caractéristiques', path: '/sav/caracteristiques', icon: 'user', visible: true, permissionsRequises: [], items: [] } as Navigation,
                // ] } as Navigation,
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.douane,
            title: 'Douane',
            visible: false,
            navigation: [
                // { text: 'Déclarations', path: '/douane/declarations', icon: '', permissionsRequises: [], visible: true, items: [] } as Navigation,
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.fretLocal,
            title: 'Fret local',
            visible: false,
            navigation: [
                { text: 'Connaissements', path: '/fret-local/connaissements', icon: 'icon ion-md-boat', visible: parametrage.currentUserType == TypeUtilisateurRuunui.chargeur, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Connaissements', path: null, icon: 'icon ion-md-boat', permissionsRequises: [], visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, items: [
                    { text: 'Liste', path: '/fret-local/connaissements-liste', icon: 'bulletlist', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                    { text: 'Nouveau', path: '/fret-local/nouveau', icon: 'add', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                    { text: 'Facturer', path: '/fret-local/facturer', icon: 'money', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                    { text: 'Export', path: '/fret-local/export', icon: 'to', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                ]} as Navigation,
                { text: 'Demandes chargeurs', path: '/fret-local/demandes-chargeurs', icon: 'icon ion-md-filing', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Voyages', path: '/fret-local/voyages', icon: 'icon ion-md-clipboard', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Secteurs géographiques', path: '/fret-local/secteurs-geographique', icon: 'globe', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Nomenclatures', path: '/fret-local/nomenclatures', icon: 'icon ion-md-document', visible: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Synchronisation Bo', path: '/fret-local/synchronisation-back-office', icon: 'download', visible: true, permissionsRequises: [Permissions.canSynchroniserBackoffice], items: [] } as Navigation,
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.kpi,
            title: 'KPI',
            visible: false,
            navigation: [
                { text: 'Tableaux de bord', path: '/kpi/dashboards', icon: 'chart', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Gérer les tableaux de bord', path: '/kpi/dashboards/admin', icon: 'preferences', visible: true, permissionsRequises: [], items: [] } as Navigation,
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.comptabilite,
            title: 'Comptabilité',
            visible: false,
            navigation: [
                { text: 'Plan comptable', path: '/comptabilite/comptes-generaux', icon: 'chart', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Interface comptable', path: '/comptabilite/interface-comptable', icon: 'icon ion-md-desktop', visible: true, permissionsRequises: [], items: []} as Navigation,
            ]
        }
    ];
}

export const navigationPartenaire = (societe: SocieteAvecPermissionsPortailClientDto): Array<NavigationTop> => {
    return [
        {
            id: 0,
            title: '',
            visible: false,
            navigation: [
                { text: 'Profil', path: '/portail-client/information-profil', icon: 'user', permissionsRequises: [], visible: true, items: [] } as Navigation,
                { text: 'Factures', path: '/portail-client/factures', icon: 'file', visible: societe?.portailClientAffichageFactures ?? false, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Demandes', path: '/portail-client/demandes', icon: 'bulletlist', visible: societe?.portailClientAffichageDemandes ?? false, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Interventions', path: '/portail-client/interventions', icon: 'icon ion-md-construct', visible: societe?.portailClientAffichageActivites ?? false, permissionsRequises: [], items: [] } as Navigation,
                // { text: 'Dossiers', path: '/portail-client/contrats', icon: 'icon ion-md-document', visible: societe?.portailClientAffichageDossiers ?? false, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Connaissements', path: '/portail-client/connaissements', icon: 'icon ion-md-boat', visible: societe?.portailClientAffichageConnaissements ?? false, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Catalogue', path: '/portail-client/articles', icon: 'icon ion-md-cart', visible: societe?.portailClientAffichageArticles ?? false, permissionsRequises: [], items: [] } as Navigation
            ]
        }
    ];
};

const navigationLogistique = (parametrage: Parametrage, enableTransporteurs: boolean): Navigation[] => {
    const stock: Navigation = { text: 'Stock', path: null, icon: 'folder', permissionsRequises: [], visible: true, items: [
        { text: 'Etat du stock', path: '/visualisation-stock', icon: 'mediumiconslayout', visible: true, permissionsRequises: [Permissions.accesVueConsultationStock], items: [] } as Navigation,
        { text: 'Numéros de série', path: '/logistique/registres-numeros-serie', icon: 'startswith', visible: true, permissionsRequises: [], items: [] } as Navigation,
        { text: 'Mouvements', path: '/visualisation-des-mouvements', icon: 'icon ion-md-swap', visible: true, permissionsRequises: [Permissions.accesVueConsultationStock], items: [] } as Navigation,
    ]};

    const activites: Navigation = { text: 'Activités', path: null, icon: 'folder', permissionsRequises: [], visible: true,
        items: parametrage.currentSocieteManageStock
            ? [
                { text: 'Documents', path: '/logistique/documents', icon: 'textdocument', visible: true, permissionsRequises: [Permissions.canAfficherDocumentsLogistiques], items: [] } as Navigation,
                { text: 'Bons', path: '/visualisation-des-bons', icon: 'textdocument', visible: true, permissionsRequises: [], items: [] } as Navigation,
            ] : [
                { text: 'Réceptions', path: '/logistique/receptions', icon: 'icon ion-md-paper', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Inventaires', path: '/logistique/inventaires', icon: 'icon ion-md-clipboard', visible: true, permissionsRequises: [], items: [] } as Navigation,
                { text: 'Préparations', path: '/logistique/preparations', icon: 'box', visible: true, permissionsRequises: [], items: [] } as Navigation,
            ]
    };
    const parametres: Navigation =
        { text: 'Paramètres', path: null, icon: 'folder', permissionsRequises: [], visible: true, expanded: false, items: [
            { text: 'Back Office', path: '/logistique/traitements', icon: 'preferences', visible: true, permissionsRequises: [], items: [] } as Navigation,
            { text: 'Gestion des terminaux', path: '/logistique/terminaux', icon: 'cart', visible: true, permissionsRequises: [Permissions.canManageTerminaux], items: [] } as Navigation,
            { text: 'Lieux de stockage', path: '/logistique/lieux-de-stockage', icon: 'verticalalignbottom', visible: parametrage.currentSocieteManageStock, permissionsRequises: [Permissions.canGererLieuStockages], items: [] } as Navigation,
            { text: 'Référencement', path: '/logistique/code-barres', icon: 'icon ion-md-barcode', visible: true, permissionsRequises: [], items: [] } as Navigation,
        ]};

    const navigations: Navigation[] = [];

    if (parametrage.currentSocieteManageStock) {
        navigations.push(stock);
    }
    navigations.push(activites);
    navigations.push(parametres);
    navigations.push(donneesBaseNavigation(enableTransporteurs))

    return navigations;
};
