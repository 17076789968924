import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectType, PartenaireDto, PartenairesLexiClient, SocieteToUpdateDto, ClientsLexiClient, TypeUtilisateurRuunui, MotifRefusDemande,
  ConnaissementsRevatuaLexiClient, FormesJuridiquesLexiClient, FormeJuridiqueDto, PartenaireType, Permissions, LieuStockageDto, LieuStockagesLexiClient, ProfilPartenairesLexiClient, ProfilPartenaireDto,
  Civilite} from '@lexi-clients/lexi';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../modules/shared/references/references';
import DataSource from 'devextreme/data/data_source';
import { TransporteurListService } from '../../services/transporteur.service';
import { LieuDebarquementListService } from '../../services/lieu-debarquement.service';
import { PaysListService } from '../../services/pays.service';
import { IleListService } from '../../services/ile.service';
import { SecteurGeographiqueListService } from '../../services/secteur-geographique.service';
import { lastValueFrom, filter, Subscription, mergeMap, take } from 'rxjs';
import { SocieteService } from '../../services/societe.service';
import { ValidationResult } from 'devextreme/ui/validation_group';
import { AuthService } from '../../settings/auth.service';
import { DxDataSourceService } from '../../shared/services/dx-data-source.service';
import { PartenaireCategorieEnum, partenaireCategories } from '../../models/enums/partenaireCategorie';

@Component({
  selector: 'app-partenaire-detail',
  templateUrl: './partenaire-detail.component.html',
  styleUrls: ['./partenaire-detail.component.scss'],
})
export class PartenaireDetailComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly storedPartenaireToDuplicateKey = "PARTENAIRE_A_DUPLIQUER";
  readonly doRefreshKey = "FORCER_RELOAD_PARTENAIRE_DETAIL";
  @ViewChild('form') form: DxFormComponent;

  // phone validation pattern
  phonePattern: any = /^[^a-zA-Z]*$/;
  phoneBrokePatternMessage: string = "Ce champ ne doit pas contenir de lettres";
  longueurCompte : number = 7;
  messageLongueurCompteInvalide: string;
  isEncoursClientSaisissable: boolean = false;
  encoursClient: number;
  objectType = ObjectType;
  partenaireId: number;
  partenaire: PartenaireDto;
  isCreation: boolean = false;
  isModificationEnCours: boolean = false;
  actions: Array<{ id: string, intitule: string }>;
  transporteurDataSource: DataSource;
  lieuDebarquementDataSource: DataSource;
  paysDataSource: DataSource;
  ileDataSource: DataSource;
  secteurGeographiqueDataSource: DataSource;
  partenaireDataSource: DataSource;
  profilPartenaires: ProfilPartenaireDto[];
  formeJuridiques: FormeJuridiqueDto[];
  typeUtilisateurRuunui: TypeUtilisateurRuunui;
  TypeUtilisateurRuunui = TypeUtilisateurRuunui;
  motifRefusDemande: MotifRefusDemande[] = [];
  isParametrageFretLocalActif: boolean = false;
  lieuStockageDataSource: LieuStockageDto[] = [];
  partenaireCategorie = PartenaireCategorieEnum.internes;
  partenaireCategories = partenaireCategories;
  partenaireCategorieIntitule = "";
  currentSiteId: number;
  partenaireType = PartenaireType;
  civiliteEnum = Civilite;
  defaultProfilPartenaireId: number;
  canGererLieuxStockages: boolean = false;
  /** Représente la hauteur des éléments au-dessus de la dataGrid du contenu de l'onglet */
  readonly heightToDeduce: number = 290;

  readonly civiliteType = [
    {id : this.civiliteEnum.madame, libelle: 'Mme'},
    {id : this.civiliteEnum.monsieur, libelle: 'M'}
  ]

  constructor(
    private readonly partenairesLexiClient: PartenairesLexiClient,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly transporteurService: TransporteurListService,
    private readonly lieuDebarquementService: LieuDebarquementListService,
    private readonly paysService: PaysListService,
    private readonly ileService: IleListService,
    private readonly societeService: SocieteService,
    private readonly secteurGeographiqueService: SecteurGeographiqueListService,
    private readonly clientLexiClient: ClientsLexiClient,
    private readonly authService: AuthService,
    private readonly connaissementsRevatuaLexiClient: ConnaissementsRevatuaLexiClient,
    private readonly formeJuriqueLexiClient: FormesJuridiquesLexiClient,
    private readonly lieuStockagesLexiClient: LieuStockagesLexiClient,
    private readonly profilPartenairesLexiClient: ProfilPartenairesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.setCurrentSiteId();
    this.canGererLieuxStockages = this.authService.securityUserisGrantedWith(Permissions.canGererLieuStockages);

    this.typeUtilisateurRuunui = await this.authService.getStoredUserTypeRuunui();
    this.formeJuridiques = await lastValueFrom(this.formeJuriqueLexiClient.getAll());
    this.profilPartenaires = await lastValueFrom(this.profilPartenairesLexiClient.getAll());

    const sub = this.activatedRoute.url
      .pipe(
        // On ne prend que le premier élt du flux pour éviter des effets de bord lorsqu'on recharge la page
        take(1),
        mergeMap(urlSegment => {
          this.partenaireCategorieIntitule = urlSegment[0].path;
          return this.activatedRoute.params;
        })
      )

      .subscribe(async params => {
        const partenaireId = params['id'];

        switch (this.partenaireCategorieIntitule) {
          case "client": {
            this.partenaireCategorie = PartenaireCategorieEnum.clients;
            break;
          }
          case "fournisseur": {
            this.partenaireCategorie = PartenaireCategorieEnum.fournisseurs;
            break;
          }
          case "partenaire": {
            this.partenaireCategorie = PartenaireCategorieEnum.internes;
            break;
          }
        }

        // Création d'un nouveau partenaire
        if (isNaN(Number(partenaireId))) {
          // Mauvais paramètre, on redirige sur la page de création d'un nouveau client
          if (partenaireId != 'nouveau') {
            this.router.navigate([`/${this.partenaireCategorieIntitule}/nouveau`]);
            return;
          }
          this.isCreation = true;
          this.setNewPartenaire();
        }

        // Modification d'un partenaire existant
        else {
          this.partenaireId = Number(partenaireId);
          await this.setPartenaire();
        }

        // Initialisation de la taille des comptes et du mode de calcul de l'encours client
        this.subscriptions.add(
          this.societeService.currentSociete$.pipe(
            filter((societe: SocieteToUpdateDto) => societe != null)
          ).subscribe(async (societe: SocieteToUpdateDto) => {
            this.longueurCompte = societe.parametrageComptable?.longueurComptes ?? 7;
            this.messageLongueurCompteInvalide = `Si renseigné, le compte doit comporter ${this.longueurCompte} caractères.`
          })
        );

        // Set de la liste déroulante des actions
        this.setActions();
        await this.initDataSources();

        if (this.authService.currentSociete.fretLocalActif && this.typeUtilisateurRuunui == TypeUtilisateurRuunui.armateur) {
          this.motifRefusDemande = await lastValueFrom(this.connaissementsRevatuaLexiClient.getListDemandeMotifsRefus());
        }
      });
    this.subscriptions.add(sub);
  }

  private async setPartenaire() {
    this.partenaire = await lastValueFrom(this.partenairesLexiClient.getById(this.partenaireId));
    this.defaultProfilPartenaireId = this.partenaire.profilPartenaireId;
    this.clientLexiClient.getEncoursEtPlafondClient(this.partenaireId).subscribe(async result => {
      this.encoursClient = result.encours;
    });
  }

  private setNewPartenaire() {
    if (localStorage.getItem(this.doRefreshKey)) {
      localStorage.removeItem(this.doRefreshKey);
      window.location.reload(); // Nécessaire pour rafraîchir la vue correctement (notamment le bouton "Créer")
      return;
    }

    const storedPartenaire = localStorage.getItem(this.storedPartenaireToDuplicateKey);
    if (storedPartenaire) {
      this.partenaire = JSON.parse(storedPartenaire);
      delete this.partenaire.id;
      this.partenaire.codeBo = null;
      this.partenaire.codeBoClient = null;
      this.partenaire.codeBoFournisseur = null;
      localStorage.removeItem(this.storedPartenaireToDuplicateKey);
    }
    else {
      this.partenaire = {
        soumisTva: true,
        actif: true,
        estClient: this.partenaireCategorie == PartenaireCategorieEnum.clients,
        estFournisseur: this.partenaireCategorie == PartenaireCategorieEnum.fournisseurs,
      };
    }

    if (this.partenaireCategorie == PartenaireCategorieEnum.clients || this.partenaireCategorie == PartenaireCategorieEnum.fournisseurs) {
      const profilsExternes = this.profilPartenaires.filter(f => f.type == this.partenaireType.externe && f.intitule.toUpperCase().includes('EXTERNE'));
      this.defaultProfilPartenaireId = profilsExternes[0]?.id;
    }
    else if (this.partenaireCategorie == PartenaireCategorieEnum.internes){
      const profilsInternes = this.profilPartenaires.filter(f => f.type == this.partenaireType.interne && f.intitule.toUpperCase().includes('INTERNE'));
      this.defaultProfilPartenaireId = profilsInternes[0]?.id;
    }
  }


  async onCreateNewPartenaire() {
    // On n'empêche pas la création si seules les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult = this.form.instance.validate();
    if (!formValidationResult.isValid) {
      const allArePhonePattern = formValidationResult.brokenRules.every( rule =>
        rule.type === "pattern"
        && rule.message === this.phoneBrokePatternMessage
      );

      if (!allArePhonePattern) {
        // Il y a au moins une règle brisée qui n'est pas de type "phonePattern" : on ne valide pas
        return;
      }
    };
    const newClientId = await lastValueFrom(this.partenairesLexiClient.create(this.partenaire));
    notify({closeOnClick: true, message: "Client créé avec succès."}, NotificationType.Success);
    this.isCreation = false;
    this.router.navigate([`/${this.partenaireCategorieIntitule}/${newClientId}`]);
  }

  async onUpdatePartenaire(message: string = null) {
    // On n'empêche pas la création si seules les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult = this.form.instance.validate();
    if (!formValidationResult.isValid) {
      if ( formValidationResult.brokenRules ) {
        const allArePhonePattern = formValidationResult.brokenRules.every( rule =>
          rule.type === "pattern"
          && rule.message === this.phoneBrokePatternMessage
        );

        if (!allArePhonePattern) {
          // Il y a au moins une règle brisée qui n'est pas de type "phonePattern" : on ne valide pas
          return;
        }
      }
    }

    this.isModificationEnCours = false;
    await lastValueFrom(this.partenairesLexiClient.update(this.partenaireId, this.partenaire));
    this.setActions();
    if (!message) {
      message = "Client modifié avec succès.";
    }
    notify({closeOnClick: true, message}, NotificationType.Success);
    await this.setPartenaire();
  }

  async onCancelUpdate() {
    this.isModificationEnCours = false;
    await this.setPartenaire();
  }

  onActionClick(e: any) {
    if( e.itemData.id == "edit") {
      this.isModificationEnCours = true;
    }

    if( e.itemData.id == "dupliquer") {
      this.onDuplicatePartenaire();
    }

    if( e.itemData.id == "activerClient") {
      this.activerClient();
    }

    if( e.itemData.id == "desactiverClient") {
      this.desactiverClient();
    }
  }

  private onDuplicatePartenaire() {
    localStorage.setItem(this.doRefreshKey, "true");
    localStorage.setItem(this.storedPartenaireToDuplicateKey, JSON.stringify(this.partenaire));
    this.router.navigate([`/${this.partenaireCategorieIntitule}/nouveau`]);
  }

  private async activerClient() {
    this.partenaire.actif = true;
    this.onUpdatePartenaire("Client activé avec succès.");
  }

  private async desactiverClient() {
    this.partenaire.actif = false;
    this.onUpdatePartenaire("Client desactivé avec succès.");
  }

  private setActions() {
    if (this.partenaire.actif) {
      this.actions = [
        { id: 'edit', intitule: 'Modifier' },
        { id: 'dupliquer', intitule: 'Dupliquer' },
        { id: 'desactiverClient', intitule: 'Désactiver client' },
      ];
    }
    else {
      this.actions = [
        { id: 'edit', intitule: 'Modifier' },
        { id: 'dupliquer', intitule: 'Dupliquer' },
        { id: 'activerClient', intitule: 'Activer client' },
      ];
    }
  }

  private setCurrentSiteId() {
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => this.currentSiteId = siteId)
    );
  }

  private async initDataSources() {
    this.partenaireDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox(this.partenaire.estClient, this.partenaire.estFournisseur);
    this.transporteurDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.transporteurService);
    this.lieuDebarquementDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.lieuDebarquementService);
    this.paysDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.paysService);
    this.ileDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.ileService);
    this.secteurGeographiqueDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.secteurGeographiqueService);
    await this.setLieuStockageDataSource();
  }

  async setLieuStockageDataSource() {
    this.lieuStockageDataSource = this.partenaireId != null ? await lastValueFrom(this.lieuStockagesLexiClient.getByPartenaire(this.partenaireId)) : [];
  }

  lieuStockageDisplayExpr(data: LieuStockageDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }

  isClientExterne() {
    return this.partenaire?.type == this.partenaireType.externe;
  }
}
