/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AffecterConnaissementNumeroRevatuaDto } from '../model/models';
import { ConnaissementLexiResultDto } from '../model/models';
import { ConnaissementNumeroVoyageAMettreAjour } from '../model/models';
import { CreateConnaissementDto } from '../model/models';
import { FacturerConnaissementOptions } from '../model/models';
import { GetConnaissementResponseDto } from '../model/models';
import { OptionTelechargementPlusieursPdfs } from '../model/models';
import { ParametresParDefautPourCreationConnaissementDto } from '../model/models';
import { PieceEditDto } from '../model/models';
import { ResultatTraitementConnaissement } from '../model/models';
import { StatutRevatua } from '../model/models';
import { TypeTelechargementPdfEnum } from '../model/models';
import { TypeUtilisateurRuunui } from '../model/models';
import { TypeVoyage } from '../model/models';
import { TypeVoyageEnum } from '../model/models';
import { UpdateConnaissementDto } from '../model/models';
import { UpdateConnaissementNombreColisDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ConnaissementsLexiClient {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param affecterConnaissementNumeroRevatuaDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public affecterNumeroRevatua(affecterConnaissementNumeroRevatuaDto: AffecterConnaissementNumeroRevatuaDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResultatTraitementConnaissement>;
    public affecterNumeroRevatua(affecterConnaissementNumeroRevatuaDto: AffecterConnaissementNumeroRevatuaDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResultatTraitementConnaissement>>;
    public affecterNumeroRevatua(affecterConnaissementNumeroRevatuaDto: AffecterConnaissementNumeroRevatuaDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResultatTraitementConnaissement>>;
    public affecterNumeroRevatua(affecterConnaissementNumeroRevatuaDto: AffecterConnaissementNumeroRevatuaDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (affecterConnaissementNumeroRevatuaDto === null || affecterConnaissementNumeroRevatuaDto === undefined) {
            throw new Error('Required parameter affecterConnaissementNumeroRevatuaDto was null or undefined when calling affecterNumeroRevatua.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ResultatTraitementConnaissement>(`${this.configuration.basePath}/api/Connaissements/affecter-numero-revatua`,
            affecterConnaissementNumeroRevatuaDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementUniqueId 
     * @param motifAnnulation 
     * @param utilisateurId 
     * @param societeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annulerConnaissement(connaissementUniqueId?: string, motifAnnulation?: string, utilisateurId?: number, societeId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public annulerConnaissement(connaissementUniqueId?: string, motifAnnulation?: string, utilisateurId?: number, societeId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public annulerConnaissement(connaissementUniqueId?: string, motifAnnulation?: string, utilisateurId?: number, societeId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public annulerConnaissement(connaissementUniqueId?: string, motifAnnulation?: string, utilisateurId?: number, societeId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementUniqueId !== undefined && connaissementUniqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connaissementUniqueId, 'connaissementUniqueId');
        }
        if (motifAnnulation !== undefined && motifAnnulation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>motifAnnulation, 'motifAnnulation');
        }
        if (utilisateurId !== undefined && utilisateurId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>utilisateurId, 'utilisateurId');
        }
        if (societeId !== undefined && societeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>societeId, 'societeId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.put(`${this.configuration.basePath}/api/Connaissements/annuler-connaissement`,
            null,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param createConnaissementDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(createConnaissementDto: CreateConnaissementDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreateConnaissementDto>;
    public create(createConnaissementDto: CreateConnaissementDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreateConnaissementDto>>;
    public create(createConnaissementDto: CreateConnaissementDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreateConnaissementDto>>;
    public create(createConnaissementDto: CreateConnaissementDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (createConnaissementDto === null || createConnaissementDto === undefined) {
            throw new Error('Required parameter createConnaissementDto was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreateConnaissementDto>(`${this.configuration.basePath}/api/Connaissements`,
            createConnaissementDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param facturerConnaissementOptions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public facturer(facturerConnaissementOptions: FacturerConnaissementOptions, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PieceEditDto>>;
    public facturer(facturerConnaissementOptions: FacturerConnaissementOptions, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PieceEditDto>>>;
    public facturer(facturerConnaissementOptions: FacturerConnaissementOptions, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PieceEditDto>>>;
    public facturer(facturerConnaissementOptions: FacturerConnaissementOptions, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (facturerConnaissementOptions === null || facturerConnaissementOptions === undefined) {
            throw new Error('Required parameter facturerConnaissementOptions was null or undefined when calling facturer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<PieceEditDto>>(`${this.configuration.basePath}/api/Connaissements/facturer`,
            facturerConnaissementOptions,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param typeVoyage 
     * @param numeroVoyage 
     * @param statutPlateformes 
     * @param partenaireId 
     * @param dateDebut 
     * @param dateFin 
     * @param codeConnaissement 
     * @param dansRevatua 
     * @param transporteurCodeOuLibelle 
     * @param userType 
     * @param facturable 
     * @param facture 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll(typeVoyage?: TypeVoyage, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, userType?: TypeUtilisateurRuunui, facturable?: boolean, facture?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ConnaissementLexiResultDto>>;
    public getAll(typeVoyage?: TypeVoyage, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, userType?: TypeUtilisateurRuunui, facturable?: boolean, facture?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ConnaissementLexiResultDto>>>;
    public getAll(typeVoyage?: TypeVoyage, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, userType?: TypeUtilisateurRuunui, facturable?: boolean, facture?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ConnaissementLexiResultDto>>>;
    public getAll(typeVoyage?: TypeVoyage, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, userType?: TypeUtilisateurRuunui, facturable?: boolean, facture?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (typeVoyage !== undefined && typeVoyage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>typeVoyage, 'TypeVoyage');
        }
        if (numeroVoyage !== undefined && numeroVoyage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>numeroVoyage, 'NumeroVoyage');
        }
        if (statutPlateformes) {
            statutPlateformes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'StatutPlateformes');
            })
        }
        if (partenaireId !== undefined && partenaireId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>partenaireId, 'PartenaireId');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'DateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'DateFin');
        }
        if (codeConnaissement !== undefined && codeConnaissement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codeConnaissement, 'CodeConnaissement');
        }
        if (dansRevatua !== undefined && dansRevatua !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dansRevatua, 'DansRevatua');
        }
        if (transporteurCodeOuLibelle !== undefined && transporteurCodeOuLibelle !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transporteurCodeOuLibelle, 'TransporteurCodeOuLibelle');
        }
        if (userType !== undefined && userType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userType, 'UserType');
        }
        if (facturable !== undefined && facturable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>facturable, 'Facturable');
        }
        if (facture !== undefined && facture !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>facture, 'Facture');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ConnaissementLexiResultDto>>(`${this.configuration.basePath}/api/Connaissements`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param typeVoyage Type de voyage (Alle/InterIle/Retour)
     * @param numeroVoyage Numéro du voyage
     * @param statutPlateformes Statut revatua  SAISIE : 0 OFFICIALISE : 1 EMBARQUE : 2 EMBARQUEMENT_REFUSE : 3 ANNULE : 4 MODIFICATION : 5 TRANSFERT : 6 BROUILLON : 7 DEMANDE : 8 DEMANDE_REFUSEE : 9 OFFICIALISE_SOUS_RESERVE : 10 PRIS_EN_CHARGE : 11
     * @param partenaireId Id du client destinataire
     * @param dateDebut Date de début Filtre et récupère les connaissement dont la date de départ est supérieure à la date de début
     * @param dateFin Date de fin Filtre et récupère les connaissement dont la date de départ est inférieure à la date de fin
     * @param codeConnaissement Numéro connaissement de GESTMAR ou CodeChargeur
     * @param dansRevatua Est présent dans Revatua
     * @param transporteurCodeOuLibelle Code bo ou intitulé du transporteur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll2(typeVoyage?: TypeVoyageEnum, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GetConnaissementResponseDto>>;
    public getAll2(typeVoyage?: TypeVoyageEnum, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GetConnaissementResponseDto>>>;
    public getAll2(typeVoyage?: TypeVoyageEnum, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GetConnaissementResponseDto>>>;
    public getAll2(typeVoyage?: TypeVoyageEnum, numeroVoyage?: string, statutPlateformes?: Array<number>, partenaireId?: number, dateDebut?: string, dateFin?: string, codeConnaissement?: string, dansRevatua?: boolean, transporteurCodeOuLibelle?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (typeVoyage !== undefined && typeVoyage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>typeVoyage, 'TypeVoyage');
        }
        if (numeroVoyage !== undefined && numeroVoyage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>numeroVoyage, 'NumeroVoyage');
        }
        if (statutPlateformes) {
            statutPlateformes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'StatutPlateformes');
            })
        }
        if (partenaireId !== undefined && partenaireId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>partenaireId, 'PartenaireId');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'DateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'DateFin');
        }
        if (codeConnaissement !== undefined && codeConnaissement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codeConnaissement, 'CodeConnaissement');
        }
        if (dansRevatua !== undefined && dansRevatua !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dansRevatua, 'DansRevatua');
        }
        if (transporteurCodeOuLibelle !== undefined && transporteurCodeOuLibelle !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transporteurCodeOuLibelle, 'TransporteurCodeOuLibelle');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<GetConnaissementResponseDto>>(`${this.configuration.basePath}/apipartenaire/connaissements`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBordereauLivraisonConnaissementPdf(connaissementIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getBordereauLivraisonConnaissementPdf(connaissementIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getBordereauLivraisonConnaissementPdf(connaissementIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getBordereauLivraisonConnaissementPdf(connaissementIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementIds) {
            connaissementIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'connaissementIds');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/Connaissements/bordereau-livraison-connaissement/pdf`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numeroRevatua 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNumeroRevatua(numeroRevatua: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ConnaissementLexiResultDto>;
    public getByNumeroRevatua(numeroRevatua: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ConnaissementLexiResultDto>>;
    public getByNumeroRevatua(numeroRevatua: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ConnaissementLexiResultDto>>;
    public getByNumeroRevatua(numeroRevatua: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numeroRevatua === null || numeroRevatua === undefined) {
            throw new Error('Required parameter numeroRevatua was null or undefined when calling getByNumeroRevatua.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ConnaissementLexiResultDto>(`${this.configuration.basePath}/api/Connaissements/${encodeURIComponent(String(numeroRevatua))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numeroRevatua 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNumeroRevatua2(numeroRevatua: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetConnaissementResponseDto>;
    public getByNumeroRevatua2(numeroRevatua: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetConnaissementResponseDto>>;
    public getByNumeroRevatua2(numeroRevatua: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetConnaissementResponseDto>>;
    public getByNumeroRevatua2(numeroRevatua: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numeroRevatua === null || numeroRevatua === undefined) {
            throw new Error('Required parameter numeroRevatua was null or undefined when calling getByNumeroRevatua2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GetConnaissementResponseDto>(`${this.configuration.basePath}/apipartenaire/connaissements/${encodeURIComponent(String(numeroRevatua))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numero 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNumeroRevatuaOrNumeroLexi(numero: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ConnaissementLexiResultDto>;
    public getByNumeroRevatuaOrNumeroLexi(numero: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ConnaissementLexiResultDto>>;
    public getByNumeroRevatuaOrNumeroLexi(numero: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ConnaissementLexiResultDto>>;
    public getByNumeroRevatuaOrNumeroLexi(numero: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numero === null || numero === undefined) {
            throw new Error('Required parameter numero was null or undefined when calling getByNumeroRevatuaOrNumeroLexi.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ConnaissementLexiResultDto>(`${this.configuration.basePath}/api/Connaissements/by-numero-revatua-or-lexi/${encodeURIComponent(String(numero))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numero 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNumeroRevatuaOrNumeroLexi2(numero: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetConnaissementResponseDto>;
    public getByNumeroRevatuaOrNumeroLexi2(numero: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetConnaissementResponseDto>>;
    public getByNumeroRevatuaOrNumeroLexi2(numero: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetConnaissementResponseDto>>;
    public getByNumeroRevatuaOrNumeroLexi2(numero: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numero === null || numero === undefined) {
            throw new Error('Required parameter numero was null or undefined when calling getByNumeroRevatuaOrNumeroLexi2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GetConnaissementResponseDto>(`${this.configuration.basePath}/apipartenaire/connaissements/by-numero-revatua-or-lexi/${encodeURIComponent(String(numero))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementIds 
     * @param type 
     * @param optionTelechargement 
     * @param nombreExemplaires 
     * @param forcerModeleRuunui 
     * @param inclureEtiquette 
     * @param inclureBordereauLivraison 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnaissementPdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getConnaissementPdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getConnaissementPdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getConnaissementPdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementIds) {
            connaissementIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ConnaissementIds');
            })
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'Type');
        }
        if (optionTelechargement !== undefined && optionTelechargement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>optionTelechargement, 'OptionTelechargement');
        }
        if (nombreExemplaires !== undefined && nombreExemplaires !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nombreExemplaires, 'NombreExemplaires');
        }
        if (forcerModeleRuunui !== undefined && forcerModeleRuunui !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forcerModeleRuunui, 'ForcerModeleRuunui');
        }
        if (inclureEtiquette !== undefined && inclureEtiquette !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inclureEtiquette, 'InclureEtiquette');
        }
        if (inclureBordereauLivraison !== undefined && inclureBordereauLivraison !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inclureBordereauLivraison, 'InclureBordereauLivraison');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/Connaissements/pdf`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementIds 
     * @param type 
     * @param optionTelechargement 
     * @param nombreExemplaires 
     * @param forcerModeleRuunui 
     * @param inclureEtiquette 
     * @param inclureBordereauLivraison 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEtiquettePdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getEtiquettePdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getEtiquettePdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getEtiquettePdfOrZipFile(connaissementIds?: Array<string>, type?: TypeTelechargementPdfEnum, optionTelechargement?: OptionTelechargementPlusieursPdfs, nombreExemplaires?: number, forcerModeleRuunui?: boolean, inclureEtiquette?: boolean, inclureBordereauLivraison?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementIds) {
            connaissementIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ConnaissementIds');
            })
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'Type');
        }
        if (optionTelechargement !== undefined && optionTelechargement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>optionTelechargement, 'OptionTelechargement');
        }
        if (nombreExemplaires !== undefined && nombreExemplaires !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nombreExemplaires, 'NombreExemplaires');
        }
        if (forcerModeleRuunui !== undefined && forcerModeleRuunui !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forcerModeleRuunui, 'ForcerModeleRuunui');
        }
        if (inclureEtiquette !== undefined && inclureEtiquette !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inclureEtiquette, 'InclureEtiquette');
        }
        if (inclureBordereauLivraison !== undefined && inclureBordereauLivraison !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inclureBordereauLivraison, 'InclureBordereauLivraison');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/Connaissements/etiquettes/pdf`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementUniqueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMontantFactureExistant(connaissementUniqueId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public getMontantFactureExistant(connaissementUniqueId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public getMontantFactureExistant(connaissementUniqueId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public getMontantFactureExistant(connaissementUniqueId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementUniqueId !== undefined && connaissementUniqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connaissementUniqueId, 'connaissementUniqueId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/Connaissements/montant-facture`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParametresParDefautPourCreation(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ParametresParDefautPourCreationConnaissementDto>;
    public getParametresParDefautPourCreation(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ParametresParDefautPourCreationConnaissementDto>>;
    public getParametresParDefautPourCreation(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ParametresParDefautPourCreationConnaissementDto>>;
    public getParametresParDefautPourCreation(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ParametresParDefautPourCreationConnaissementDto>(`${this.configuration.basePath}/api/Connaissements/params-creation-connaissement`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementUniqueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuantiteFacturee(connaissementUniqueId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public getQuantiteFacturee(connaissementUniqueId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public getQuantiteFacturee(connaissementUniqueId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public getQuantiteFacturee(connaissementUniqueId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connaissementUniqueId !== undefined && connaissementUniqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connaissementUniqueId, 'connaissementUniqueId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/Connaissements/quantite-facture`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementLexiResultDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBackOfficeConnaissements(connaissementLexiResultDto: Array<ConnaissementLexiResultDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ResultatTraitementConnaissement>>;
    public updateBackOfficeConnaissements(connaissementLexiResultDto: Array<ConnaissementLexiResultDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ResultatTraitementConnaissement>>>;
    public updateBackOfficeConnaissements(connaissementLexiResultDto: Array<ConnaissementLexiResultDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ResultatTraitementConnaissement>>>;
    public updateBackOfficeConnaissements(connaissementLexiResultDto: Array<ConnaissementLexiResultDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (connaissementLexiResultDto === null || connaissementLexiResultDto === undefined) {
            throw new Error('Required parameter connaissementLexiResultDto was null or undefined when calling updateBackOfficeConnaissements.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Array<ResultatTraitementConnaissement>>(`${this.configuration.basePath}/api/Connaissements/backoffice`,
            connaissementLexiResultDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateConnaissementDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnaissement(updateConnaissementDto: UpdateConnaissementDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateConnaissement(updateConnaissementDto: UpdateConnaissementDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateConnaissement(updateConnaissementDto: UpdateConnaissementDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateConnaissement(updateConnaissementDto: UpdateConnaissementDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (updateConnaissementDto === null || updateConnaissementDto === undefined) {
            throw new Error('Required parameter updateConnaissementDto was null or undefined when calling updateConnaissement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put(`${this.configuration.basePath}/api/Connaissements`,
            updateConnaissementDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numeroRevatua 
     * @param updateConnaissementNombreColisDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNombreDeColis(numeroRevatua: string, updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public updateNombreDeColis(numeroRevatua: string, updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public updateNombreDeColis(numeroRevatua: string, updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public updateNombreDeColis(numeroRevatua: string, updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numeroRevatua === null || numeroRevatua === undefined) {
            throw new Error('Required parameter numeroRevatua was null or undefined when calling updateNombreDeColis.');
        }
        if (updateConnaissementNombreColisDto === null || updateConnaissementNombreColisDto === undefined) {
            throw new Error('Required parameter updateConnaissementNombreColisDto was null or undefined when calling updateNombreDeColis.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<string>(`${this.configuration.basePath}/api/Connaissements/${encodeURIComponent(String(numeroRevatua))}/nombre-de-colis`,
            updateConnaissementNombreColisDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateConnaissementNombreColisDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNombreDeColisHorsRevatua(updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateNombreDeColisHorsRevatua(updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateNombreDeColisHorsRevatua(updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateNombreDeColisHorsRevatua(updateConnaissementNombreColisDto: UpdateConnaissementNombreColisDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (updateConnaissementNombreColisDto === null || updateConnaissementNombreColisDto === undefined) {
            throw new Error('Required parameter updateConnaissementNombreColisDto was null or undefined when calling updateNombreDeColisHorsRevatua.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch(`${this.configuration.basePath}/api/Connaissements/nombre-de-colis-hors-revatua`,
            updateConnaissementNombreColisDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connaissementNumeroVoyageAMettreAjour 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNumeroVoyage(connaissementNumeroVoyageAMettreAjour: Array<ConnaissementNumeroVoyageAMettreAjour>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateNumeroVoyage(connaissementNumeroVoyageAMettreAjour: Array<ConnaissementNumeroVoyageAMettreAjour>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateNumeroVoyage(connaissementNumeroVoyageAMettreAjour: Array<ConnaissementNumeroVoyageAMettreAjour>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateNumeroVoyage(connaissementNumeroVoyageAMettreAjour: Array<ConnaissementNumeroVoyageAMettreAjour>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (connaissementNumeroVoyageAMettreAjour === null || connaissementNumeroVoyageAMettreAjour === undefined) {
            throw new Error('Required parameter connaissementNumeroVoyageAMettreAjour was null or undefined when calling updateNumeroVoyage.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch(`${this.configuration.basePath}/api/Connaissements`,
            connaissementNumeroVoyageAMettreAjour,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numeroRevatua 
     * @param statut 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatut(numeroRevatua: string, statut: StatutRevatua, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public updateStatut(numeroRevatua: string, statut: StatutRevatua, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public updateStatut(numeroRevatua: string, statut: StatutRevatua, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public updateStatut(numeroRevatua: string, statut: StatutRevatua, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numeroRevatua === null || numeroRevatua === undefined) {
            throw new Error('Required parameter numeroRevatua was null or undefined when calling updateStatut.');
        }
        if (statut === null || statut === undefined) {
            throw new Error('Required parameter statut was null or undefined when calling updateStatut.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<string>(`${this.configuration.basePath}/api/Connaissements/${encodeURIComponent(String(numeroRevatua))}/${encodeURIComponent(String(statut))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param numeroRevatua 
     * @param statut 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatut2(numeroRevatua: string, statut: StatutRevatua, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public updateStatut2(numeroRevatua: string, statut: StatutRevatua, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public updateStatut2(numeroRevatua: string, statut: StatutRevatua, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public updateStatut2(numeroRevatua: string, statut: StatutRevatua, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (numeroRevatua === null || numeroRevatua === undefined) {
            throw new Error('Required parameter numeroRevatua was null or undefined when calling updateStatut2.');
        }
        if (statut === null || statut === undefined) {
            throw new Error('Required parameter statut was null or undefined when calling updateStatut2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<string>(`${this.configuration.basePath}/apipartenaire/connaissements/${encodeURIComponent(String(numeroRevatua))}/${encodeURIComponent(String(statut))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
