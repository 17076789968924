<ng-container *ngIf="showBandeauAlerteLicence">
  <div id="bandeauAlerteLicence">
    <div>
      <span *ngIf="licenceARenouveler && !licenceEnPeriodeDeGrace">
        Votre licence expire le {{ licenceARenouveler.dateFin | date:'dd/MM/yyyy' }}.
        Veuillez-vous rapprocher d'IDT si ce n'est pas déjà fait.
      </span>
      <span *ngIf="licenceEnPeriodeDeGrace">
        Votre licence a expiré le {{ licenceEnPeriodeDeGrace.dateFin | date:'dd/MM/yyyy' }}.
        Vous possédez 30 jours de grâce avant le retrait définitif de votre licence.
      </span>
    </div>
    <dx-button id="closeBandeauBtn" icon="close" (click)="showBandeauAlerteLicence = false"></dx-button>
  </div>
</ng-container>
<header >
  <dx-toolbar >

    <!-- Menu hamburger -->
    <dxi-item *ngIf="menuToggleEnabled" location="before" widget="dxButton"  [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"></dxi-item>

    <!-- Titre société -->
    <dxi-item location="before" cssClass="header-title" [text]="currentSociete?.intitule"></dxi-item>

    <!-- Les différents modules -->
    <dxi-item *ngFor="let option of options" location="before" widget="dxButton" [options]="option"></dxi-item>

    <!-- SelectBoxes-->
    <dxi-item location="after" locateInMenu="auto">
      <div *dxTemplate style="display: flex; align-items: center;" >
        <!-- Choix du site -->
        <div style="display: flex; align-items: center;" >
          <span style="margin-right: 6px;">{{ 'site' | internationalisation: 'Site' }} : </span>
          <dx-select-box #targetSiteSelectBox [width]="220" [dataSource]="sites" [value]="currentSite?.id"
            valueExpr="id" displayExpr="intitule" (onValueChanged)="changerSite($event)" [searchEnabled]="true"
            [searchExpr]="['intitule']"
          ></dx-select-box>
        </div>

        <!-- Partenaire lié -->
        <!-- <div style="margin: auto 12px;">
          <span>Partenaire lié : </span>
          <a [routerLink]="'/partenaire/' + currentSitePartenaire?.id">{{currentSitePartenaire?.intitule}}</a>
        </div> -->
      </div>
    </dxi-item>

    <!-- Menu Support -->
    <dxi-item location="after" locateInMenu="auto">
      <div *dxTemplate>
        <dx-button icon="help" (onClick)='openSupport()'>
          <i class="dx-icon-help" style="color: rgb(0, 107, 230); font-size: 18px;"></i>
        </dx-button>
      </div>
    </dxi-item>

    <!-- Menu liste déroulante sous le profil -->
    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button class="user-button authorization" height="100%" stylingMode="text" >
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>

    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>

    <dxi-item location="after" locateInMenu="auto" cssClass="error-warning" template="error"
      *ngIf="erreurConnectionBackOffice || nombreErreursInQueue > 0">
      <div *dxTemplate="let data of 'error'">
        <dx-button icon="dx-icon-warning" (onClick)='togglePopup()'>
          <i class="dx-icon-warning" style="color: rgb(230, 0, 0); font-size: 18px;"></i>
        </dx-button>
      </div>
    </dxi-item>

    <dxi-item location="after" locateInMenu="auto" cssClass="error-warning" template="contactsEnAttentes"
      *ngIf="contactsEnAttentes.length > 0">
      <div *dxTemplate="let data of 'contactsEnAttentes'">
        <dx-button icon="dx-icon-warning" (onClick)='togglePopupContactsEnAttentes()'>
          <i class="dx-icon-warning" style="color: rgb(251, 178, 7); font-size: 18px;"></i>
        </dx-button>
      </div>
    </dxi-item>

  </dx-toolbar>
</header>

<!-- Popup changement de société -->
<dx-popup
  [width]="450"
  [height]="200"
  [showTitle]="true"
  title="Changer de société"
  [dragEnabled]="false"
  [showCloseButton]="true"
  [(visible)]="switchSocietePopupVisible"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <dx-select-box #societeSelectBox [width]="300" [dataSource]="societes" [value]="currentSociete?.id" valueExpr="id"
      displayExpr="intitule" style="display: inline-block; margin-right: 10px"></dx-select-box>

    <dx-button text="Valider" (onClick)="changerSociete()"></dx-button>
  </div>
</dx-popup>


<!-- Popup warning erreurs -->
<dx-popup [width]="300" [height]="'auto'" [(visible)]="showErreurPopup" [showTitle]="true" [dragEnabled]="false"
  [showCloseButton]="true" title="Alerte" container=".dx-viewport">
  <div *dxTemplate="let data of 'content'">

    <!-- Erreur s'il y a des fichiers en erreur dans la queue -->
    <p *ngIf="nombreErreursInQueue==1" style="text-align:center">- Il y a 1 message dans la file d'attente en erreur<br>
    </p>
    <p *ngIf="nombreErreursInQueue>1" style="text-align:center">- Il y a {{nombreErreursInQueue}} messages dans
      la file d'attente en erreur<br></p>

    <!-- Erreur s'il la connection entre le client IDS et le serveur Lexi est rompue -->
    <p *ngIf="erreurConnectionBackOffice" style="text-align:center">- La connection au Client IDS a échoué</p>

  </div>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="{
        text: 'Détails',
        onClick: redirectionVersMessage
    }">
  </dxi-toolbar-item>
</dx-popup>


<!-- Popup contacts en attentes -->
<dx-popup [width]="800" [height]="'auto'" [(visible)]="showPopupContactsEnAttentes" [showTitle]="true"
  [dragEnabled]="false" [showCloseButton]="true" title="Liste des demandes d'ajout de contacts"
  container=".dx-viewport">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="gridContainer" [dataSource]="contactsEnAttentes" [allowColumnReordering]="false"
      [columnAutoWidth]="true" [allowColumnResizing]="false" [columnHidingEnabled]="false">

      <dxi-column dataField="partenaireIntitule" caption="Partenaire"></dxi-column>

      <dxi-column dataField="utilisateurIntitule" caption="Utilisateur"></dxi-column>

      <dxi-column cellTemplate="validerTemplate"></dxi-column>

      <dxi-column cellTemplate="deleteTemplate"></dxi-column>

      <div *dxTemplate="let cell of 'validerTemplate'" (click)="onValidate(cell)">
        <dx-button icon="check" hint="Valider"></dx-button>
      </div>

      <div *dxTemplate="let cell of 'deleteTemplate'" (click)="onDelete(cell)">
        <dx-button icon="close" hint="Supprimer"></dx-button>
      </div>

    </dx-data-grid>

  </div>
</dx-popup>