import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { QUERY_KEY_RETURN_URL } from './constants';
import { OidcUaaConfig } from './oidc-uaa.config';
import { UaaService } from './uaa.service';

@Injectable({ providedIn: 'root' })
export class IsAuthenticatedGuard  {
  constructor(private uaa: UaaService, private navCtrl: Router, private config: OidcUaaConfig) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.uaa.currentUser?.isAuthenticated()) {
      console.warn(`User is not authenticated. Access denied to ${state.url}`);

      let unauthorizedRoute: string = this.config?.openIDConfiguration?.unauthorizedRoute;
      if (!unauthorizedRoute) {
        console.warn("config.unauthorizedRoute est undefined, on redirige vers la route par défaut '/'");
        unauthorizedRoute = '/';
      }
      this.navCtrl.navigate([unauthorizedRoute],{ queryParams: { [QUERY_KEY_RETURN_URL]: state.url }});
    }
    return this.uaa.currentUser?.isAuthenticated();
  }
}
