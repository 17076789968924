<div style="display: flex; padding: 1rem">
  <!-- Partenaire -->
  <dx-select-box
    [dataSource]="partenaireDataSource"
    [displayExpr]="partenaireDisplayExpr"
    searchEnabled="true"
    [searchExpr]="['intitule', 'codeBo']"
    [width]="250"
    [showClearButton]="true"
    placeholder="Sélectionner un partenaire..."
    [(value)]="partenaireId"
    valueExpr="id"
    (onValueChanged)="partenaireChanged($event)"
  ></dx-select-box>
  <!-- Lieu Stockage -->
  <dx-select-box
    [dataSource]="lieuStockageDataSource"
    [displayExpr]="partenaireDisplayExpr"
    searchEnabled="true"
    [searchExpr]="['intitule', 'codeBo']"
    [showClearButton]="true"
    [width]="250"
    placeholder="Sélectionner un lieu..."
    [(value)]="lieuId"
    valueExpr="id"
    (onValueChanged)="lieuChanged($event)"
    style="margin-left: 1rem"
  ></dx-select-box>
  <!-- Article -->
  <dx-select-box
    [dataSource]="articleDataSource"
    [displayExpr]="articleDisplayExpr"
    searchEnabled="true"
    [searchExpr]="['libelleLong', 'codeBo']"
    [showClearButton]="true"
    [width]="250"
    placeholder="Sélectionner un article..."
    (onValueChanged)="articleChanged($event)"
    style="margin-left: 1rem"
    [disabled]="!canSelectionnerArticle"
    [(value)]="articleId"
    valueExpr="id"
  ></dx-select-box>
  <!-- Mode d'affichage -->
  <dx-select-box
    [dataSource]="modesAffichageDataSource"
    displayExpr="libelle"
    searchEnabled="true"
    searchExpr="id"
    [showClearButton]="true"
    [width]="250"
    placeholder="Sélectionner un mode d'affichage..."
    (onValueChanged)="changementModeAffichage()"
    style="margin-left: 1rem; margin-right: 1rem;"
    [(value)]="modeAffichage"
    valueExpr="id"
  ></dx-select-box>
  <dx-button text="Rechercher" (onClick)="reloadDataSource()"></dx-button>
</div>
<div [style]="'height: '+height">
  <dx-data-grid #gridContainer
    [dataSource]="dataSource"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [showBorders]="true"
    [masterDetail]="{ enabled: true, template: 'details' }"
    [filterSyncEnabled]="true"
    height="100%"
    (onExporting)="onExporting($event, 'registre_numero_serie')"
  >
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-scrolling showScrollbar="always"></dxo-scrolling>
    <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true" [grouping]="true"></dxo-remote-operations>
    <dxo-search-panel [visible]="true"></dxo-search-panel>

    <!-- Article -->
    <dxi-column caption="Article" [calculateCellValue]="calculateArticleCellValue"></dxi-column>
    <!-- N° Série -->
    <dxi-column dataField="numeroSerie" caption="No Série" cssClass="cell-bold"></dxi-column>

    <!-- Partenaire -->
    <dxi-column dataField="partenaireCodeBo" caption="Code" width="120" [visible]="false"></dxi-column>
    <dxi-column dataField="partenaireIntitule" caption="Partenaire" [visible]="partenaireId == null"></dxi-column>

    <!-- Lieu -->
    <dxi-column dataField="lieuCodeBo" caption="Code" width="120" [visible]="false"></dxi-column>
    <dxi-column dataField="lieuIntitule" caption="Lieu" [visible]="lieuId == null"></dxi-column>

    <dxi-column dataField="nombreNumeroSerie" caption="Nombre de numéro de série" width="120" [visible]="false"></dxi-column>
    <dxi-column dataField="dateEntree" caption="Date entrée" dataType="date" format="dd/MM/yyyy HH:mm" [width]="150"></dxi-column>
    <dxi-column dataField="datePremiereEntree" caption="Date 1ère entrée" dataType="date" format="dd/MM/yyyy HH:mm" [width]="150" [visible]="canShowDatePremiereEntree"></dxi-column>
    <dxi-column dataField="dateSortie" caption="Date sortie" dataType="date" format="dd/MM/yyyy HH:mm" [width]="150" [visible]="canShowDateSortie" [filterValues]="dateSortieFilterValues"></dxi-column>

    <!-- Master detail : Mouvements -->
    <div *dxTemplate="let detail of 'details'">
      <dx-data-grid
        [dataSource]="detail.data.mouvements"
        [showBorders]="true"
        [allowColumnResizing]="true"
      >
        <!-- Colonnes -->
        <dxi-column dataField="date" caption="Date" dataType="date" format="dd/MM/yyyy HH:mm" [width]="137"></dxi-column>
        <dxi-column dataField="documentReference" caption="Document référence"></dxi-column>
        <dxi-column dataField="documentType" caption="Type doc." [width]="85"></dxi-column>
        <dxi-column dataField="bonId" caption="Bon" [width]="85" cellTemplate="idBonCellTemplate">
          <div *dxTemplate="let data of 'idBonCellTemplate'">
            <a routerLink="/bon/{{data.value}}">
              {{data.value}}
            </a>
          </div>
        </dxi-column>
        <dxi-column dataField="caisseSessionInfos" caption="Session caisse"></dxi-column>
      </dx-data-grid>
    </div>
  </dx-data-grid>
</div>