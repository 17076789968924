<div class="user-panel">

  <div class="user-info">
    <i class="dx-icon-card"></i>
    <div class="user-name">{{user.username}} {{authService?.currentAsUser != null ? ' (' + authService?.currentAsUser?.username + ')' : ''}}</div>
  </div>

  <!-- Menu grand écran -->
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    target=".user-button"
    showEvent="dxclick"
    [position]="{ my: 'top center', at: 'bottom center' }"
    cssClass="user-menu">
  </dx-context-menu>

  <!-- Menu 3 petits points sur petit écran -->
  <dx-menu
    *ngIf="menuMode === 'list'"
    class="dx-toolbar-menu-action"
    [dataSource]="menuItems"
    orientation="vertical"
    [hideSubmenuOnMouseLeave]="false"
    [showFirstSubmenuMode]="{
      name: 'onHover',
      delay: { show: 0, hide: 500 }
    }"
  ></dx-menu>

</div>
