import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PartenaireType, ArticleForUpdateDto, PartenaireDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { LieuStockageListService } from 'lexi-angular/src/app/services/lieu-stockage.service';
import { RegistreNumeroSerieService } from 'lexi-angular/src/app/services/registre-numero-serie.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registre-numero-serie-list',
  templateUrl: './registre-numero-serie-list.component.html',
  styleUrls: ['./registre-numero-serie-list.component.scss'],
})
export class RegistreNumeroSerieListComponent implements OnInit, OnDestroy {
  @ViewChild('gridContainer') dataGrid: DxDataGridComponent;

  @Input() articleId: number;
  @Input() canSelectionnerArticle: boolean = true;
  @Input() height: string = 'calc(100vh - 200px)'

  dataSource: CustomStore;
  articleDataSource: DataSource;
  partenaireDataSource: DataSource;
  lieuStockageDataSource: DataSource;
  partenaireId: number;
  lieuId: number;
  subscriptions = new Subscription();
  modeAffichage: 'registrePresent' | 'historique' = 'registrePresent';
  dateSortieFilterValues = [null];

  modesAffichageDataSource = [
    {id: 'registrePresent', libelle: 'Numéros actifs'},
    {id: 'historique', libelle: 'Historique'}
  ]

  get canShowDatePremiereEntree() {
    return this.modeAffichage == 'registrePresent';
  }
  
  get canShowDateSortie() {
    return this.modeAffichage == 'historique';
  }

  constructor(
    private readonly dxDatasourceService: DxDataSourceService,
    private readonly articleListService: ArticleListService,
    private readonly lieuStockageListService: LieuStockageListService,
    private readonly registresNumeroSerieService: RegistreNumeroSerieService,
    private readonly authService: AuthService,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  ngOnInit() {
    const sub = this.authService.currentSiteId$.subscribe(siteId => {
      if (!siteId) return;
      
      // On permet de choisir uniquement les articles sérialisables
      const articleParams = new Map<string, string>();
      articleParams.set('avecNumeroSerie', 'true');
      this.articleDataSource = this.dxDatasourceService.getDataSourceForSelectBox(this.articleListService, articleParams);
      
      this.partenaireDataSource = this.dxDatasourceService.getPartenaireDataSourceForSelectBox(false, false, PartenaireType.interne);
      this.partenaireId = this.authService.currentSite.partenaireId;
      
      this.reloadDataSource();
      this.reloadLieuStockageDataSource();
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  articleDisplayExpr(article: ArticleForUpdateDto) {
    return article && `${article.codeBo} - ${article.libelleLong}`;
  }

  partenaireDisplayExpr(partenaire: PartenaireDto) {
    return partenaire && `${partenaire.codeBo} - ${partenaire.intitule}`;
  }

  articleChanged(e) {
    this.articleId = e.value;
    this.reloadDataSource();
  }

  partenaireChanged(e) {
    this.lieuId = null;
    this.reloadLieuStockageDataSource();
    this.reloadDataSource();
  }

  lieuChanged(e) {
    this.reloadDataSource();
  }

  reloadDataSource() {
    const params = new Map<string, string>();
    if (this.articleId) {
      params.set('articleId', this.articleId.toString());
    }
    if (this.partenaireId) {
      params.set('partenaireId', this.partenaireId.toString());
    }
    if (this.lieuId) {
      params.set('lieuId', this.lieuId.toString());
    }
    
    this.dataSource = this.dxDatasourceService.getCustomStore(this.registresNumeroSerieService, params);
  }

  changementModeAffichage() {
    // [null] pour ajouter un filtre "aucune valeur"
    // null pour enlever le filtre actif
    this.dateSortieFilterValues = this.modeAffichage == 'registrePresent' ? [null] : null;

    this.dataGrid.instance.clearFilter();
    this.modeAffichage == 'registrePresent'
      ? this.dataGrid?.instance.filter(['dateSortie', '=', null])
      : this.dataGrid?.instance.filter(null);
    this.dataGrid?.instance.refresh();
  }

  calculateArticleCellValue(rowData) {
    return `${rowData.articleCodeBo} - ${rowData.articleIntitule}`;
  }

  private reloadLieuStockageDataSource() {
    const params = new Map<string, string>();
    if (this.partenaireId) {
      params.set('partenaireId', this.partenaireId.toString());
    }
    this.lieuStockageDataSource = this.dxDatasourceService.getDataSourceForSelectBox(this.lieuStockageListService, params);
  }
  
  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
