import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { LexiUser } from '@lexi/oidc-uaa';
import { AuthService, STORE_KEY_RETURN_URL } from '../../../settings/auth.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { DxSelectBoxTypes } from "devextreme-angular/ui/select-box"
import { navigationCollaborateur, navigationPartenaire, NavigationTop, Parametrage } from '../../../models/navigation-top';
import { ModuleService } from '../../services/module.service';
import { Router } from '@angular/router';
import { Societe } from 'lexi-angular/src/app/domain/societe';
import {
  MessagingLexiClient, SecurityLexiClient, SyncDataLexiClient,
  TypeUtilisateur, UtilisateurRelationLexiClient, PartenairesLexiClient, PartenaireDto,
  LicenceLexiClient, Permissions, UtilisateursLexiClient
} from '@lexi-clients/lexi';
import { NotificationType } from 'lexi-angular/src/app/modules/shared/references/references';
import notify from 'devextreme/ui/notify';
import { LexiStorage } from 'lexi-angular/src/app/settings/lexi-storage';
import { SocieteService } from 'lexi-angular/src/app/services/societe.service';
import { concatMap, filter } from 'rxjs';
import { AppInitService } from 'lexi-angular/src/app/app-init.service';
import { Site } from 'lexi-angular/src/app/domain/site';
import { DxDataSourceService } from '../../services/dx-data-source.service';

@Component({
  template: 'no-need-ui--but-we-specified-fake-one-because-of-compiler'
})
export class HeaderBaseComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();
  @ViewChild('societeSelectBox', { static: false }) societeIdSelectBox: DxSelectBoxComponent;

  options: any[];
  _currentModuleId: number = 0;
  get currentModuleId() {
    return this._currentModuleId;
  }

  //Cet array ne doit pas être réinitialisé car
  //celui-ci doit garder une adresse fixe dans la mémoire
  memoireSocietes: Societe[] = [];

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;

  get user(): LexiUser {
    return this.authService.authenticatedUser;
  }

  get societes(): Societe[] {
    //Reset l'array memoireSocietes sans le réinitialisé
    this.memoireSocietes.splice(0);
    //Push les sociétés filtrées dans l'array en mémoire
    this.authService.societes.filter(societe => societe.enabled == true).forEach(societe => {
      this.memoireSocietes.push(societe);
    });
    return this.memoireSocietes;
  }

  currentSociete: Societe;
  currentClient: PartenaireDto;

  navPrincipal: Array<NavigationTop> = [];
  currentSite: Site;
  currentSitePartenaire: PartenaireDto;

  constructor(
    protected readonly router: Router,
    protected readonly authService: AuthService,
    protected readonly moduleService: ModuleService,
    protected readonly partenairesLexiClient: PartenairesLexiClient,
    protected readonly messagingClient: MessagingLexiClient,
    protected readonly syncDataService: SyncDataLexiClient,
    protected readonly userRelationService: UtilisateurRelationLexiClient,
    protected readonly licencesClient: LicenceLexiClient,
    protected readonly securityHttpClient: SecurityLexiClient,
    private readonly storage: LexiStorage,
    private readonly customSocieteService: SocieteService,
    private readonly dxDataSourceService: DxDataSourceService,
    protected readonly utilisateursLexiClient: UtilisateursLexiClient,
    protected appInitService: AppInitService
  ) {
    this.authService.currentSiteId$.pipe(
      filter((siteId: number) => siteId != null)
    ).subscribe((siteId: number) => {
      this.currentSite = this.authService.currentSite;
      this.dxDataSourceService.getPartenaireById(this.authService.currentSite.partenaireId)
        .then(x => this.currentSitePartenaire = x)
      ;
    })

    this.moduleService.currentModule.asObservable().subscribe(u =>  this._currentModuleId = u);

    this.authService.currentSocieteId$.pipe(
      filter((societeId: number) => societeId != null)
    ).subscribe((societeId: number) => {
      this.currentSociete = this.authService.currentSociete;
    })

    this.authService.currentUser$.subscribe(async u => {
      const userIsCollaborateur: boolean = this.authService.securityUser?.typeUtilisateur == TypeUtilisateur.collaborateur.toLowerCase();

      // Si l'utilisateur est un partenaire,
      // on le renvoie sur le portail client
      if(!userIsCollaborateur) {
        this.navigate(0);
        return;
      }
      // Set navPrincipal
      await this.setNavPrincipal(userIsCollaborateur);
      this.options = this.navPrincipal.map((item) => ({ text: item.title, visible: item.visible, onClick: (r) => this.navigate(item.id) }));

      // Si l'utilisateur est un collaborateur
      // on détermine la route
      const storageTarget  = this.storage.getItem(STORE_KEY_RETURN_URL);
      const firstNavEnabled = this.navPrincipal.find(d => d.visible);

      const targetNav: NavigationTop = (!storageTarget || storageTarget == '/')
        ? firstNavEnabled
        : this.getNavigationMenuByStoredUrl()
      ;

      if (targetNav || firstNavEnabled) {
        // Si on ne trouve pas le targetNav,
        // on se base sur le premier menu actif
        this.navigate(targetNav?.id || firstNavEnabled.id);
      }

    });
  }

  private async setNavPrincipal(userIsCollaborateur: boolean) {
    if(userIsCollaborateur) {
      const parametrage: Parametrage = {
        currentUserType: await this.authService.getStoredUserTypeRuunui(),
        currentUserCanGererTransporteurs: this.authService.securityUserisGrantedWith(Permissions.canGererTransporteurs),
        currentSocieteManageStock: this.authService.currentSociete != null && this.authService.currentSociete.gestionStock
      };
      const navCollaborateur: NavigationTop[] = this.moduleService.enableModulesActifs(navigationCollaborateur(parametrage));
      this.navPrincipal = navCollaborateur;
    }

    else {
      let societe = this.customSocieteService.getSocietePortailClient();
      if(!societe) {
        await this.customSocieteService.setSocietePortailClient();
        societe = this.customSocieteService.getSocietePortailClient();
      }
      this.navPrincipal = navigationPartenaire(societe);
    }
  }

  getNavigationMenuByStoredUrl(): NavigationTop {
    const storedUrl = this.storage.getItem(STORE_KEY_RETURN_URL);
    const moduleName = storedUrl.split('/')[1];
    return this.navPrincipal.find(f => f.title.toLowerCase() == moduleName.toLowerCase());
  }

  changerModule(event: DxSelectBoxTypes.ValueChangedEvent) {
    this.navigate(event.value);
  }

  navigate(moduleId: number) {
    this.moduleService.selectModule = moduleId;
  }

  ngOnInit() {
    this.authService.refreshAvailableSocietesFromServer(this.authService.securityUser?.id);
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}
