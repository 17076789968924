import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BonACreerDto,
  BonACreerFromReliquatDto,
  BonDto,
  BonLigneACreerDto,
  BonsLexiClient,
  ComptageStatut,
  CopieImageStockType,
  DisponibiliteMarchandiseDto,
  DocumentLogistiqueEnteteDto,
  DocumentLogistiquesLexiClient,
  EcritureDto,
  EditionsLexiClient,
  EtapeAction,
  FluxEtapeDto,
  FluxLexiClient,
  FluxStatut,
  FluxUpdateDto,
  GetConditionnementDto,
  GetStockResult,
  IntervenantType,
  LexiMouvementTypeDto,
  LexiMouvementTypesLexiClient,
  LieuStockageDto,
  LieuStockagesLexiClient,
  LieuStockageType,
  LignePourReliquatDto,
  ListEditionDto,
  ModeReservation,
  MouvementSens,
  MouvementTypeGeneration,
  ObjectType,
  OptionNombreEtiquette,
  PartenaireDto,
  Permissions,
  ReserverQuantitesResultEnum,
  StrategieComptage,
  UniteDto,
  UnitesLexiClient,
  UpdateBonDto,
  UpdateBonLigneDto,
  UpdateBonLigneQuantitesDto,
  UpdateBonQuantitesAvecReliquatDto,
  UpdateBonQuantitesDto
} from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import {AuthService} from 'lexi-angular/src/app/settings/auth.service';
import {filter, lastValueFrom, Subscription} from 'rxjs';
import {NotificationType} from '../../references/references';
import {BonDetailLieuStockageComponent} from './bon-detail-lieu-stockage/bon-detail-lieu-stockage.component';
import {BonDetailLignesComponent} from './bon-detail-lignes/bon-detail-lignes.component';
import {confirm} from 'devextreme/ui/dialog';
import {FluxEtapePossibleListComponent} from '../../flux-etape-possible-list/flux-etape-possible-list.component';
import {LexiUser} from '@lexi/oidc-uaa';
import {DownloadService} from 'lexi-angular/src/app/services/download.service';
import DataSource from 'devextreme/data/data_source';
import {DxDataSourceService} from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import {FluxHistoriqueListComponent} from '../../flux-historique-list/flux-historique-list.component';
import {lieuStockageReguleCodeBo} from 'lexi-angular/src/app/models/lieu-stockage-list';
import {ReferenceService} from '../../references/references.service';
import {ObjectUtilitiesService} from 'lexi-angular/src/app/shared/services/object-utilities.service';
import {
  ConditionnementData,
  ConditionnementService,
  ConditionnementType
} from 'lexi-angular/src/app/services/conditionnement.service';

const statutsComptagesEnCours = [ComptageStatut.enCours, ComptageStatut.transmis];

@Component({
  selector: 'app-bon-detail',
  templateUrl: './bon-detail.component.html',
  styleUrls: ['./bon-detail.component.scss'],
})
export class BonDetailComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  // Constantes
  readonly FluxStatut = FluxStatut;
  readonly fluxStatutDataSource = [
    { id: FluxStatut.new, intitule: "Brouillon" },
    { id: FluxStatut.paused, intitule: "En attente" },
    { id: FluxStatut.opened, intitule: "En cours" },
    { id: FluxStatut.closed, intitule: "Finalisé" },
  ];
  readonly listePossibleActionImageStock: any[] = [
    {id: CopieImageStockType.reel, intitule: 'Stock physique', description: 'Quantité présente physiquement dans le stock'},
    {id: CopieImageStockType.disponible, intitule: 'Stock disponible', description: 'Quantité disponible, donc non réservée'},
  ];

  // Références composants
  private _bonDetailLignesRef: BonDetailLignesComponent;
  get bonDetailLignesRef() { return this._bonDetailLignesRef }
  @ViewChild('bonDetailLignesRef') set bonDetailLignesRef(value: BonDetailLignesComponent) {
    this._bonDetailLignesRef = value;
    this.cd.detectChanges();
  }
  private _bonDetailLieuStockageRef: BonDetailLieuStockageComponent;
  get bonDetailLieuStockageRef() { return this._bonDetailLieuStockageRef }
  @ViewChild('bonDetailLieuStockageRef') set bonDetailLieuStockageRef(value: BonDetailLieuStockageComponent) {
    this._bonDetailLieuStockageRef = value;
    this.cd.detectChanges();
  }
  private _fluxEtapeListRef: FluxEtapePossibleListComponent;
  get fluxEtapeListRef() { return this._fluxEtapeListRef }
  @ViewChild(FluxEtapePossibleListComponent) set fluxEtapeListRef(value: FluxEtapePossibleListComponent) {
    this._fluxEtapeListRef = value;
    this.cd.detectChanges();
  }
  private _fluxHistoriqueListComponent: FluxHistoriqueListComponent;
  get fluxHistoriqueListComponent() { return this._fluxHistoriqueListComponent }
  @ViewChild(FluxHistoriqueListComponent) set fluxHistoriqueListComponent(value: FluxHistoriqueListComponent) {
    this._fluxHistoriqueListComponent = value;
    this.cd.detectChanges();
  }
  private _bonDetailLignesComponent: BonDetailLignesComponent;
  get bonDetailLignesComponent() { return this._bonDetailLignesComponent }
  @ViewChild(BonDetailLignesComponent) set bonDetailLignesComponent(value: BonDetailLignesComponent) {
    this._bonDetailLignesComponent = value;
    this.cd.detectChanges();
  }

  private _toggle: boolean;
  public get toggle(): boolean {
    return this._toggle;
  }
  @Input()
  public set toggle(value: boolean) {
    this._toggle = value;
  }

  // DataSources secondaires
  lieuStockageDataSource: LieuStockageDto[];
  partenaireSourceDataSource: DataSource;
  partenaireDestinationDataSource: DataSource;
  bonFromReliquatDto: BonACreerFromReliquatDto;

  private currentMouvementTypeCodeBo: string;
  currentSocieteId: number;
  currentSiteId: number;
  currentSitePartenaireId: number;
  currentUserId: number;
  currentBonId: number;
  currentMouvementType: LexiMouvementTypeDto;
  bon: BonDto;
  bonStrategieComptage: StrategieComptage;
  /** L'étape sélectionnée lorsque la popup de confirmation des quantités mouvementées est ouverte */
  selectedEtape: FluxEtapeDto;
  uniteDataSource: UniteDto[];
  marchandiseLignes: MarchandiseLigne[];
  ecritureLignes: EcritureDto[];

  isCreation = false;
  /** true si c'est une création ou si l'utilisateur a cliqué sur 'Modifier' */
  isModificationEnCours = false;
  /** true si une action côté serveur est en cours */
  disableAllBtn = false;
  showLignes = true;
  showConfirmationQuantitePopup = false;
  showCreationReliquatPopup = false;
  isCreationReliquatEnCours = false;
  marchandiseLignesForPopup: MarchandiseLigne[];
  showErrorPopup = false;
  errorPopupMessage: string;
  actionsMouvementer = [EtapeAction.mouvementerTout];
  hasActionMouvementer = false;
  ObjectType = ObjectType;
  canGererBons: boolean = false;
  canGererEcrituresComptables: boolean = false;
  canAfficherQuantitesTheoriquesSurUnBonInventaire = false;
  canGererParametres = false;
  canFaireImageDuStock = false;
  canAfficherMouvementsStock = false;
  canModifierQuantiteReservee: boolean = false;
  saisieNumeroSerie: boolean = false;
  hasDocumentLogistique: boolean = false;
  documentLogistique: DocumentLogistiqueEnteteDto;
  mouvementSens = MouvementSens;
  fluxStatut = FluxStatut;
  modeReservation = ModeReservation;
  disponibiliteDataSource: DisponibiliteMarchandiseDto[];
  showDisponibilitePopup= false;
  nomQuantiteInitiale: string;
  nomQuantiteReservee: string;
  showLoader = false;
  showPage = false;
  isPrinting = false;
  /** true si le stockage source est lié à une caisse */
  sourceStockageEstLieAUneCaisse = false;
  /** true si le stockage destination est lié à une caisse */
  destinationStockageEstLieAUneCaisse = false;
  canDoInventaireNoSerie = false;
  conditionnementTypeParDefaut = ConditionnementType.base;
  showAlerteStockInsuffisant = false;
  currentSourceStockage: LieuStockageDto;
  currentDestinationStockage: LieuStockageDto;
  anyMarchandiseSelected: boolean = false;
  showPopupImpressionEtiquette: boolean = false;
  marchandiseLignesForImpression: MarchandiseLigne[];
  optionNombreEtiquette = [
    {option : OptionNombreEtiquette.quantiteTheorique , libelle : "Quantité théorique"},
    {option : OptionNombreEtiquette.quantiteMouvementee , libelle : "Quantité mouvementée"},
    {option : OptionNombreEtiquette.custom , libelle : "Quantité personnalisée"},
  ];
  impressionEtiquetteArticleDto: ListEditionDto;
  nombreEtiquetteCustom: number;


  /**
   * L'utilisateur peut effectuer une action sur ce bon et avancer dans le processus
   * décrit par les étapes du process lié au type de mouvement lié au bon
   */
  private get userHasEtapesPossibles(): boolean {
    return this.fluxEtapeListRef?.fluxEtapesPossibles?.length > 0;
  }


  /**
   * Je suis initiateur du bon si :
   * - C'est une création de bon ou
   * - C'est un inventaire ou
   * - Je suis la destination et c'est un mouvement de sens entrée ou
   * - Je suis la source et c'est un mouvement de sens sortie
   * - Je suis la source et c'est un bon en deux étapes
   *
   * Note : Dans le cas d'un transfert interne (même partenaire destination et source),
   * on est pas en mesure de dire qui entre la source et la destination est initiateur.
   */
  get userIsInitiateur(): boolean {
    return this.isCreation
      || this.currentMouvementType?.sens === MouvementSens.inventaire
      || (this.userIsDestination && this.currentMouvementType?.sens === MouvementSens.entree)
      || (this.userIsSource && this.currentMouvementType?.sens === MouvementSens.sortie)
      || (this.userIsSource && this.currentMouvementType?.typeGeneration === MouvementTypeGeneration.bonDeuxEtapes)
    ;
  }


  /** Sert à déterminer si l'utilisateur peut voir le bon. Ce doit être le cas lorsque :
   * - C'est une création
   * - L'utilisateur est la source ou la destination
   */
  private get canViewBon(): boolean {
    return this.isCreation || this.userIsDestination || this.userIsSource;
  }

  get userIsDestination(): boolean {
    return this.bon != null && this.bon.destinationStockagePartenaireId == this.currentSitePartenaireId;
  }

  get userIsSource(): boolean {
    return this.bon != null && this.bon.sourceStockagePartenaireId == this.currentSitePartenaireId;
  }

  get isTransfertInterne(): boolean {
    return this.userIsDestination && this.userIsSource;
  }

  get isCreationOuBrouillon(): boolean {
    return this.isCreation || this.bon?.fluxStatut == null || this.bon.fluxStatut == FluxStatut.new;
  }

  get canEditLieuStockage(): boolean {
    return this.canGererBons && this.isCreationOuBrouillon;
  }

  get canEditPartenaireSource(): boolean {
    return this.canEditLieuStockage && !this.hasDocumentLogistique && this.currentMouvementType?.partenaireSourceModifiable;
  }

  get canEditPartenaireDestination(): boolean {
    return this.canEditLieuStockage && !this.hasDocumentLogistique && this.currentMouvementType?.partenaireDestinationModifiable;
  }

  get canEditQuantiteInitiale(): boolean {
    return this.bonSens != MouvementSens.inventaire && this.isCreationOuBrouillon && this.userIsInitiateur && !this.isBonEntreeFromFlottant;
  }

/**
 * Détermine si le bon est un bon d'entrée issu d'un document en deux étapes
 * On ne peut pas modifier les quantités initiales et les marchandises d'un bon d'entrée
 */
  get isBonEntreeFromFlottant(): boolean {
    return this.bon?.sourceStockageType === LieuStockageType.flottant
      && this.currentMouvementType?.typeGeneration === MouvementTypeGeneration.bonDeuxEtapes;
  }

  /** L'utilisateur peut modifier la quantité réservée si :
   * - Le bon n'est pas null et le statut n'est pas finalisé
   * - Il peut passer à une étape suivante
   * - Il a la permission de modifier la quantité réservée
   * - L'étape possède l'action Compter
   */
  get canEditQuantiteReservee(): boolean {
    return this.bon != null && this.bon.fluxStatut !== FluxStatut.closed
      && this.userHasEtapesPossibles
      && this.canModifierQuantiteReservee
      && this.bon.etapeAction === EtapeAction.compter
    ;
  }

  get bonSens(): MouvementSens {
    // Inventaire
    if (this.currentMouvementType?.sens === MouvementSens.inventaire) return MouvementSens.inventaire;

    // Transfert interne : on essaie de discriminer la caisse pour la mettre sur la carte de droite
    if (this.isTransfertInterne) {
      const sourceIsCaisse = this.sourceStockageEstLieAUneCaisse && !this.destinationStockageEstLieAUneCaisse;
      const destinationIsCaisse = this.destinationStockageEstLieAUneCaisse && !this.sourceStockageEstLieAUneCaisse;
      return sourceIsCaisse ? MouvementSens.entree
        : destinationIsCaisse ? MouvementSens.sortie
        : this.currentMouvementType?.sens
      ;
    }

    // Transfert entre deux partenaires différents
    return this.userIsSource && this.currentMouvementType?.sens == MouvementSens.entree ? MouvementSens.sortie
      : this.userIsDestination && this.currentMouvementType?.sens == MouvementSens.sortie ? MouvementSens.entree
      : this.currentMouvementType?.sens
    ;
  }

  /** Condition pour afficher la colonne "Écart" dans la popup de confirmation des mouvements */
  get anyMarchandiseLignesForPopupHasEcart(): boolean {
    return this.marchandiseLignesForPopup != null
      && this.marchandiseLignesForPopup.length > 0
      && this.marchandiseLignesForPopup.some(x => x.ecart !== 0)
    ;
  }

  get anyMarchandiseLignesForPopupHasEcartNegatif(): boolean {
    return this.anyMarchandiseLignesForPopupHasEcart && this.marchandiseLignesForPopup.some(x => x.ecart < 0);
  }

  get anyMarchandiseSerialiseesLignesHasEcart(): boolean {
    if (this.bon.strategieComptage.saisieNumeroSerie) {
      return false;
    }

    return this.marchandiseLignes != null
      && this.marchandiseLignes.length > 0
      && this.marchandiseLignes.some(x => x.ecart !== 0 && x.nombreNumeroSerie > 0);
  }

  get getTooltipMessageConfirmerBon(): string {
    return this.anyMarchandiseSerialiseesLignesHasEcart ? 'Un ou plusieurs articles sérialisés en écart' : '';
  }

  get disableBoutonImageStock(): boolean {
    return this.isModificationEnCours;
  }

  get isComptageEnCours(): boolean {
    return this.bon?.comptagesStatut?.some(statut => statutsComptagesEnCours.includes(statut));
  }

  get hasEtapeActionReserver(): boolean {
    return this.fluxEtapeListRef?.fluxEtapesPossibles?.some(e => e.action == EtapeAction.reserver);
  }

  get showNoSerieInputs(): boolean {
    // Cas inventaire
    if (this.bonSens === MouvementSens.inventaire) {
      return this.canDoInventaireNoSerie
        && this.bon?.strategieComptage != null
        && this.bon.strategieComptage.saisieNumeroSerie;
    }

    // Cas transfert interne entre lieu parent sérialisé et enfant non sérialisé
    if (this.isTransfertInterne && this.currentDestinationStockage != null && this.currentSourceStockage != null) {
      const dest = this.currentDestinationStockage;
      const src = this.currentSourceStockage;
      // Le lieu destination est sérialisé et est le lieu parent
      const parentSerialiseAvecEnfantNonSeriealise = (dest.suiviNumeroSerie && !src.suiviNumeroSerie && src.lieuParentId == dest.id)
      // Le lieu source est sérialisé et est le lieu parent
        || (src.suiviNumeroSerie && !dest.suiviNumeroSerie && dest.lieuParentId == src.id);

      return !parentSerialiseAvecEnfantNonSeriealise;
    }

    // Cas entrée / sortie par défaut
    return true;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly bonsLexiClient: BonsLexiClient,
    private readonly mouvementTypesLexiClient: LexiMouvementTypesLexiClient,
    private readonly lieuStockagesLexiClient: LieuStockagesLexiClient,
    private readonly router: Router,
    private readonly fluxLexiClient: FluxLexiClient,
    private readonly unitesLexiClient: UnitesLexiClient,
    private readonly downloadService: DownloadService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly docLogistiquesLexiClient: DocumentLogistiquesLexiClient,
    private readonly referenceService: ReferenceService,
    private readonly objectUtilitiesService: ObjectUtilitiesService,
    private readonly conditionnementService: ConditionnementService,
    private readonly cd: ChangeDetectorRef,
    private readonly editionsLexiClient: EditionsLexiClient
  ) {
    this.setCurrentSiteId();
    this.setCurrentSocieteId();
    this.setCurrentUserId();
    this.impressionEtiquetteArticleDto = {
      optionNombreEtiquette : OptionNombreEtiquette.quantiteTheorique,
      editionTemplateId: undefined,
      editionObjectIds: []
    };

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.canGererBons = this.authService.securityUserisGrantedWith(Permissions.canGererBons);
    this.canGererEcrituresComptables = this.authService.securityUserisGrantedWith(Permissions.canGererEcrituresComptables);
    this.canAfficherQuantitesTheoriquesSurUnBonInventaire = this.authService.securityUserisGrantedWith(Permissions.canAfficherQuantitesTheoriquesSurUnBonInventaire);
    this.canModifierQuantiteReservee = this.authService.securityUserisGrantedWith(Permissions.canModifierQuantiteReservee);
    this.canAfficherMouvementsStock = this.authService.securityUserisGrantedWith(Permissions.accesVueConsultationStock);
    this.canGererParametres = this.authService.securityUserisGrantedWith(Permissions.canGererParametresBons);
    this.canFaireImageDuStock = this.authService.securityUserisGrantedWith(Permissions.canFaireImageDuStock);
    this.activatedRoute.params.subscribe(async params => {
      try {
        const bonId = params['id'];

        if (isNaN(Number(bonId))) {
          // Mauvais paramètre, on redirige sur la liste des bons
          if (!(bonId as string).startsWith('nouveau')) {
            await this.notifyErrorAndNavigateToBonList('Url incorrecte. Redirection vers la liste des bons...');
            return;
          }

          // L'utilisateur n'a pas le droit de créer un nouveau bon
          if (!this.canGererBons) {
            await this.notifyErrorAndNavigateToBonList("Vous n'avez pas la permission de créer un nouveau bon. Redirection vers la liste des bons...");
            return;
          }

          // Création d'un nouveau bon
          this.isCreation = true;
          this.isModificationEnCours = true;
          this.activatedRoute.queryParams.subscribe(async queryParams => {
            let mouvementTypeCodeBo = queryParams['mouvementTypeCodeBo'];
            if (!mouvementTypeCodeBo) { // Cas où on recharge la page de création et l'url ressemble à /bon/nouveau%3FmouvementTypeCodeBo%3DPREL-DELEST
              mouvementTypeCodeBo = (bonId as string).substring((bonId as string).indexOf('mouvementTypeCodeBo=') + 20)
            }

            // Type de mouvement inconnu
            if (!mouvementTypeCodeBo || mouvementTypeCodeBo == '') {
              await this.notifyErrorAndNavigateToBonList('Impossible de trouver le type de mouvement. Redirection vers la liste des bons...');
              return;
            }

            this.currentMouvementTypeCodeBo = mouvementTypeCodeBo;
            const isDuplication: boolean = queryParams['isDuplication'] === 'true';
            const bonADupliquerId: number = isNaN(Number(queryParams['bonADupliquerId'])) ? null : Number(queryParams['bonADupliquerId']);
            await this.loadPageData(isDuplication, bonADupliquerId);
          });
          return;
        }

        // Modification d'un bon existant
        this.isCreation = false;
        this.isModificationEnCours = false;
        this.currentBonId = Number(bonId);
        await this.loadPageData();
        if (!this.canViewBon) await this.notifyErrorAndNavigateToBonList("Vous n'avez pas les droits pour visionner ce bon. Redirection vers la liste des bons...");
      }

      finally {
        setTimeout(() => this.showPage = true, 500);
      }
    });
  }

  //#region Méthodes principales
  private async loadPageData(isDuplication: boolean = false, bonADupliquerId: number = null) {
    this.showLignes = false;
    this.uniteDataSource = await lastValueFrom(this.unitesLexiClient.getListeUnites());

    // En cas de modification, le bon doit être récupéré en amont pour pouvoir récupérer le type de mouvement
    if (!this.isCreation) {
      await this.setSelectBoxesDataSources();
      await this.setBon(this.currentBonId);
    }

    await this.setCurrentMouvementType();
    // Impossible de continuer sans type de mouvement
    if (!this.currentMouvementType) return;

    // En cas de création, le type de mouvement doit être récupéré en amont avant de pouvoir alimenter correctement le nouveau bon
    if (this.isCreation) {
      await this.setSelectBoxesDataSources();
      await this.setBon(bonADupliquerId);
    }

    // Si c'est un transfert interne, on essaie de discriminer la caisse pour la mettre sur la carte de droite
    if (!this.isCreation && this.isTransfertInterne) {
      this.sourceStockageEstLieAUneCaisse = this.bon.sourceStockageId && await lastValueFrom(this.lieuStockagesLexiClient.estLieAUneCaisse(this.bon.sourceStockageId));
      this.destinationStockageEstLieAUneCaisse = this.bon.destinationStockageId && await lastValueFrom(this.lieuStockagesLexiClient.estLieAUneCaisse(this.bon.destinationStockageId));
    }

    // Set du document logistique parent
    if (this.bon.documentLogistiqueId != null) {
      this.documentLogistique = await lastValueFrom(this.docLogistiquesLexiClient.getEnteteById(this.bon.documentLogistiqueId));
    }

    // Set conditionnement par défaut selon la stratégie de comtpage du bon
    this.conditionnementTypeParDefaut = this.conditionnementService.getConditionnementTypeFromUniteType(this.bon?.strategieComptage?.uniteParDefaut);

    // Set du nom des quantités
    this.setNomDesQuantites();
    // Set des cartes
    this.bonDetailLieuStockageRef?.setCartes();
    // Set des lignes
    await this.setLignes(isDuplication, bonADupliquerId);
    // Set des écritures
    // TODO : A réactiver ou supprimer après avoir vu avec @Damien ET après avoir revérifier la récupération des écritures
    // if (!this.isCreation && this.canGererEcrituresComptables) this.ecritureLignes = await lastValueFrom(this.ecrituresLexiClient.getPourBon(this.currentBonId));
    // Affichage des lignes après avoir chargé les données
    this.showLignes = true;
    // Set de la taille de la dataGrid des lignes
    setTimeout(() => this.onToggleLieuStockageDisplay(false), 100);

    // Dans le cas d'un inventaire, on récupère l'info sur le fait de mouvementer les n° de série ou pas
    if (this.bonSens === MouvementSens.inventaire && this.bon?.destinationStockageId != null) {
      this.canDoInventaireNoSerie = await lastValueFrom(this.lieuStockagesLexiClient.canDoInventaireNumerosSeries(this.bon.destinationStockageId));
    }
  }

  private async setBon(id: number) {
    if (id == null) {
      this.bon = {
        partenaireSourceId: this.getCurrentMouvementTypePartenaireSourceId(),
        partenaireDestinationId: this.getCurrentMouvementTypePartenaireDestinationId(),
        sourceStockagePartenaireId: this.getCurrentMouvementTypePartenaireSourceId(),
        destinationStockagePartenaireId: this.getCurrentMouvementTypePartenaireDestinationId(),
        sourceStockageId: this.currentMouvementType.sens == MouvementSens.inventaire ? await this.getLieuStockageReguleId() : await this.getCurrentMouvementTypeStockageSourceId(),
        destinationStockageId: await this.getCurrentMouvementTypeStockageDestinationId(),
        sens: this.currentMouvementType.sens,
        dateSouhaite: new Date().toISOString(),
        fluxHistoriqueActions: [],
        strategieComptage: (this.currentMouvementType.sens == MouvementSens.entree || this.currentMouvementType.sens == MouvementSens.inventaire) ? this.currentMouvementType.strategieComptageDestinataire : this.currentMouvementType.strategieComptageExpediteur
      };
    }
    else {
      this.bon = await lastValueFrom(this.bonsLexiClient.getById(id));
      this.hasActionMouvementer = this.bon.fluxHistoriqueActions?.some(a => this.actionsMouvementer.includes(a)) ?? false;
    }

    this.hasDocumentLogistique = this.bon.documentLogistiqueId != null;
    /**
     * On utilise une variable avec la valeur (et non la référence) de bon.strategieComptage
     * pour éviter de modifier l'interface lorsqu'on fait des changements dans la stratégie de comptage.
     * Ex : Si on modifie "Saisie Numéro de colis", l'onglet "colis" s'affiche ou se masque selon si case cochée.
     * */
    this.bonStrategieComptage = this.objectUtilitiesService.deepCopy(this.bon.strategieComptage);
    await this.setBonSourceEtDestinationInfos();
  }

  private async setLignes(isDuplication: boolean = false, bonADupliquerId: number = null) {
    this.marchandiseLignes = [];

    if (isDuplication || !this.isCreation) {
      const lignes = await lastValueFrom(this.bonsLexiClient.getLignes(isDuplication ? bonADupliquerId : this.currentBonId));
      for (const l of lignes) {
        if (l.articleId) {
          console.log(l);
          this.marchandiseLignes.push({
            bonLigneId: l.id,
            articleId: l.articleId,
            articleIntitule: l.articleIntitule,
            articleCodeBo: l.articleCodeBo,
            zIndex: l.zIndex,
            uniteId: l.uniteId,
            quantiteInitiale: l.quantiteInitiale,
            quantiteReservee: isDuplication ? 0 : l.quantiteReservee,
            quantiteMouvementee: isDuplication ? 0 : l.quantiteMouvementee,
            ecart: isDuplication ? l.quantiteInitiale : l.quantiteReservee - l.quantiteInitiale + (this.bonSens != MouvementSens.inventaire ? l.quantiteMouvementee : 0),
            qteDisponibleResteDocument: l.qteDisponibleResteDocument,
            stockSource: l.stockSource,
            stockDestination: l.stockDestination,
            stockNegatifAutorise: l.stockNegatifAutorise,
            numerosSeries: this.bon.numeroSeries?.numeros?.filter(x => x.articleId == l.articleId).map(x => x.numeroSerie ?? ""),
            conditionnementVenteId: l.conditionnementVenteId,
            conditionnementVente: l.conditionnementVente,
            conditionnementAchatId: l.conditionnementAchatId,
            conditionnementAchat: l.conditionnementAchat,
            nombreNumeroSerie: l.nombreNumeroSerie,
            referenceFournisseur: l.referenceFournisseur,
          });
        }
      }
    }
  }
  //#endregion

  //#region Gestion des évènements
  async onCancel() {
    try {
      this.disableAllBtn = true;
      await this.loadPageData();
    } finally {
      this.isModificationEnCours = false;
      setTimeout(() => { // Obligatoire pour ne pas trigger la gestion des évènements du BonDetailLieuStockageComponent qui pose problème d'affichage
        this.disableAllBtn = false;
      }, 1);
    }
  }

  async onUpdateBon() {
    try {
      // On enregistre les modification des marchandises en cours si il y en a
      if (this.bonDetailLignesRef?.dataGridMarchandiseLignes?.instance?.hasEditData()) {
        await this.bonDetailLignesRef.dataGridMarchandiseLignes.instance.saveEditData();
      }

      // On vérifie que le formulaire d'entête soit valide
      const isValid = this.bonDetailLieuStockageRef.form.instance.validate().isValid;
      if (!isValid) { return; }

      if (this.bon.intitule == null || this.bon.intitule.trim() === "") {
        notify({closeOnClick: true, message: "L'intitulé est obligatoire."}, NotificationType.Warning);
        return;
      }

      this.disableAllBtn = true;

      const dto: UpdateBonDto = {
        id: this.bon.id,
        fluxStatut: this.bon.fluxStatut,
        dateSouhaite: this.bon.dateSouhaite,
        intitule: this.bon.intitule,
        editedBy: this.currentUserId,
        typeId: this.bon.typeId,
        partenaireSourceId: this.bon.partenaireSourceId,
        partenaireDestinationId: this.bon.partenaireDestinationId,
        sourceStockageId: this.bon.sourceStockageId,
        destinationStockageId: this.bon.destinationStockageId,
        lignes: this.getLignesToSubmit(),
        strategieComptage: this.bonStrategieComptage,
      };
      await lastValueFrom(this.bonsLexiClient.updateBon(dto));
      notify({closeOnClick: true,
        message: `Le bon n°${this.currentBonId} a bien été mis à jour.`},
        NotificationType.Success
      );
    }
    finally {
      this.isModificationEnCours = false;
      this.disableAllBtn = false;
      await this.loadPageData();
    }
  }

  async onCreateNewBon() {
    try {
      // On enregistre les modification des marchandises en cours si il y en a
      if (this.bonDetailLignesRef?.dataGridMarchandiseLignes?.instance?.hasEditData()) {
        this.bonDetailLignesRef.dataGridMarchandiseLignes.instance.saveEditData();
        // On attends que le BonDetailLignesComponent.OnSaving s'applique
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // On vérifie que le formulaire d'entête soit valide
      const isValid = this.bonDetailLieuStockageRef.form.instance.validate().isValid;
      if (!isValid) { return; }

      if (this.bon.intitule == null || this.bon.intitule.trim() === "") {
        notify({closeOnClick: true, message: "L'intitulé est obligatoire."}, NotificationType.Warning);
        return;
      }

      if (this.bon.destinationStockageId == null) {
        notify({closeOnClick: true, message: "La destination est obligatoire."}, NotificationType.Warning);
        return;
      }

      if (this.bon.partenaireSourceId == null || this.bon.sourceStockageId == null) {
        notify({closeOnClick: true, message: "La source est obligatoire."}, NotificationType.Warning);
        return;
      }

      this.disableAllBtn = true;

      // Récupération de l'étape à mettre (FluxEtape)
      const fluxEtape: FluxEtapeDto = await this.getFluxEtape();

      const newBon: BonACreerDto = {
        editedBy: this.currentUserId,
        dateSouhaite: this.bon.dateSouhaite,
        typeId: this.currentMouvementType.id,
        fluxEtapeId: fluxEtape?.id,
        fluxStatut: fluxEtape?.evenementTypeDefaultStatut ?? FluxStatut.new,
        partenaireSourceId: this.bon.partenaireSourceId,
        sourceStockageId: this.bon.sourceStockageId,
        partenaireDestinationId: this.bon.partenaireDestinationId,
        destinationStockageId: this.bon.destinationStockageId,
        lignes: this.getLignesToSubmit(),
        intitule: this.bon.intitule
      };
      const newBonId = await lastValueFrom(this.bonsLexiClient.creer(newBon));
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/bon/${newBonId}`]);
      });

    } finally {
      this.disableAllBtn = false;
    }
  }

  onNavigateToBonList() {
    this.router.navigate(['/visualisation-des-bons']);
  }

  onNavigateToDocumentLogistiqueParent() {
    const canAfficherDocumentsLogistiques = this.authService.securityUserisGrantedWith(Permissions.canAfficherDocumentsLogistiques);
    if (canAfficherDocumentsLogistiques)
      this.router.navigate([`/logistique/documents/${this.bon?.documentLogistiqueId}`]);
    else
      notify({closeOnClick: true, message: "Vous n'avez pas la permission d'afficher les documents logistiques."}, NotificationType.Info);
  }

  async onClickEtape(etape: FluxEtapeDto) {
    this.disableAllBtn = true;
    try {
      await this.handleEtape(etape);
    }
    finally {
      if (!this.showConfirmationQuantitePopup) {
        this.disableAllBtn = false;
      }
    }
  }

  private async handleEtape(etape: FluxEtapeDto) {
    // On affiche une erreur à l'utilisateur si un lieu de stockage est manquant
    const showError = this.showPopupErrorIfNeeded();
    if (showError) return;

    // Si l'action mouvemente du stock, on gère dans une popup à part
    if (etape.action === EtapeAction.mouvementerTout){
      this.showConfirmationPopup(etape);
      return;
    } else if (etape.action === EtapeAction.compter && this.bon.sens === MouvementSens.inventaire) {
      const isQuantitesInitialesNonNulles = this.marchandiseLignes.some(m => m.quantiteInitiale != 0);
      if (!isQuantitesInitialesNonNulles) {
        const confirmed = await confirm(
          `Toutes les quantiés théoriques sont nulles, avez-vous fait l'image du stock ?
          Cliquer sur 'Oui' pour poursuivre
          `,
          `Demande de confirmation`
        ).catch(() => false); // catch nécessaire sinon erreur quand on appui "échap"
        if (!confirmed) return;
      }
    }

    let isFluxUpdated: boolean;

    if (etape.action == EtapeAction.reserver) {
      await this.reserverQuantites(etape.id);

      isFluxUpdated = true;
    } else {
      isFluxUpdated = await this.fluxEtapeListRef.updateFluxEtape(etape);
    }
    if (isFluxUpdated) {
      await this.loadPageData();
      this.fluxHistoriqueListComponent?.setFluxHistorique();
    }
  }

  async reserverQuantites(etapeId: number) {
    const fluxUpdateDto: FluxUpdateDto = this.fluxEtapeListRef.getFluxUpdateDto(etapeId)
    const resultatReservation = await lastValueFrom(this.bonsLexiClient.reserver(fluxUpdateDto));

    if (resultatReservation.resultat == ReserverQuantitesResultEnum.aucune) {
      notify({closeOnClick: true, message: "Certaines quantités sont indisponibles. Vérifiez les disponibilités."}, NotificationType.Error);
    } else {
      if (resultatReservation.resultat == ReserverQuantitesResultEnum.totale) {
        notify({closeOnClick: true, message: "Toutes les quantités ont été réservées"}, NotificationType.Success);
      } else if (resultatReservation.resultat == ReserverQuantitesResultEnum.partielle) {
        notify({closeOnClick: true, message: "Certaines quantités n'ont pas été totalement réservées"}, NotificationType.Warning);
      }
      await this.fluxEtapeListRef.setFluxEtapesPossibles();
    }
  }

  closeConfirmationPopup = async () => {
    this.showConfirmationQuantitePopup = false;
    this.showAlerteStockInsuffisant = false;
    this.selectedEtape = null;
    this.marchandiseLignesForPopup = null;
  }

  closeErrorPopup = () => {
    this.showErrorPopup = false;
  }

  mouvementerAvecReliquat = async (): Promise<number> => {
    const updateBonQuantitesAvecReliquatDto : UpdateBonQuantitesAvecReliquatDto = {
      updateBonQuantites: this.getUpdateBonQuantites(),
      reliquat: this.bonFromReliquatDto
    };
    return await lastValueFrom(this.bonsLexiClient.updateQuantitesThenFluxAvecReliquat(updateBonQuantitesAvecReliquatDto));
  }

  mouvementerSansReliquat = async () => {
    const dto = this.getUpdateBonQuantites();
    await lastValueFrom(this.bonsLexiClient.updateQuantitesThenFlux(dto));
    this.refreshMouvementStocks();
    await this.loadPageData();
  }

  getUpdateBonQuantites = () => {
    const dtoLignes: UpdateBonLigneQuantitesDto[] = this.marchandiseLignesForPopup.map(m => {
      return {
        bonLigneId: m.bonLigneId,
        quantiteInitiale: m.quantiteInitiale,
        quantiteReservee: m.quantiteReservee
      };
    });

    const dtoIntervenants: IntervenantType[] = [IntervenantType.tous];

    if (this.bon.partenaireSourceId === this.currentSitePartenaireId) {
      dtoIntervenants.push(IntervenantType.fournisseur);
    }

    if (this.bon.partenaireDestinationId === this.currentSitePartenaireId) {
      dtoIntervenants.push(IntervenantType.client);
    }

    const dto: UpdateBonQuantitesDto = {
      societeId: this.currentSocieteId,
      siteId: this.currentSiteId,
      utilisateurId: this.currentUserId,
      bonId: this.currentBonId,
      bonLignes: dtoLignes,
      nextEtapeId: this.selectedEtape?.id,
      intervenantTypes: dtoIntervenants,
    };
    return dto;
  }

  onConfirmationQuantiteMouvementee = async (e: {element: HTMLElement}) => {
    this.showConfirmationQuantitePopup = false;
    this.showAlerteStockInsuffisant = false;
    try {
      const avecReliquat: boolean = e.element.getAttribute("data-reliquat") === "Avec reliquat";
      if (avecReliquat) {
        this.initReliquat();
      }
      else {
        this.showLoader = true;
        await this.mouvementerSansReliquat();
      }
    }
    catch {
      try {
        await this.loadPageData();
      } catch { }
    }
    finally {
      this.showLoader = false;
    }
  }

  onConfirmationCreationReliquat = async (e) => {
    this.showCreationReliquatPopup = false;
    let reliquatId: number = null;
    try {
      this.showLoader = true;
      reliquatId = await this.mouvementerAvecReliquat();
    }
    finally {
      this.isCreationReliquatEnCours = false;
      if (reliquatId != null) {
        this.router.navigate([`/bon/${reliquatId}`]);
      } else {
        notify({closeOnClick: true, message:"Une erreur est survenue lors de la création du reliquat. Aucun mouvement n'a été effectué."}, NotificationType.Error)
      }
      this.disableAllBtn = false;
      this.showLoader = false;
    }
  }


  onConfirmationQuantitePopupHiding = async (e: {element: HTMLElement}) => {
    if (this.isCreationReliquatEnCours) {
      this.showCreationReliquatPopup = true;
    }
  }

  verifierQuantite = async () => {
    this.disponibiliteDataSource = await lastValueFrom(this.bonsLexiClient.getDisponibiliteMarchandise(this.bon.id));
    if (this.disponibiliteDataSource?.length > 0) {
      this.showDisponibilitePopup = true;
    } else {
      notify({closeOnClick: true, message:"Toutes les quantités sont disponibles"}, NotificationType.Success);
    }
  }

  onPopupDisponibiliteHidden = () => {
    this.showDisponibilitePopup = false;
  }

  private async getFluxEtape(): Promise<FluxEtapeDto> {
    let fluxEtape: FluxEtapeDto =  null;
    const fluxId = this.userIsSource && this.currentMouvementType.sens !== MouvementSens.inventaire ? this.currentMouvementType.fluxExpediteurId : this.currentMouvementType.fluxDestinataireId;
    if (fluxId != null) {
      const etapes = await lastValueFrom(this.fluxLexiClient.getEtapesByFluxId(fluxId));
      if (etapes) {
        const smallestZIndex = Math.min(...etapes.map(x => x.zIndex));
        fluxEtape = etapes.find(x => x.zIndex == smallestZIndex);
      }
    }
    return fluxEtape;
  }

  onDupliquerBon() {
    this.router.navigate([`/bon/nouveau`], {
      queryParams: {
        mouvementTypeCodeBo: this.currentMouvementType.codeBo,
        isDuplication: true,
        bonADupliquerId: this.currentBonId
      }
    });
  }

  async onPrintBon() {
    this.isPrinting = true;
    try {
      const response = await lastValueFrom(this.bonsLexiClient.getEdition(this.currentBonId, 'response'));
      this.downloadService.directDownloadFile(response, '.pdf');
    } finally {
      this.isPrinting = false;
    }
  }

  async onStockageChanged(origine: 'source' | 'destination') {
    if (origine == 'source') {
      this.currentSourceStockage = this.lieuStockageDataSource.find(x => x.id == this.bon.sourceStockageId);
      if (this.bon != null) this.bon.sourceStockagePartenaireId = this.currentSourceStockage?.partenaireId;
    }
    else {
      this.currentDestinationStockage = this.lieuStockageDataSource.find(x => x.id == this.bon.destinationStockageId);
      if (this.bon != null ) this.bon.destinationStockagePartenaireId = this.currentDestinationStockage?.partenaireId;
    }

    if (this.marchandiseLignes == null || this.marchandiseLignes.length == 0) return;
    await this.calculateLignesStock(origine, this.marchandiseLignes.map(m => m.articleId));
  }

  async refreshStock(articleIds: number[]) {
    await this.calculateLignesStock('source', articleIds);
    await this.calculateLignesStock('destination', articleIds);
  }

  /**
   * @param toggle si true affiche/cache les cartes, sinon set uniquement la hauteur de la datagrid des lignes de marchandises
   */
  onToggleLieuStockageDisplay(toggle = true) {
    // On affiche ou cache les cartes des lieux de stockage
    const wrapper = document.getElementById("bonDetailLieuStockageWrapper");
    if (wrapper?.style == null) return;
    if (toggle) {
      wrapper.style.display = wrapper.style.display == "block" ? "none" : "block";
    }

    // On modifie la taille de la dataGrid
    const datagrid = document.getElementById("bonDetailLignesDataGrid");
    if (datagrid?.style != null) {
      const heightToReduce = wrapper.style.display == "none" ? 241 : 349;
      datagrid.style.height = `calc(100vh - ${heightToReduce}px)`;
    }
    this.toggle = wrapper.style.display == "none";
  }

  async onComptageChanged() {
    this.disableAllBtn = true;
    try {
      await this.loadPageData();
    } finally {
      this.disableAllBtn = false;
    }
  }

  async onCopieImageStockClick(e) {
    const hasComptageEnCours = !!(this.bon?.comptagesStatut?.find(x => x != ComptageStatut.annule && x != ComptageStatut.enAttente));
    if (hasComptageEnCours) {
      const confirmed = await confirm(`Il y a un ou plusieurs comptages en cours, la quantité théorique et les écarts seront recalculés`, 'Confirmer');
      if (!confirmed) {
        return;
      }
    }

    try
    {
      const chercherDansSource: boolean = false;
      await lastValueFrom(this.bonsLexiClient.copierImageStock(this.bon?.id, e.itemData.id, chercherDansSource));
      notify({closeOnClick: true, message: `Les quantités du bon n°${this.currentBonId} ont bien été mises à jour.`}, NotificationType.Success);
    }
    finally
    {
      await this.loadPageData();
    }
  }
  handleMarchandiseSelected(e) {
    this.marchandiseLignesForImpression = e;
    this.anyMarchandiseSelected = this.marchandiseLignesForImpression.length > 0;
  }

  showImpressionPopup() {
    this.showPopupImpressionEtiquette = true;
  }

  async handleImpressionEtiquette() {
    this.impressionEtiquetteArticleDto.editionObjectIds = this.getImpressionEtiquetteByOptionNombreEtiquette();
    if (this.impressionEtiquetteArticleDto.editionObjectIds){
      const response = await lastValueFrom(
        this.editionsLexiClient.getByEditionTemplateAndObjectIdPOST(this.impressionEtiquetteArticleDto , 'response')
      );
      this.downloadService.directDownloadFile(response, 'pdf');
    }
  }

  getTitlePopupEtiquettes() {
    const nombreArticleSelectionnee =  this.marchandiseLignesForImpression?.length ?? '';

    return `Imprimer les étiquettes - ${nombreArticleSelectionnee} article(s) sélectionné(s)`;
  }

  getImpressionEtiquetteByOptionNombreEtiquette() {
    
    if (this.impressionEtiquetteArticleDto.optionNombreEtiquette === OptionNombreEtiquette.quantiteTheorique
      && this.marchandiseLignesForImpression.some(x => x.quantiteInitiale > 0)) {
        return this.marchandiseLignesForImpression.map(marchandise => ({
          objectId: marchandise.articleId,
          nombreTemplate: marchandise.quantiteInitiale
        }));
    } else if (this.impressionEtiquetteArticleDto.optionNombreEtiquette === OptionNombreEtiquette.quantiteMouvementee
      && this.marchandiseLignesForImpression.some(x => x.quantiteMouvementee > 0)) {
        return this.marchandiseLignesForImpression.map(marchandise => ({
          objectId: marchandise.articleId,
          nombreTemplate: marchandise.quantiteMouvementee
        }));
    } 

    return this.marchandiseLignesForImpression.map(marchandise => ({
      objectId: marchandise.articleId,
      nombreTemplate: this.nombreEtiquetteCustom > 0 ? this.nombreEtiquetteCustom : 1
    }));
  }

  handleTemplateIdChange(e) {
    this.impressionEtiquetteArticleDto.editionTemplateId = e;
  }

  isOptionNombreEtiquetteCustom() {
    return this.impressionEtiquetteArticleDto.optionNombreEtiquette === OptionNombreEtiquette.custom;
  }

  informationsImpressionValide(): boolean {
    return this.impressionEtiquetteArticleDto.optionNombreEtiquette != null &&
      this.impressionEtiquetteArticleDto.editionTemplateId != null &&
      (
        (this.impressionEtiquetteArticleDto.optionNombreEtiquette === OptionNombreEtiquette.custom && this.nombreEtiquetteCustom > 0) ||
        this.impressionEtiquetteArticleDto.optionNombreEtiquette !== OptionNombreEtiquette.custom
      );
  }

  getStylePourEcart(data): string {
    const bonLigneByArticle = this.marchandiseLignes.find(ml => ml.articleId === data?.key.articleId);
    if (this.bon.strategieComptage.saisieNumeroSerie) {
      return 'font-size: 20px; color: rgb(204, 187, 38); margin-right: 6px;';
    }
    return bonLigneByArticle.nombreNumeroSerie > 0 && bonLigneByArticle.ecart !== 0 ?
      'font-size: 20px; color: rgb(220, 20, 60); margin-right: 6px;' :
      'font-size: 20px; color: rgb(204, 187, 38); margin-right: 6px;';
  }

  getTooltipMessagePourLigneSerialiseEnEcart(data): string {
    const bonLigneByArticle = this.marchandiseLignes.find(ml => ml.articleId === data?.key.articleId);
    return bonLigneByArticle.nombreNumeroSerie > 0 && bonLigneByArticle.ecart !== 0 ?
      'Article sérialisé contenant un écart' :
      '';
  }

  //#endregion



  private setNomDesQuantites() {
    const noms = this.referenceService.getNomsQuantitesDocumentLogistique(this.userIsSource, this.userIsDestination);
    this.nomQuantiteInitiale = noms.quantiteInitiale;
    this.nomQuantiteReservee = noms.quantiteReservee;
  }

  //#region Méthodes helpers
  private initReliquat() {
    const marchandisesLignesPourReliquat = this.marchandiseLignesForPopup.filter(ligne => {
        return ligne.ecart < 0;
      })
    const lignesBonReliquatDto: LignePourReliquatDto[] = marchandisesLignesPourReliquat.map(m => {
        return {
          bonLigneId: m.bonLigneId,
          quantite: m.ecart * -1,
        };
    });

    this.bonFromReliquatDto = {
      bonBaseId: this.bon.id,
      lignes: lignesBonReliquatDto,
      fluxId: this.bon.evenementFluxId,
    }

    this.isCreationReliquatEnCours = true;
  }

  /**
   * Recalcule le stock disponible des lignes de marchandises.
   * Si le lieu de stockage n'est pas renseigné, on prend le stock au niveau du site.
   */
  private async calculateLignesStock(origine: 'source' | 'destination', articleIds: number[]) {
    if (this.marchandiseLignes == null || this.marchandiseLignes.length == 0) return;
    if (articleIds == null || articleIds.length == 0) return;

    // Source
    if (origine == 'source' && this.bon?.sourceStockageId != null) {
      await this.getStockForMarchandiseLignes(articleIds, this.bon.sourceStockageId, 'source');
    }

    // Destination
    if (origine == 'destination' && this.bon?.destinationStockageId != null) {
      await this.getStockForMarchandiseLignes(articleIds, this.bon.destinationStockageId, 'destination');
    }
  }

  private async getStockForMarchandiseLignes(articleIds: number[], lieuStockageId: number, origine: 'source' | 'destination') {
    if (lieuStockageId == null) return;
    if (articleIds == null || articleIds.length == 0) return;
    const stocks = await lastValueFrom(this.bonsLexiClient.getStockResultByLieuStockageAndArticleList(lieuStockageId, articleIds));
    for (const articleId of articleIds) {
      const ligne = this.marchandiseLignes.find(l => l.articleId == articleId);
      if (ligne == null) continue;
      if (origine == 'source') {
        ligne.stockSource = stocks.find(s => s.articleId == articleId);
      } else if (origine == 'destination') {
        ligne.stockDestination = stocks.find(s => s.articleId == articleId);
      }
    }
  }

  /** Retourne true si l'étape cliquée mouvemente du stock et qu'un lieu de stockage est manquant */
  private showPopupErrorIfNeeded(): boolean {
    const showError = this.bon.sourceStockageId == null || this.bon.destinationStockageId == null;
    if (showError) {
      this.showErrorPopup = true;
      this.errorPopupMessage = `Veuillez renseigner tous les lieux de stockage avant de pouvoir effectuer un mouvement de stock.`;
    }
    return showError;
  }

  private showConfirmationPopup(etape: FluxEtapeDto) {
    this.selectedEtape = etape;
    this.marchandiseLignesForPopup = [];
    this.marchandiseLignes.forEach(m => this.marchandiseLignesForPopup.push({
      bonLigneId: m.bonLigneId,
      articleId: m.articleId,
      articleCodeBo: m.articleCodeBo,
      articleIntitule: m.articleIntitule,
      zIndex: m.zIndex,
      uniteId: m.uniteId,
      quantiteInitiale: m.quantiteInitiale,
      quantiteReservee: m.quantiteReservee,
      quantiteMouvementee: null,
      ecart: m.quantiteReservee - m.quantiteInitiale + (this.bonSens != MouvementSens.inventaire ? m.quantiteMouvementee : 0),
      qteDisponibleResteDocument: m.qteDisponibleResteDocument,
      stockSource: m.stockSource,
      stockDestination: m.stockDestination,
      stockNegatifAutorise: m.stockNegatifAutorise,
      numerosSeries : m.numerosSeries
    }));
    if (this.bon.fluxStatut == FluxStatut.new) this.marchandiseLignesForPopup.forEach(m => m.quantiteReservee = m.quantiteInitiale);
    this.showConfirmationQuantitePopup = true;
    this.showAlerteStockInsuffisant = this.marchandiseLignesForPopup.some(x =>
      !x.stockNegatifAutorise
      && x.quantiteReservee > 0
      && x.stockSource != null
      && x.quantiteReservee > (x.stockSource?.quantiteDisponible ?? x.stockSource?.quantite)
    );
  }

  private async setBonSourceEtDestinationInfos() {
    // Set source
    if (this.bon.partenaireSourceId != null) {
      const partenaireDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox();
      partenaireDataSource.filter(["id", "=", this.bon.partenaireSourceId]);
      const partenaire: PartenaireDto = (await partenaireDataSource.load())?.[0];

      if (this.bon.partenaireSourceIntitule == null) {
        this.bon.partenaireSourceIntitule = partenaire?.intitule;
      }

      if (this.bon.sourceStockageId == null) {
        this.bon.sourceStockageId = partenaire?.lieuParDefautId;
      }

      if (this.bon.sourceStockageId != null && this.currentSourceStockage == null) {
        this.currentSourceStockage = this.lieuStockageDataSource.find(x => x.id == this.bon.sourceStockageId);
      }

      if (this.bon.sourceStockageId != null && this.bon.sourceStockageIntitule == null) {
        this.bon.sourceStockageIntitule = this.currentSourceStockage?.intitule;
      }
    }

    // Set destination
    if (this.bon.partenaireDestinationId != null) {
      const partenaireDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox();
      partenaireDataSource.filter(["id", "=", this.bon.partenaireDestinationId]);
      const partenaire: PartenaireDto = (await partenaireDataSource.load())?.[0];

      if (this.bon.partenaireDestinationIntitule == null) {
        this.bon.partenaireDestinationIntitule = partenaire?.intitule;
      }

      if (this.bon.destinationStockageId == null) {
        this.bon.destinationStockageId = partenaire?.lieuParDefautId;
      }

      if (this.bon.destinationStockageId != null && this.currentDestinationStockage == null) {
        this.currentDestinationStockage = this.lieuStockageDataSource.find(x => x.id == this.bon.destinationStockageId);
      }

      if (this.bon.destinationStockageId != null && this.bon.destinationStockageIntitule == null) {
        this.bon.destinationStockageIntitule = this.lieuStockageDataSource.find(x => x.id == this.bon.destinationStockageId)?.intitule;
      }
    }
  }

  private async setCurrentMouvementType() {
    const mouvementTypeDataSource = await lastValueFrom(this.mouvementTypesLexiClient.getMouvementType());
    this.currentMouvementType = mouvementTypeDataSource.find(x => (this.bon && x.id == this.bon.typeId) || x.codeBo == this.currentMouvementTypeCodeBo);
    if (!this.currentMouvementType) await this.notifyErrorAndNavigateToBonList('Impossible de trouver le type de mouvement. Redirection vers la liste des bons...');
  }

  /** Set les DataSources des différentes selectBox du composant */
  private async setSelectBoxesDataSources() {
    this.lieuStockageDataSource = await lastValueFrom(this.lieuStockagesLexiClient.getBySociete(this.currentSocieteId));
    this.partenaireSourceDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox(
      this.currentMouvementType?.partenaireSourceEstUnClient,
      null,
      this.currentMouvementType?.partenaireSourceType
    );
    this.partenaireDestinationDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox(
      this.currentMouvementType?.partenaireDestinationEstUnClient,
      null,
      this.currentMouvementType?.partenaireDestinationType
    );
  }

  private setCurrentSocieteId() {
    this.subscriptions.add(
      this.authService.currentSocieteId$.pipe(
        filter((societeId: number) => societeId != null)
      ).subscribe((societeId: number) => this.currentSocieteId = societeId)
    );
  }

  private setCurrentSiteId() {
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => {
        this.currentSiteId = siteId;
        this.currentSitePartenaireId = this.authService.currentSite?.partenaireId;
      })
    );
  }

  private setCurrentUserId() {
    this.subscriptions.add(
      this.authService.currentUser$.pipe(
        filter((user: LexiUser) => user != null)
      ).subscribe((user: LexiUser) => this.currentUserId = user.id)
    );
  }

  private refreshMouvementStocks() {
    this.bonDetailLignesRef?.dataGridMouvementMarchandise && this.bonDetailLignesRef.dataGridMouvementMarchandise.setDataSource();
  }

  private getLignesToSubmit(): BonLigneACreerDto[] | UpdateBonLigneDto[] {
    return this.marchandiseLignes.map(x => {
      return {
        id: x.bonLigneId,
        articleId: x.articleId,
        uniteId: x.uniteId,
        quantiteInitiale: x.quantiteInitiale,
        quantiteReservee: x.quantiteReservee,
        lieuDestinationId: this.bon.destinationStockageId,
        lieuSourceId: this.bon.sourceStockageId
      }
    });
  }

  private async notifyErrorAndNavigateToBonList(message: string) {
    notify({closeOnClick: true, message}, NotificationType.Error);
    await this.router.navigate(['/visualisation-des-bons']);
  }

  private getCurrentMouvementTypePartenaireSourceId(): number {
    if (this.currentMouvementType == null) return null;
    let partenaireSourceId = this.currentMouvementType.partenaireSourceCourant
      ? this.currentSitePartenaireId
      : this.currentMouvementType.partenaireSourceDefaultId;

    if (partenaireSourceId == null) {
      partenaireSourceId = this.currentMouvementType.sens == MouvementSens.entree ? null : this.currentSitePartenaireId;
    }
    return partenaireSourceId;
  }

  private getCurrentMouvementTypePartenaireDestinationId(): number {
    if (this.currentMouvementType == null) return null;
    let partenaireDestinationId = this.currentMouvementType.partenaireDestinationCourant
      ? this.currentSitePartenaireId
      : this.currentMouvementType.partenaireDestinationDefaultId;

    if (partenaireDestinationId == null) {
      partenaireDestinationId = this.currentMouvementType.sens == MouvementSens.entree ? this.currentSitePartenaireId : null;
    }
    return partenaireDestinationId;
  }

  private async getLieuStockageReguleId(): Promise<number | null> {
    return (await this.dxDataSourceService.getLieuStockageByCodeBo(lieuStockageReguleCodeBo))?.id;
  }

  private async getCurrentMouvementTypeStockageSourceId(): Promise<number> {
    const partenaireId = this.getCurrentMouvementTypePartenaireSourceId();
    if (partenaireId == null) return null;
    const partenaire: PartenaireDto = await this.dxDataSourceService.getPartenaireById(partenaireId);
    return partenaire?.lieuParDefautId;
  }

  private async getCurrentMouvementTypeStockageDestinationId(): Promise<number> {
    const partenaireId = this.getCurrentMouvementTypePartenaireDestinationId();
    if (partenaireId == null) return null;
    const partenaire: PartenaireDto = await this.dxDataSourceService.getPartenaireById(partenaireId);
    return partenaire?.lieuParDefautId;
  }
  //#endregion

}

export interface MarchandiseLigne extends ConditionnementData {
  bonLigneId?: number;
  articleId: number;
  articleCodeBo: string;
  articleIntitule: string;
  zIndex: number;
  uniteId?: number;
  quantiteInitiale: number;
  quantiteInitialeConvertie?: number;
  quantiteReservee: number;
  quantiteReserveeConvertie?: number;
  /** readonly: alimentée depuis le serveur */
  quantiteMouvementee: number;
  quantiteMouvementeeConvertie?: number;
  /**
   * Si inventaire : quantiteReservee - quantiteInitiale
   * sinon : quantiteReservee - quantiteInitiale + quantiteMouvementee
   */
  ecart: number;
  qteDisponibleResteDocument: number;
  stockSource?: GetStockResult;
  stockDestination?: GetStockResult;
  numerosSeries?: string[];
  stockNegatifAutorise?: boolean;
  nombreNumeroSerie?: number;
  referenceFournisseur?: string;
  conditionnementVenteId?: number;
  conditionnementVente?: GetConditionnementDto;
  conditionnementAchatId?: number;
  conditionnementAchat?: GetConditionnementDto;
}
