import { Injectable, OnDestroy } from '@angular/core';
import { ClientCrispConfig, ClientSentryConfig, SecurityLexiClient } from '@lexi-clients/lexi';
import { EnvironmentService } from './environment.service';
import * as Sentry from "@sentry/angular";
import { Observable, Subscription, concatMap, filter, lastValueFrom } from 'rxjs';
import   lexiVersion  from './app-info.json';
import { UaaService } from '@lexi/oidc-uaa';
import { LoginResponse } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AppInitService implements OnDestroy {

  private crispConfig: any;

  private checkAuthSubscription: Subscription;

  get checkAuthResponse$(): Observable<LoginResponse> {
    return this.uaa?.checkAuthResponse$;
  }

  constructor(
    private readonly envService: EnvironmentService,
    private readonly securityLexiClient: SecurityLexiClient,
    private readonly uaa: UaaService,

  ) { }

  ngOnDestroy(): void {
    if (this.checkAuthSubscription) {
      this.checkAuthSubscription.unsubscribe();
    }
  }

  loadCrispConfiguration() {
    this.checkAuthSubscription = this.checkAuthResponse$.pipe(
      filter(response => response.isAuthenticated === true),
      concatMap(() => this.securityLexiClient.getClientCrispConfiguration())
    ).subscribe(
      (clientCrispConfig: ClientCrispConfig) => {
        this.crispConfig = clientCrispConfig;
      }
    );
  }

  loadSentryConfigurationAndInitSdk() {
    console.info("LOAD SENTRY CONFIG");
    return lastValueFrom(this.securityLexiClient.getClientSentryConfiguration())
      .then((clientSentryConfig: ClientSentryConfig) => {

        // Le SDK est activé et envoie des messages si enabled
        if(clientSentryConfig.enabled)
        {
          console.info("INIT SENTRY SDK");
          // Register and configure the BrowserTracing integration, including custom Angular routing instrumentation
          // https://github.com/getsentry/sentry-javascript/tree/8.7.0/packages/angular
          Sentry.init({
            dsn: clientSentryConfig.dsn,

            // Make sure this value is identical to the name you give the release that you
            // create using Sentry CLI: https://docs.sentry.io/platforms/javascript/sourcemaps/uploading/cli/
            release: lexiVersion.lexiVersion,
            
            integrations: [Sentry.browserTracingIntegration()],
            tracePropagationTargets: clientSentryConfig.tracePropagationTargets?.split(",").map(element => element.trim()),
            
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: clientSentryConfig.traceSampleRate,
            
            // Enable/Disable SDK
            // Le champ enabled n'est pas suffisant pour désactiver totalement le SDK
            // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#enabled
            // Specifies whether this SDK should send events to Sentry
            // To disable Sentry completely, depending on environment, call Sentry.init conditionally.
            enabled: clientSentryConfig.enabled,

            // Set SDK Environment
            environment: this.envService.environment.production ? "production" : "development",
          });
        }
      });
  }

  get isCrispEnabled() {
    if (!this.crispConfig) {
      return false;
    }
    return this.crispConfig.isCrispEnabled;
  }

  get crispKey() {
    if (!this.crispConfig) {
      return "";
    }
    return this.crispConfig.crispKey;
  }

}
