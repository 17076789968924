<h2 class="content-block">Liste des {{ partenaireCategorieIntitule }}</h2>

<div style="margin: 0 0 1.5em 1.5em;">
  <a routerLink="/{{ partenaireCategorieIntitule | slice:0:-1 }}/nouveau" style="margin-right: 12px;">
    <dx-button text="Créer un {{ partenaireCategorieIntitule | slice:0:-1 }}"></dx-button>
  </a>
  <dx-button text="Importer" style="margin-right: 12px;" (onClick)="this.showImportCsvPopup = true"></dx-button>
  <dx-button text="Template" style="margin-right: 12px;" (onClick)="downloadTemplate()"
      [disabled]="recuperationTemplateEncours"
  >
    <div style="display: flex; align-items: center;">
      <dx-load-indicator [visible]="recuperationTemplateEncours" height="20" width="20"></dx-load-indicator>
      <span class="dx-button-text" [ngStyle]="{'margin-left': recuperationTemplateEncours ? '10px' : 'auto'}">
        Modèle CSV
      </span>
    </div>
  </dx-button>
  <dx-button text="Rafraîchir" (onClick)="setDataSource()"></dx-button>
  <dx-radio-group
    id="radio-group-with-selection"
    [items]="partenaireCategories"
    valueExpr="enumId"
    displayExpr="text"
    [(value)]="partenaireCategorie"
    (onValueChanged)="onPartenaireCategoryChanged($event)"
    layout="horizontal"
    style="margin: 1.5em 0 1.5em 1.5em;"
    [visible]="!isPartenaireCategorieFige"
  >
  </dx-radio-group>

</div>

<!-- DataGrid -->
<dx-data-grid
  #gridContainer
  id="gridContainer"
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [remoteOperations]="{ groupPaging: true }"
  [showBorders]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onExporting)="onExporting($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <i id="partenaireListLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"></i>
      </div>
    </dxi-item>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button
          icon="deletetable"
          hint="Réinitialiser les filtres"
          (onClick)="clearDatagridFilters()"
        ></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-export [enabled]="true" [fileName]="partenaireCategorieIntitule"></dxo-export>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>

  <!-- Colonnes -->
  <dxi-column [allowHeaderFiltering]="true" dataField="codeBoClient" caption="Code" cellTemplate="cellTemplate">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/{{ partenaireCategorieIntitule | slice:0:-1 }}/{{data.data.id}}">
        <span [hidden]="!data.value">{{data.value}}</span>
        <span [hidden]="data.value != null">{{data.data.codeBo}}</span>
      </a>
    </div>
  </dxi-column>

  <dxi-column [allowHeaderFiltering]="true" dataField="intitule" caption="Libellé" ></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="telephoneFixe" caption="Téléphone"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="telephoneAutre" caption="Téléphone 2"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="telephonePortable" caption="GSM"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="telecopie" caption="Fax"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="email" caption="Email"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="sousContrat" caption="Sous contrat"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="actif" caption="Actif" [visible]="false"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="identifiantEntreprise" caption="N° Tahiti / Ridet" [visible]="false"></dxi-column>

  <dxo-summary>
    <dxi-group-item column="codeBo" summaryType="count">
    </dxi-group-item>
  </dxo-summary>
</dx-data-grid>

<!-- Popup import CSV -->
<dx-popup
  [width]="500"
  [height]="450"
  title = "Importer des partenaires via fichier .csv"
  [(visible)]="showImportCsvPopup"
  [showCloseButton]="true"
>
  <div *dxTemplate="let data of 'content'">
    <div [hidden]="showLoader">
      <dx-file-uploader
        [multiple]="false"
        [allowedFileExtensions]="['.csv']"
        uploadMode="useForm"
        selectButtonText="Importer un fichier .csv"
        readyToUploadMessage=""
        labelText="ou Glisser/Déposer le fichier"
        [(value)]="fichierCsv"
        (click)="resetImportResultMsg()"
      ></dx-file-uploader>
      <dx-button style="margin-right: 10px;" (click)="cancelImportCsv()" text="Annuler"></dx-button>
      <dx-button (click)="importDataFromCsv()" text="Importer" [disabled]="!fichierCsv[0]?.name.endsWith('.csv')"></dx-button>
      <div *ngIf="importCsvResultMsg" style="margin-top: 20px;">
        <p>
          <b *ngIf="!importHasFailed">Résultat</b>
          <b *ngIf="importHasFailed" style="color: red;">Une erreur est survenue lors de l'importation CSV</b>
        </p>
        <p>{{importCsvResultMsg}}</p>
        <p>{{importCsvResultMsg2}}</p>
      </div>
    </div>
    <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center;">
      <dx-load-indicator [visible]="showLoader" height="150" width="150"></dx-load-indicator>
    </div>
  </div>
</dx-popup>

<!-- Popover: Légende couleur -->
<dx-popover
  target="#partenaireListLegendeIcon"
  [width]="300"
  [showTitle]="true"
  title="Légende :"
  [(visible)]="showLegende"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <span class="row-bloque">Rouge</span> = Client désactivé.<br><br>
  </div>
</dx-popover>